import { useCaptivePortalContext } from '@givelify/onboarding';
import { GivelifyLoading } from '@givelify/ui';
import CaptivePortal from '../pages/captivePortal';
import { OverviewPage } from '../pages/overview/OverviewPage';

const OverviewRoutes = () => {
    const { showCaptivePortal, showLoader } = useCaptivePortalContext();

    if (showLoader) {
        return <GivelifyLoading type="linear" />;
    }

    if (showCaptivePortal) return <CaptivePortal />;

    return <OverviewPage />;
};

export default OverviewRoutes;
