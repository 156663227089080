import React, { useEffect, useMemo, useState } from 'react';
import { GivelifyLabel, GivelifyModal } from '@givelify/ui';
import { useApiRequest } from '@givelify/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import { getAutoIntegrationType } from 'pages/integrations/utils/autoIntegrationType';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../../store';
import { Donor, DonorResponse } from '../../../../../../../types/donorTypes';
import { IntegrationTypes } from '../../../../../types';
import { mapDonorResponseDataToDonor } from '../../../DonorMatching/types';
import { defaultCurrentDonor } from '../../types';
import { getDonorInformation } from '../automatedIntegrationRequest';
import { autoIntegrationModalPaper, autoIntegrationModalStyle } from '../style';
import AutoIntegrationUnMatchDonorTabsContent from './AutoIntegrationUnMatchDonorTabsContent';
import AutoIntegrationUnMatchDonorTabsHeader from './AutoIntegrationUnMatchDonorTabsHeader';

interface F1CCBUnMatchDonorModalProps {
    handleClose: (isDonorMatched?: boolean) => void;
    donorId: number;
    open: boolean;
    matchDonor: (donorId: number, chmsDonorId: number) => void;
    integration: IntegrationTypes;
    isReadOnly: boolean;
    errorMessage: string;
    handleChangeMessage: (value: string) => void;
}

const AutoIntegrationUnMatchDonorModal: React.FC<
    F1CCBUnMatchDonorModalProps
> = ({
    handleClose,
    donorId,
    open,
    matchDonor,
    integration,
    isReadOnly,
    errorMessage,
    handleChangeMessage,
}) => {
    const { f1ModalContent } = autoIntegrationModalStyle();
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState(0);
    const handleChange = (newValue: number) => {
        setCurrentTab(newValue);
        handleChangeMessage('');
    };
    const { doneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
    }));
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('smallTablet'));
    const copy = useMemo(
        () => ({
            title: t('pages.integration_donor_matching.matchTitle'),
            matchInfo: t('f1DonorPage.matchInfo'),
            ccbMatchDetail: t(
                'pages.integrations.ccbIntegration.ccbMatchDetail',
            ),
            f1GoMatchDetail: t(
                'pages.integrations.f1goIntegration.matchDetail',
            ),
            pcMatchInfo: t(
                'pages.integrations.planningCenter.donorsMatchDescription',
            ),
        }),
        [t],
    );
    const [donor, setDonor] = useState<Donor>();
    const [donorsRequestState, getDonor] = useApiRequest<DonorResponse>();
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        getDonor(getDonorInformation(doneeId, donorId, true));
        setLoading(true);
    }, [getDonor, doneeId, donorId]);
    useEffect(() => {
        if (donorsRequestState.type === 'REQUEST_SUCCESS') {
            setDonor(
                mapDonorResponseDataToDonor(donorsRequestState.response.data),
            );
            setLoading(false);
        } else if (donorsRequestState.type === 'REQUEST_ERROR') {
            setDonor(defaultCurrentDonor);
            setLoading(false);
        }
    }, [donorsRequestState]);

    const { isCCB, isF1, isF1Go } = useMemo(
        () => getAutoIntegrationType(integration),
        [integration],
    );

    return (
        <GivelifyModal
            fullWidth
            showCloseButton
            PaperComponent={autoIntegrationModalPaper}
            fullScreen={isSmallScreen}
            maxWidth="smallTablet"
            onClose={handleClose}
            open={open}
        >
            <div className={f1ModalContent}>
                <div className="header">
                    <GivelifyLabel
                        text={copy.title}
                        variant={isSmallScreen ? 'heading3S' : 'heading1S'}
                    />
                    <GivelifyLabel
                        text={
                            isCCB
                                ? copy.ccbMatchDetail
                                : isF1
                                ? copy.matchInfo
                                : isF1Go
                                ? copy.f1GoMatchDetail
                                : copy.pcMatchInfo
                        }
                        textAlign="center"
                        variant={isSmallScreen ? 'body2' : 'body1'}
                    />
                </div>
                <div className="body">
                    <AutoIntegrationUnMatchDonorTabsHeader
                        integration={integration}
                        isLoading={loading}
                        onChange={handleChange}
                        value={currentTab}
                    />
                    <AutoIntegrationUnMatchDonorTabsContent
                        currentTab={currentTab}
                        doneeId={doneeId}
                        donor={donor}
                        donorId={donorId}
                        errorMessage={errorMessage}
                        handleClose={handleClose}
                        integration={integration}
                        isLoading={loading}
                        isReadOnly={isReadOnly}
                        matchDonor={matchDonor}
                    />
                </div>
            </div>
        </GivelifyModal>
    );
};

export default AutoIntegrationUnMatchDonorModal;
