import { GivelifyButton } from '@givelify/ui';
import { styled } from '@mui/material';

export const Footer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(4),
    gap: theme.spacing(2),
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('mobile')]: {
        marginTop: theme.spacing(3),
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
}));

export const FooterSubmit = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column-reverse',
        alignItems: 'stretch',
        justifyContent: 'center',
        gap: theme.spacing(1),
    },
}));

export const BackButton = styled(GivelifyButton)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginRight: 'auto',
}));
