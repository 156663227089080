import React from 'react';
import { Skeleton } from '@mui/material';
import { DonationsStatisticType } from 'api/client/endpoints';
import { BasicErrorBoundary } from 'components';
import dayjs from 'dayjs';
import { useAdvancedTranslation } from 'utils/i18n';
import ZeroState from '../../ZeroState';
import { WidgetErrorBox } from '../components';
import { ResultsData } from './BarChart';
import { SimpleBarChart } from './components/SimpleBarChart';

const src = '/overview/giving-trend.svg';
const srcGrey = '/overview/giving-trend-grey.svg';

export type BarChartViewProps = {
    isLoading: boolean;
    isEmpty: boolean;
    data: ResultsData[];
    keys: { id: number | number[]; title: string }[];
    dataKey: string;
    handleChartClick: (
        startDateTime: dayjs.Dayjs,
        endDateTime: dayjs.Dayjs,
    ) => void;
    handleLegendClick: (entry: {
        id: number | number[];
        title: string;
    }) => void;
    rangeDateType: DonationsStatisticType;
    onboardingEnabled?: boolean;
    onboardingCompleted?: boolean;
};

const BarChartViewComponent = ({
    isLoading,
    isEmpty,
    data,
    dataKey,
    handleChartClick,
    handleLegendClick,
    rangeDateType,
    keys,
    onboardingEnabled,
    onboardingCompleted,
}: BarChartViewProps): JSX.Element => {
    const { t } = useAdvancedTranslation();

    if (isEmpty && !isLoading) {
        return (
            <ZeroState
                src={onboardingEnabled && onboardingCompleted ? src : srcGrey}
                type={t('donationData')}
            />
        );
    }

    return (
        <BasicErrorBoundary
            fallback={<WidgetErrorBox height="100%" width="100%" />}
        >
            {isLoading ? (
                <Skeleton
                    data-testid="bar-chart-skeleton"
                    height="100%"
                    sx={{
                        maxHeight: '400px',
                        aspectRatio: '1',
                    }}
                    variant="rectangular"
                    width="100%"
                />
            ) : (
                <SimpleBarChart
                    data={data}
                    dataKey={dataKey}
                    handleChartClick={handleChartClick}
                    handleLegendClick={handleLegendClick}
                    keys={keys}
                    rangeDateType={rangeDateType}
                />
            )}
        </BasicErrorBoundary>
    );
};

export const BarChartView: typeof BarChartViewComponent = React.memo(
    BarChartViewComponent,
);
