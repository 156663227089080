// We need to do different requests for desktop/mobile

import { useEffect, useRef } from 'react';
import { TimeFrameValues } from '@givelify/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import { useLoaderData, useRevalidator } from 'react-router-dom';
import { OverviewLoadedData } from './types';

// We can trigger router loader with this hook
export const useRevalidateWhenScreenChanges = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const firstMount = useRef(true);
    const revalidator = useRevalidator();

    useEffect(() => {
        if (firstMount.current) {
            firstMount.current = false;
        } else {
            if (revalidator.state === 'idle') {
                revalidator.revalidate();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);
};

// This hook triggers route loaded when timezone changes.
// We set timezone before we render any route component on apps initial load,
// so in most cases I dont expect it to be executed
// Just keep it in case behavior will change in future
export const useRevalidateWhenTimezonChanges = () => {
    const firstMount = useRef(true);
    const revalidator = useRevalidator();

    const lifetimeTimeFrame = TimeFrameValues['lifetime'];

    useEffect(() => {
        if (firstMount.current) {
            firstMount.current = false;
        } else {
            revalidator.revalidate();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lifetimeTimeFrame.start, lifetimeTimeFrame.end]);
};

export const useOverviewLoaderData = (): OverviewLoadedData => {
    // requests will be null when running unit tests
    const requests = useLoaderData() as OverviewLoadedData;

    return {
        envelopesData: requests?.envelopesData,
        donationsStatistics: requests?.donationsStatistics,
        donationsStatisticsByEnvelopes:
            requests?.donationsStatisticsByEnvelopes,
        donorsData: requests?.donorsData,
    };
};
