import { DesignTokens, GivelifyButton } from '@givelify/ui';
import { Avatar, styled } from '@mui/material';
import { MOBILE_FOOTER_SPACE } from 'theme/layoutMeaserments';

export const CalendlyFloatingButtonText = styled('span')(() => ({
    overflow: 'hidden',
    textWrap: 'nowrap',
}));

export const CalendlyFloatingButtonWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'bringToFront',
})<{
    bringToFront: boolean;
}>(({ theme, bringToFront }) => ({
    zIndex: bringToFront ? theme.zIndex.tooltip : theme.zIndex.modal,
    position: 'fixed',
    right: 32,
    bottom: 32,
    [theme.breakpoints.down('mobile')]: {
        bottom: MOBILE_FOOTER_SPACE,
        right: 16,
    },
}));

export const CalendlyFloatingButtonStyled = styled(GivelifyButton, {
    shouldForwardProp: (prop) => prop !== 'expanded',
})<{
    expanded: boolean;
}>(({ expanded }) => ({
    transition: 'all 500ms ease',
    transitionProperty: 'max-width, padding-left, padding-right, min-width',
    maxWidth: expanded ? 300 : 0,
    paddingLeft: !expanded ? 12 : undefined,
    paddingRight: !expanded ? 12 : undefined,
    minWidth: !expanded ? 48 : undefined,
    '& .MuiButton-startIcon': {
        transition: 'all 500ms ease',
        transitionProperty: 'margin-left, margin-right',
        marginLeft: !expanded ? 8 : undefined,
        marginRight: !expanded ? 8 : undefined,
    },
}));

export const CalendlyAvatar = styled(Avatar)(() => ({
    borderColor: DesignTokens.color.iconSecondary,
}));

export const TooltipContentWrapper = styled('div')(() => ({
    margin: 24,
}));

export const TooltipCloseButton = styled(GivelifyButton)(() => ({
    color: DesignTokens.color.iconSecondary,
    position: 'absolute',
    top: 24,
    right: 24,
}));

export const TooltipGotItButton = styled(GivelifyButton)(() => ({
    borderColor: 'white',
    color: 'white',
    '&:hover': {
        backgroundColor: 'rgb(53, 53, 53)',
        color: 'white',
        borderColor: 'white',
    },
    '&:active': {
        borderColor: 'white',
        color: 'white',
    },
}));
