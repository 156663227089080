import React from 'react';
import { convertDoneeToPrimaryRepresentativeInfo } from '@givelify/onboarding';
import { PAGE_NAME, TrackingProvider } from '@givelify/utils';
import toCamelCase from 'camelcase-keys';
import { useNotification } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Location, useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { AppState } from 'store';
import {
    PrimaryRepDetails,
    PrimaryRepEditor,
    PrimaryRepEditorRef,
    PrimaryRepresentativeInfoStatus,
} from './components';

type PrimaryRepresentativeTabComponentProps = {
    editorRef?: React.RefObject<PrimaryRepEditorRef>;
};

const PrimaryRepresentativeTabComponent: React.FC<
    PrimaryRepresentativeTabComponentProps
> = ({ editorRef }) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            congrats: t('pages.settings.bank-info.customize-rep-tab.congrats'),
        }),
        [t],
    );
    const { PATH } = useGasRouterContext();
    const navigate = useNavigate();
    const { doneeId, primaryRepInfo, user } = useSelector(
        (state: AppState) => ({
            doneeId: state.Donee.donee.id,
            user: state.User.user,
            primaryRepInfo: convertDoneeToPrimaryRepresentativeInfo(
                toCamelCase(state.Donee.donee, { deep: true }),
            ),
            address: state.Donee.donee.onboarding.primaryRepresentative.street,
        }),
    );
    const { showNotification } = useNotification();
    const [status, setStatus] = React.useState<PrimaryRepresentativeInfoStatus>(
        primaryRepInfo && primaryRepInfo.firstName && primaryRepInfo.lastName
            ? 'completed'
            : 'add',
    );
    const onReplaceClick = React.useCallback(() => {
        setStatus('update');
        showNotification(null);
    }, [showNotification]);
    const onCancel = React.useCallback(
        (nexLocation?: Location) => {
            if (nexLocation) {
                navigate(nexLocation, {
                    state: { ignoreBlocker: true },
                });
            } else if (
                primaryRepInfo &&
                primaryRepInfo.firstName &&
                primaryRepInfo.lastName
            ) {
                setStatus('completed');
            } else {
                navigate(PATH.SETTINGS.ROOT, {
                    state: { ignoreBlocker: true },
                });
            }
        },
        [PATH.SETTINGS.ROOT, primaryRepInfo, navigate],
    );
    const onEditDone = React.useCallback(() => {
        setStatus('success');
        showNotification({
            text: copy.congrats,
            variant: 'success',
            id: 'primaryRepVerificationDone',
        });
    }, [copy.congrats, showNotification]);
    React.useEffect(() => {
        return () => {
            showNotification(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (status === 'add' || status === 'update') {
        return (
            <PrimaryRepEditor
                ref={editorRef}
                address={{
                    city: primaryRepInfo.primaryRepresentativeAddress.city,
                    zip: primaryRepInfo.primaryRepresentativeAddress.zip,
                    state: primaryRepInfo.primaryRepresentativeAddress.state,
                    street: primaryRepInfo.primaryRepresentativeAddress.street,
                    phoneNumber:
                        primaryRepInfo.primaryRepresentativeAddress.phone,
                }}
                doneeId={doneeId}
                information={{
                    isPrimaryRepresentative:
                        primaryRepInfo.isPrimaryRepresentative,
                    dateOfBirth: primaryRepInfo.birthDate,
                    firstName: primaryRepInfo.firstName,
                    lastName: primaryRepInfo.lastName,
                    title: primaryRepInfo.title,
                    ssn: primaryRepInfo.socialSecurityNumber,
                }}
                mode={status}
                onCancel={onCancel}
                onEditDone={onEditDone}
                userDetails={{
                    firstName: user.firstName,
                    lastName: user.lastName,
                    title: user.title,
                }}
            />
        );
    }
    return (
        <PrimaryRepDetails
            name={`${primaryRepInfo.firstName} ${primaryRepInfo.lastName}`}
            onReplaceClick={status === 'success' ? undefined : onReplaceClick}
        />
    );
};

export const PrimaryRepresentativeTabV2: React.FC<
    PrimaryRepresentativeTabComponentProps
> = (props) => {
    return (
        <TrackingProvider
            trackPageVisit
            pageName={PAGE_NAME.SettingsBankInfoPrimaryRepTab}
        >
            <PrimaryRepresentativeTabComponent {...props} />
        </TrackingProvider>
    );
};
