import React from 'react';
import {
    GivelifyDatePicker,
    GivelifyLabel,
    GivelifyLoading,
    GivelifyNotification,
    GivelifyToggle,
} from '@givelify/ui';
import { useApiRequest } from '@givelify/utils';
import { Divider, Skeleton } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import * as Yup from 'yup';
import {
    getIntegrationSettings,
    updateCcbOrF1GoCredential,
    updateIntegrationAutoExport,
} from '../../AutoIntegrationContent/components/automatedIntegrationRequest';
import {
    AutoIntegrationSettingData,
    F1GoOrCCBCredentialUpdate,
    SetupF1GoFormData,
} from '../../AutoIntegrationContent/types';
import { F1GoIntegrationCredentialsForm } from './F1GoIntegrationCredentialsForm';
import { Root, Group, Form } from './styles';

export const F1GoIntegrationSettings = () => {
    const { doneeId, userDoneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
        userDoneeId: state.User.user.doneeId,
    }));
    const { t } = useTranslation();
    const prefix = 'pages.integrations.content.setup-dialog.f1go';
    const prefixSetttings = 'pages.integrations.settings.f1go';
    const copy = React.useMemo(() => {
        return {
            title: t(`${prefix}.title`),
            line1: t(`${prefix}.screen2.line1`),
            line2: t(`${prefix}.screen2.line2`),
            click: t(`${prefix}.screen2.clickHere`),
            button: t('labels.submit'),
            urlRequired: t(`${prefix}.screen2.urlRequired`),
            usernameRequired: t(`${prefix}.screen2.usernameRequired`),
            passwordRequired: t(`${prefix}.screen2.passwordRequired`),
            startDateRequired: t(`${prefix}.screen2.startDateRequired`),
            header: t(`${prefixSetttings}.header1`),
            header2: t(`${prefixSetttings}.header2`),
            integrationOn: t(`${prefixSetttings}.integrationOn`),
            integrationOff: t(`${prefixSetttings}.integrationOff`),
            startDateSaved: t(`${prefixSetttings}.startDateSaved`),
            startOn: t(`${prefixSetttings}.startOn`),
        };
    }, [t]);
    const schema = Yup.object<Record<keyof SetupF1GoFormData, Yup.AnySchema>>({
        url: Yup.string().required(copy.urlRequired),
        username: Yup.string().required(copy.usernameRequired),
        password: Yup.string().required(copy.passwordRequired),
    });
    const [integrationSettingsRequest, makeIntegrationSettingsRequest] =
        useApiRequest<AutoIntegrationSettingData>();
    React.useEffect(() => {
        makeIntegrationSettingsRequest(getIntegrationSettings(userDoneeId));
    }, [doneeId, makeIntegrationSettingsRequest, userDoneeId]);
    const [settings, setSettings] = React.useState({
        loading: true,
        on: false,
        exportStartDate: null,
        username: '',
        url: '',
    });
    const [successMessage, setSuccessMessage] = React.useState('');
    const onDateChange = React.useCallback(
        (date: dayjs.Dayjs | null) => {
            setSettings((prev) => ({ ...prev, exportStartDate: date }));
            makeIntegrationSettingsRequest(
                updateIntegrationAutoExport(userDoneeId, settings.on, date),
            );
            setSuccessMessage(copy.startDateSaved);
        },
        [
            copy.startDateSaved,
            makeIntegrationSettingsRequest,
            settings.on,
            userDoneeId,
        ],
    );
    const onToggleChange = React.useCallback(
        (_, checked: boolean) => {
            setSettings((prev) => ({ ...prev, on: checked }));
            makeIntegrationSettingsRequest(
                updateIntegrationAutoExport(
                    userDoneeId,
                    checked,
                    settings.exportStartDate,
                ),
            );
            setSuccessMessage(
                checked ? copy.integrationOn : copy.integrationOff,
            );
        },
        [
            copy.integrationOff,
            copy.integrationOn,
            makeIntegrationSettingsRequest,
            settings.exportStartDate,
            userDoneeId,
        ],
    );
    const handleSubmit = React.useCallback(
        async (value: SetupF1GoFormData) => {
            const data: F1GoOrCCBCredentialUpdate = {
                credentials: value,
            };
            makeIntegrationSettingsRequest(
                updateCcbOrF1GoCredential(userDoneeId, data),
            );
        },
        [makeIntegrationSettingsRequest, userDoneeId],
    );
    React.useEffect(() => {
        if (integrationSettingsRequest.type === 'REQUEST_SUCCESS') {
            setSettings({
                loading: false,
                on: integrationSettingsRequest.response.data.settings
                    .donationsAutoexport,
                exportStartDate: integrationSettingsRequest.response.data
                    .settings.exportStartDate
                    ? dayjs(
                          integrationSettingsRequest.response.data.settings
                              .exportStartDate,
                      )
                    : null,
                url: integrationSettingsRequest.response.data.credentials?.url,
                username:
                    integrationSettingsRequest.response.data.credentials
                        .username,
            });
        }
    }, [integrationSettingsRequest]);
    return (
        <Root>
            <Group>
                <GivelifyLabel text={copy.header} variant="body1B" />
                {settings.loading ? (
                    <GivelifyLoading type="circular" />
                ) : (
                    <GivelifyToggle
                        checked={settings.on}
                        label="On"
                        onChange={onToggleChange}
                    />
                )}
            </Group>
            {settings.on ? (
                <Group>
                    <GivelifyLabel text={copy.startOn} variant="body1B" />
                    {settings.loading ? (
                        <Skeleton height={56} variant="rectangular" />
                    ) : (
                        <GivelifyDatePicker
                            disableFuture
                            fullwidth
                            onDateChange={onDateChange}
                            value={
                                settings.exportStartDate
                                    ? settings.exportStartDate
                                    : null
                            }
                        />
                    )}
                </Group>
            ) : null}
            <Divider />
            <GivelifyLabel text={copy.header2} variant="body1B" />
            {settings.loading ? (
                <Group>
                    <Skeleton height={56} variant="rectangular" />
                    <Skeleton height={56} variant="rectangular" />
                    <Skeleton height={56} variant="rectangular" />
                </Group>
            ) : (
                <Form<SetupF1GoFormData>
                    defaultValues={{
                        url: settings.url,
                        username: settings.username,
                        password: '',
                    }}
                    onSubmit={handleSubmit}
                    schema={schema}
                >
                    <F1GoIntegrationCredentialsForm
                        disabled={settings.loading}
                        loading={
                            integrationSettingsRequest.type === 'REQUEST_START'
                        }
                    />
                </Form>
            )}
            <GivelifyNotification
                global
                showDismiss
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3000}
                onClose={() => setSuccessMessage('')}
                open={Boolean(
                    successMessage &&
                        integrationSettingsRequest.type === 'REQUEST_SUCCESS',
                )}
                text={successMessage}
                transitionDuration={0}
                variant="success"
            />
        </Root>
    );
};
