import React from 'react';
import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { LegendBlock, LegendIcon, LegendItem, LegendText } from './styles';

export interface PieChartLegendProps<T> {
    heading: string;
    nameKey: keyof T & string;
    legends: T[];
    fillColors: Record<string, string>;
    onSectorClick?: (entry: T) => void;
}

export const PieChartLegend = <T,>({
    heading,
    legends,
    nameKey,
    fillColors,
    onSectorClick,
}: PieChartLegendProps<T>) => {
    return (
        <div data-testid="pie-chart-legend">
            <GivelifyLabel
                data-testid="pie-chart-legend-title"
                text={heading}
                variant="heading3S"
            />
            <LegendBlock>
                {legends
                    ? legends.map((legend, index) => (
                          <LegendItem
                              key={index}
                              data-testid={`pie-chart-legend-item-${index}`}
                          >
                              <LegendIcon
                                  data-testid={`pie-chart-legend-item-icon-${index}`}
                                  index={index}
                                  legendColor={
                                      fillColors?.[
                                          String(legend[nameKey]).toLowerCase()
                                      ]
                                  }
                              />
                              <LegendText
                                  color={DesignTokens.color.textAccentDefault}
                                  data-testid={`pie-chart-legend-item-text-${index}`}
                                  name={`${heading} legend item`}
                                  onClick={() => onSectorClick(legend)}
                                  text={String(legend[nameKey])}
                                  variant="body2"
                              />
                          </LegendItem>
                      ))
                    : null}
            </LegendBlock>
        </div>
    );
};
