import { GivelifyForm } from '@givelify/ui';
import { styled } from '@mui/material';

export const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
}));

export const Group = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
    maxWidth: '528px',
}));

export const Form = styled(GivelifyForm)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
    maxWidth: '528px',
    alignItems: 'flex-end',
})) as typeof GivelifyForm;
