import React from 'react';
import {
    GivelifyLabel,
    GivelifyLink,
    GivelifyNotification,
} from '@givelify/ui';
import { defaultStartDateDayjs } from '@givelify/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { updateIntegrationAutoExport } from 'pages/integrations/IntegrationContent/TabsContent/AutoIntegrationContent/components/automatedIntegrationRequest';
import { getCcbUrl } from 'pages/integrations/IntegrationContent/TabsContent/AutoIntegrationContent/types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { newF1GoIntegration } from 'store/automatedIntegration/thunks';
import { handleErrorMessages } from 'store/thunks';
import * as Yup from 'yup';
import { F1goAuthForm, SetupF1GoFormData } from './F1goAuthForm';
import {
    Step2Box,
    Step2Content,
    Step2Form,
    Step2FormRoot,
    SubmitButton,
} from './styles';

type F1goSetupStep2Props = {
    doneCallBack?: () => void;
};

export const F1goSetupStep2: React.FC<F1goSetupStep2Props> = ({
    doneCallBack,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const { t } = useTranslation();
    const prefix = 'pages.integrations.content.setup-dialog.f1go';
    const copy = React.useMemo(() => {
        return {
            title: t(`${prefix}.title`),
            line1: t(`${prefix}.screen2.line1`),
            line2: t(`${prefix}.screen2.line2`),
            click: t(`${prefix}.screen2.clickHere`),
            button: t('labels.submit'),
            urlRequired: t(`${prefix}.screen2.urlRequired`),
            usernameRequired: t(`${prefix}.screen2.usernameRequired`),
            passwordRequired: t(`${prefix}.screen2.passwordRequired`),
            startDateRequired: t(`${prefix}.screen2.startDateRequired`),
        };
    }, [t]);
    const { doneeId, userDoneeId, startDate } = useSelector(
        (state: AppState) => ({
            doneeId: state.Donee.donee.id,
            userDoneeId: state.User.user.doneeId,
            startDate: state.Donee.donee.signupDate,
        }),
    );
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const schema = Yup.object<Record<keyof SetupF1GoFormData, Yup.AnySchema>>({
        url: Yup.string().required(copy.urlRequired),
        integrationUsername: Yup.string().required(copy.usernameRequired),
        integrationPassword: Yup.string().required(copy.passwordRequired),
        startDate: Yup.date().required(copy.startDateRequired),
    });
    const handleSubmit = React.useCallback(
        async (value: SetupF1GoFormData) => {
            try {
                setLoading(true);
                setErrorMessage('');
                await newF1GoIntegration(
                    {
                        username: value.integrationUsername,
                        password: value.integrationPassword,
                        url: value.url,
                    },
                    doneeId,
                )(dispatch);
                await updateIntegrationAutoExport(
                    userDoneeId,
                    true,
                    dayjs(value.startDate),
                );
                if (doneCallBack) {
                    doneCallBack();
                }
            } catch (e) {
                const translatedError = handleErrorMessages(e, t);
                setErrorMessage(translatedError.message);
                setLoading(false);
            }
        },
        [doneeId, dispatch, userDoneeId, doneCallBack, t],
    );
    return (
        <Step2Box>
            <GivelifyLabel
                text={copy.title}
                variant={isMobile ? 'heading3S' : 'heading1S'}
            />
            <Step2Content>
                <GivelifyLabel
                    text={copy.line1}
                    textAlign="center"
                    variant={isMobile ? 'body2' : 'body1'}
                >
                    {copy.line1}
                    <GivelifyLabel
                        textAlign="center"
                        variant={isMobile ? 'body2' : 'body1'}
                    >
                        <GivelifyLink
                            fontSize="inherit"
                            href={getCcbUrl}
                            rel="noopener"
                            target="_blank"
                            text={copy.click}
                        />{' '}
                        {copy.line2}
                    </GivelifyLabel>
                </GivelifyLabel>
                <Step2FormRoot>
                    <GivelifyNotification
                        open={!!errorMessage}
                        text={errorMessage}
                        variant="error"
                    />
                    <Step2Form<SetupF1GoFormData>
                        defaultValues={{
                            startDate: startDate
                                ? dayjs(startDate).tz()
                                : defaultStartDateDayjs,
                        }}
                        onSubmit={handleSubmit}
                        schema={schema}
                    >
                        <F1goAuthForm />
                        <SubmitButton
                            isLoading={loading}
                            size="large"
                            text={copy.button}
                            type="submit"
                            variant="primary"
                        />
                    </Step2Form>
                </Step2FormRoot>
            </Step2Content>
        </Step2Box>
    );
};
