import React, { useEffect } from 'react';
import { TrackingProvider, useTrackingContext } from '@givelify/utils';
import { Dialog, Paper, Stack, styled } from '@mui/material';
import { CloseIcon } from '../assets';
import { GivelifyButton } from '../button';
import { DesignTokens } from '../specify';
import { GivelifyModalProps } from './types';

const StyledDialog = styled(Dialog, {
    shouldForwardProp: (prop) => prop !== 'fullWidth',
})<{ fullWidth?: boolean }>(({ fullWidth }) => ({
    '& .MuiBackdrop-root': {
        backgroundColor: DesignTokens.color.overlayModal,
    },
    '& .MuiPaper-root': {
        margin: fullWidth ? 0 : 16,
    },
}));

const StyledPaperNoPadding = styled(Paper)({
    borderRadius: DesignTokens.measurement.borderRadiusL,
    backgroundColor: DesignTokens.color.backgroundPrimary,
    boxShadow: DesignTokens.shadow.shadowHigh,
});

const StyledPaper = styled(StyledPaperNoPadding)({
    padding: DesignTokens.measurement.fontDefaultSize,
});

export const GivelifyModalDefaultPaper = StyledPaper;

const GivelifyModalInternal: React.FC<GivelifyModalProps> = ({
    showCloseButton,
    children,
    disableBackdropClose,
    onClose,
    noPadding,
    classes,
    PaperComponent,
    CloseButton,
    name,
    trackCloseButton = true,
    ...props
}) => {
    const { trackOpenCloseEvent } = useTrackingContext();

    useEffect(() => {
        if (name) {
            trackOpenCloseEvent(true);

            return () => {
                trackOpenCloseEvent(false);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCloseButtonClick = React.useCallback(() => {
        if (onClose) onClose({}, 'closeButtonClick');
    }, [onClose]);
    const ModalDialog = noPadding ? StyledPaperNoPadding : StyledPaper;
    return (
        <StyledDialog
            {...props}
            PaperComponent={PaperComponent ? PaperComponent : ModalDialog}
            onClose={disableBackdropClose ? undefined : onClose}
        >
            {showCloseButton ? (
                <Stack
                    alignItems="end"
                    className={classes?.header}
                    flexDirection="column"
                    justifyContent="center"
                    paddingTop={
                        noPadding ? DesignTokens.measurement.fontDefaultSize : 0
                    }
                    paddingX={
                        noPadding ? DesignTokens.measurement.fontDefaultSize : 0
                    }
                >
                    <GivelifyButton
                        aria-label="Close Dialog"
                        className={classes?.closeButton}
                        name="Close"
                        onClick={onCloseButtonClick}
                        size="small"
                        track={trackCloseButton}
                        variant="icon"
                    >
                        {CloseButton ?? (
                            <CloseIcon className={classes?.closeIcon} />
                        )}
                    </GivelifyButton>
                </Stack>
            ) : null}
            {children}
        </StyledDialog>
    );
};

export const GivelifyModal: React.FC<GivelifyModalProps> = (props) => {
    if (!props.open) return null;

    return (
        <TrackingProvider
            pageName={props.name}
            trackPageVisit={false}
            viewType="modal"
        >
            <GivelifyModalInternal {...props} />
        </TrackingProvider>
    );
};
