import React, { useEffect, useMemo } from 'react';
import { FullWidthModal, useAdvancedTranslation } from '@givelify/givelify-ui';
import { useCalendlyCTAButtonContext } from 'layout/components/header/Dropdowns/CalendlyModal/CalendlyFloatingButton';
import { useFormContext } from 'react-hook-form';
import { useCampusesContext } from '../CampusesProvider';
import CampusSelected from './CampusSelected';
import ConfirmModal from './ConfirmModal';
import ManualEntry from './ManualEntry';
import SearchForm from './SearchForm';
import { OrganizationSuggestionsResponse } from './SearchForm/types';
import useStyles from './styles';
import { FormProps } from './types';

interface AddCampusViewProps {
    currentStep: number;
    setCurrentStep: (value: number) => void;
    onCampusSelect: (value: OrganizationSuggestionsResponse) => void;
    onSubmit: () => void;
    onConfirm: () => void;
}

const AddCampusView: React.FC<AddCampusViewProps> = ({
    currentStep,
    setCurrentStep,
    onCampusSelect,
    onSubmit,
    onConfirm,
}) => {
    const { wrapper } = useStyles();
    const { addCampusRequestState, showAddCampusModal, setShowAddCampusModal } =
        useCampusesContext();

    const form = useFormContext<FormProps>();
    const { watch } = form;
    const vls = watch();

    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'pages.settings.campuses.addCampusModal',
    });

    const { title, cancel, next } = useMemo(
        () => ({
            title: scopedTranslate('title'),
            cancel: scopedTranslate('cancel'),
            next: scopedTranslate('next'),
        }),
        [scopedTranslate],
    );

    const { setButtonBringToFront } = useCalendlyCTAButtonContext();
    useEffect(() => {
        setButtonBringToFront(!showAddCampusModal);
        return () => setButtonBringToFront(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showAddCampusModal]);

    return (
        <FullWidthModal
            trackPageVisit
            errorMessage={null}
            footer={
                currentStep === 1 && {
                    cancelText: cancel,
                    submitText: next,
                    disableSubmit: false,
                    isLoading: addCampusRequestState.type === 'REQUEST_START',
                    onCancel: () => setCurrentStep(currentStep - 1),
                    onSubmit,
                }
            }
            heading={title}
            name="Add Campus"
            onClose={() => {
                if (currentStep === 0) setShowAddCampusModal(false);
                else setCurrentStep(currentStep - 1);
            }}
            open={showAddCampusModal}
            retryMessage={null}
            useBackArrow={currentStep === 1}
        >
            <div className={wrapper} data-testid="add-campus-modal">
                <ConfirmModal onOk={onConfirm} />
                {currentStep === 0 && <SearchForm onSelect={onCampusSelect} />}
                {currentStep === 1 && vls.campusSelected && <CampusSelected />}
                {currentStep === 1 && !vls.campusSelected && <ManualEntry />}
            </div>
        </FullWidthModal>
    );
};

export default AddCampusView;
