import React from 'react';
import { GivelifyButton, GivelifyFormTextField } from '@givelify/ui';
import { useFormContext } from 'react-hook-form';

type F1GoIntegrationCredentialsFormProps = {
    loading: boolean;
    disabled: boolean;
};

export const F1GoIntegrationCredentialsForm: React.FC<
    F1GoIntegrationCredentialsFormProps
> = ({ loading, disabled }) => {
    const { formState } = useFormContext();
    return (
        <>
            <GivelifyFormTextField fullWidth name="url" size="large" />
            <GivelifyFormTextField
                fullWidth
                autoComplete="none"
                name="username"
                size="large"
            />
            <GivelifyFormTextField
                fullWidth
                passwordToggle
                autoComplete="new-password"
                name="password"
                size="large"
                type="password"
            />
            <GivelifyButton
                disabled={disabled || !formState.isValid}
                isLoading={loading}
                size="large"
                text="Update Credentials"
                type="submit"
                variant="primary"
            />
        </>
    );
};
