import { Reducer } from 'redux';
import { NotificationsActions, NotificationsState } from './types';

const initialState: NotificationsState = {
    hideEnvelopesNotification: false,
    hideIntroductionVideoCards: false,
    calendlyModalDismissed: undefined,
    calendlyTooltipDismissed: undefined,
};

const NotificationsReducer: Reducer<
    NotificationsState,
    NotificationsActions
> = (state = initialState, action) => {
    switch (action.type) {
        case 'LOAD_NOTIFICATIONS_SUCCESS': {
            const res = { ...action.data };
            return res;
        }
        case 'SET_NOTIFICATION_FLAG': {
            return {
                ...state,
                [action.data.type]: action.data.value,
            };
        }
    }
    return state;
};

export default NotificationsReducer;
