import {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    CloseIcon,
    GivelifyLabel,
    GivelifyTooltip,
    HelpIcon,
} from '@givelify/ui';
import { useTrackingContext } from '@givelify/utils';
import {
    AvatarGroup,
    ClickAwayListener,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { updateNotification } from 'store/notifications/thunks';
import CalendlyWidget, { useCalendly } from '..';
import Img1 from '../img/1.png';
import Img2 from '../img/2.png';
import Img3 from '../img/3.png';
import {
    CalendlyAvatar,
    CalendlyFloatingButtonStyled,
    CalendlyFloatingButtonText,
    CalendlyFloatingButtonWrapper,
    TooltipCloseButton,
    TooltipContentWrapper,
    TooltipGotItButton,
} from './styles';

const CalendlyFloatingButtonInner = () => {
    const { t } = useTranslation();

    const {
        talkWithExpert,
        tooltipDescription,
        tooltipDescsription2,
        tooltipGotIt,
        tooltipHeader,
    } = useMemo(
        () => ({
            talkWithExpert: t('calendly.talkWithExpert'),
            tooltipHeader: t('calendly.tooltipHeader'),
            tooltipDescription: t('calendly.tooltipDescription'),
            tooltipDescsription2: t('calendly.tooltipDescsription2'),
            tooltipGotIt: t('calendly.tooltipGotIt'),
        }),
        [t],
    );

    const { trackEvent } = useTrackingContext();

    const dispatch = useDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const { calendlyModalDismissed, calendlyTooltipDismissed } = useSelector(
        (state: AppState) => ({
            calendlyModalDismissed: state.Notifications.calendlyModalDismissed,
            calendlyTooltipDismissed:
                state.Notifications.calendlyTooltipDismissed,
        }),
    );

    const { openCalendly, setOpenCalendly } = useCalendly();

    const [showTooltip, setShowTooltip] = useState(
        calendlyModalDismissed && !calendlyTooltipDismissed,
    );

    const onButtonClick = () => {
        setOpenCalendly(true);
        closeTooltipClick();
    };

    const closeTooltipClick = () => {
        dispatch(updateNotification('calendlyTooltipDismissed', true));
        setShowTooltip(false);
    };

    const [expanded, setExpanded] = useState(true);

    const onClickAway = () => {
        if (isMobile) {
            setExpanded(false);
        }
    };
    const observedElementRef = useRef(null);
    const popperRef = useRef(null);

    useEffect(() => {
        if (showTooltip) {
            trackEvent(
                'Captive Portal Page <Talk with an Expert Notification CTA>',
                undefined,
                'view',
            );
        }

        if (!observedElementRef.current || !showTooltip) return;

        const observer = new ResizeObserver((el) => {
            requestAnimationFrame(() => {
                if (popperRef.current) {
                    popperRef.current.update();
                }
            });
        });

        observer.observe(observedElementRef.current, {});

        return () => {
            observer.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { buttonBringToFront } = useCalendlyCTAButtonContext();

    return (
        <>
            {openCalendly && (
                <CalendlyWidget onClose={() => setOpenCalendly(false)} />
            )}
            <GivelifyTooltip
                arrow
                disableFocusListener
                disableHoverListener
                disableTouchListener
                PopperProps={{
                    sx: {
                        transform: isMobile
                            ? 'translate3d(-24px, -132px, 0px) !important'
                            : undefined,
                        zIndex: buttonBringToFront
                            ? theme.zIndex.tooltip
                            : theme.zIndex.modal,
                    },
                    popperOptions: {
                        strategy: 'fixed',
                    },
                    popperRef: popperRef,
                    modifiers: [
                        {
                            name: 'preventOverflow',
                            options: {
                                padding: 24,
                            },
                        },
                    ],
                }}
                open={showTooltip}
                placement="top-end"
                slotProps={{
                    tooltip: {
                        style: {
                            maxWidth: isMobile ? 304 : 364,
                        },
                    },
                }}
                title={
                    <TooltipContentWrapper>
                        <TooltipCloseButton
                            name="X CTA"
                            onClick={closeTooltipClick}
                            variant="icon"
                        >
                            <CloseIcon />
                        </TooltipCloseButton>
                        <AvatarGroup
                            style={{
                                justifyContent: 'start',
                                marginBottom: 16,
                            }}
                        >
                            <CalendlyAvatar src={Img1} />
                            <CalendlyAvatar src={Img2} />
                            <CalendlyAvatar src={Img3} />
                        </AvatarGroup>
                        <GivelifyLabel
                            color="white"
                            text={tooltipHeader}
                            variant="heading2S"
                        />
                        <GivelifyLabel
                            color="white"
                            style={{
                                marginTop: 8,
                                marginBottom: 16,
                            }}
                            variant="body2"
                        >
                            {tooltipDescription}{' '}
                            <GivelifyLabel color="white" variant="body2B">
                                {tooltipDescsription2}
                            </GivelifyLabel>
                        </GivelifyLabel>
                        <TooltipGotItButton
                            name="Got it, thanks CTA"
                            onClick={closeTooltipClick}
                            text={tooltipGotIt}
                            variant="secondary"
                        />
                    </TooltipContentWrapper>
                }
            >
                <CalendlyFloatingButtonWrapper
                    bringToFront={calendlyModalDismissed && buttonBringToFront}
                >
                    <ClickAwayListener
                        onClickAway={onClickAway}
                        touchEvent="onTouchStart"
                    >
                        <CalendlyFloatingButtonStyled
                            ref={observedElementRef}
                            expanded={expanded}
                            name="Talk with an Expert CTA"
                            onClick={onButtonClick}
                            size="large"
                            startIcon={<HelpIcon />}
                            text={
                                <CalendlyFloatingButtonText>
                                    {talkWithExpert}
                                </CalendlyFloatingButtonText>
                            }
                            variant="primary"
                        />
                    </ClickAwayListener>
                </CalendlyFloatingButtonWrapper>
            </GivelifyTooltip>
        </>
    );
};

export const CalendlyFloatingButton = () => {
    const { isCalendlyEnabled } = useCalendly();

    if (!isCalendlyEnabled) return null;

    return <CalendlyFloatingButtonInner />;
};

const CalendlyCTAButtonContext = createContext({
    buttonBringToFront: true,
    setButtonBringToFront: (value: boolean) => undefined,
});

export const useCalendlyCTAButtonContext = () =>
    useContext(CalendlyCTAButtonContext);

export const CalendlyCTAButtonProvider: React.FC = ({ children }) => {
    const [buttonBringToFront, setButtonBringToFront] = useState(true);

    return (
        <CalendlyCTAButtonContext.Provider
            value={{
                buttonBringToFront,
                setButtonBringToFront,
            }}
        >
            {children}
        </CalendlyCTAButtonContext.Provider>
    );
};
