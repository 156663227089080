import React from 'react';
import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { RequestState, responseOrUndefined } from '@givelify/utils';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from '../../../types';
import { PieChartLegend } from '../components/PieChartLegend';
import { SimplePieChart } from '../components/SimplePieChart';
import {
    AdditionalText,
    ChartItem,
    ChartWrapper,
} from '../components/SimplePieChart/styles';

export type PieChartProps<
    K extends {
        id?: number | null;
        aggregatedIds?: number[] | null;
    },
    T extends {
        data: K[];
    },
> = {
    data: RequestState<T>;
    nameKey: keyof K & string;
    dataKey: string;
    legendTitle: string;
    fillColors?: Record<string, string>;
    onSectorClick?: (entry: K, isSector?: boolean) => void;
    helperText1?: string;
    helperText2?: string;
    tooltip?: React.FC<TooltipProps<ValueType, NameType>>;
};

const PieChartComponent = <
    K extends {
        id?: number | null;
        aggregatedIds?: number[] | null;
    },
    T extends {
        data: K[];
    },
>({
    data,
    nameKey,
    dataKey,
    legendTitle,
    fillColors,
    onSectorClick,
    helperText1,
    helperText2,
    tooltip,
}: PieChartProps<K, T>): JSX.Element => {
    const response = responseOrUndefined<T>(data);

    const parsedData =
        response &&
        response?.data?.map((env) =>
            env.id !== null ? env : { ...env, title: 'Other' },
        );

    return (
        <div>
            <ChartWrapper data-testid="pie-chart-wrapper">
                <ChartItem data-testid="pie-chart-block">
                    <SimplePieChart
                        data={parsedData}
                        dataKey={dataKey}
                        fillColors={fillColors}
                        nameKey={nameKey}
                        onSectorClick={onSectorClick}
                        tooltip={tooltip}
                    />
                </ChartItem>
                <ChartItem data-testid="pie-chart-legend-block">
                    <PieChartLegend
                        fillColors={fillColors}
                        heading={legendTitle}
                        legends={parsedData}
                        nameKey={nameKey}
                        onSectorClick={onSectorClick}
                    />
                </ChartItem>
            </ChartWrapper>
            {helperText1 || helperText2 ? (
                <AdditionalText>
                    {helperText1 ? (
                        <GivelifyLabel
                            color={DesignTokens.color.textSecondary}
                            data-testid="pie-chart-helper-1"
                            text={helperText1}
                            variant="componentLabel"
                        />
                    ) : null}

                    {helperText2 ? (
                        <GivelifyLabel
                            color={DesignTokens.color.textSecondary}
                            data-testid="pie-chart-helper-2"
                            text={helperText2}
                            variant="componentLabel"
                        />
                    ) : null}
                </AdditionalText>
            ) : null}
        </div>
    );
};

export const PieChart = React.memo(
    PieChartComponent,
) as typeof PieChartComponent;
