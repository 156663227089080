import React, { useEffect, useMemo } from 'react';
import { FullWidthModal, useAdvancedTranslation } from '@givelify/givelify-ui';
import { useInvokeApi } from 'hooks/useInvokeApi';
import { useCalendlyCTAButtonContext } from 'layout/components/header/Dropdowns/CalendlyModal/CalendlyFloatingButton';
import { useForm, FormProvider } from 'react-hook-form';
import { useCampusesContext } from '../../../CampusesProvider';
import { CampusItem } from '../../types';
import EditForm from './EditForm';
import { FormProps } from './types';

interface EditModalProps {
    open: boolean;
    onClose: () => void;
    data: CampusItem;
}

const EditModal: React.FC<EditModalProps> = ({ open, onClose, data }) => {
    const [editCampusRequestState, makeEditCampusRequest] = useInvokeApi<
        unknown,
        { nickname: string }
    >();

    const { doneeId, getList } = useCampusesContext();
    const form = useForm<FormProps>({
        mode: 'onBlur',
        defaultValues: data,
    });

    const { setError } = form;

    const formHandleSubmit = async (values: FormProps) => {
        const campusId = data.id;
        const nickname = values.short_name;
        makeEditCampusRequest(
            'PATCH',
            `/donees/${doneeId}/campus/${campusId}`,
            {
                nickname,
            },
        );
    };

    useEffect(() => {
        if (editCampusRequestState.type === 'REQUEST_SUCCESS') {
            getList();
            onClose();
        }
        if (
            editCampusRequestState.type === 'REQUEST_ERROR' &&
            editCampusRequestState.error.response.status === 422
        ) {
            setError('short_name', {
                type: 'unique',
            });
        }
    }, [editCampusRequestState, setError, getList, onClose]);

    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'pages.settings.campuses.list.listItem.editModal',
    });

    const copy = useMemo(
        () => ({
            title: scopedTranslate('title'),
            cancel: scopedTranslate('cancel'),
            save: scopedTranslate('save'),
        }),
        [scopedTranslate],
    );

    const { setButtonBringToFront } = useCalendlyCTAButtonContext();
    useEffect(() => {
        setButtonBringToFront(!open);
        return () => setButtonBringToFront(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <FullWidthModal
            errorMessage={null}
            footer={{
                cancelText: copy.cancel,
                submitText: copy.save,
                disableSubmit: false,
                isLoading: editCampusRequestState.type === 'REQUEST_START',
                onCancel: onClose,
                onSubmit: () => {
                    form.handleSubmit(formHandleSubmit)();
                },
            }}
            heading={copy.title}
            name="modal"
            onClose={onClose}
            open={open}
            retryMessage={null}
        >
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(formHandleSubmit)}>
                    <EditForm item={data} />
                </form>
            </FormProvider>
        </FullWidthModal>
    );
};

export default EditModal;
