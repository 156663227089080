import { useMemo } from 'react';
import { GivelifyTabOption } from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';

interface OwnProps {
    integrationNoDonor: boolean;
    integrationNoEnvelope?: boolean;
    featureEnabled?: boolean;
}

const CommonTabsHeader = ({
    integrationNoDonor,
    integrationNoEnvelope,
    featureEnabled,
}: OwnProps): GivelifyTabOption[] => {
    const { t } = useTranslation();
    const { totalUnmatchedEnvelopes, totalUnmatchedDonors } = useSelector(
        (state: AppState) => ({
            totalUnmatchedDonors: state.Integrations.totalUnmatchedDonors,
            totalUnmatchedEnvelopes: state.Integrations.totalUnmatchedEnvelopes,
        }),
    );

    const copy = useMemo(
        () => ({
            reportTab: t('menu.reports.title'),
            matchDonorTab: t('pages.integration_donor_matching.matchTitleTab'),
            matchEnvelopeTab: t('pages.integration_envelope_matching.title'),
            settingTab: t('menu.data.integrations.settings'),
        }),
        [t],
    );

    const options: GivelifyTabOption[] = [{ label: copy.reportTab }];

    if (integrationNoDonor) {
        options.push({
            label: copy.matchDonorTab,
            badgeText: featureEnabled ? totalUnmatchedDonors : '',
        });
    }

    if (!integrationNoEnvelope) {
        options.push({
            label: copy.matchEnvelopeTab,
            badgeText: featureEnabled ? totalUnmatchedEnvelopes : '',
        });
    }
    options.push({ label: copy.settingTab });

    return options;
};

export default CommonTabsHeader;
