import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import LegacyReportDonations from 'pages/reports/GenerateReports/legacy/LegacyReportDonations';
import LegacyReports from 'pages/reports/LegacyReports';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import PageTitle from '../components/PageTitle';
import LoadingBar from '../components/system/LoadingBar';
import Report from '../pages/reports/GenerateReports';
import ReportBankDeposits from '../pages/reports/GenerateReports/report/ReportBankDeposits';
import ReportDisbursements from '../pages/reports/GenerateReports/report/ReportDisbursements';
import ReportDonations from '../pages/reports/GenerateReports/report/ReportDonations';
import ReportDonationsByDonors from '../pages/reports/GenerateReports/report/ReportDonationsByDonors';
import ReportDonationsByEnvelope from '../pages/reports/GenerateReports/report/ReportDonationsByEnvelope';
import ReportDonationsLegacy from '../pages/reports/GenerateReports/report/ReportDonationsLegacy';
import ReportDonors from '../pages/reports/GenerateReports/report/ReportDonors';
import ReportHistory from '../pages/reports/ReportHistory';
import { ReportHistoryDownloadWrapper } from '../pages/reports/ReportHistory/ReportsHistoryDownloadWrapper';
import { AppState } from '../store';
import { PATH } from './routes';

export const REPORTS_ROUTES = (
    <>
        <Route element={<Report />} path={PATH.REPORTS.GENERATE_REPORTS} />
        <Route element={<LegacyReports />} path={PATH.REPORTS.LEGACY_REPORTS} />
        <Route
            element={<LegacyReportDonations />}
            path={PATH.REPORTS.LEGACY_REPORTS_DONATIONS}
        />
        <Route
            element={<LegacyReportDonations />}
            path={PATH.REPORTS.LEGACY_REPORTS_DISBURSMENTS}
        />
        <Route
            element={<LegacyReportDonations />}
            path={PATH.REPORTS.LEGACY_REPORTS_DONORS}
        />
        <Route
            element={<LegacyReportDonations />}
            path={PATH.REPORTS.LEGACY_REPORTS_IMPORT_FRIENDLY}
        />
        <Route
            element={<LegacyReportDonations />}
            path={PATH.REPORTS.LEGACY_REPORTS_BY_ENVELOPE}
        />
        <Route
            element={<LegacyReportDonations />}
            path={PATH.REPORTS.LEGACY_REPORTS_BY_DONOR}
        />
        <Route element={<ReportHistory />} path={PATH.REPORTS.REPORT_HISTORY} />
        <Route
            element={<ReportHistoryDownloadWrapper />}
            path={PATH.REPORTS.REPORT_HISTORY_DOWNLOAD}
        />
        <Route
            element={
                <TrackingProvider
                    pageName={PAGE_NAME.ReportGenerate}
                    trackPageVisit={false}
                >
                    <Outlet />
                </TrackingProvider>
            }
        >
            <Route
                element={<ReportDonations />}
                path={PATH.REPORTS.REPORT_DONATIONS}
            />
            <Route
                element={<ReportBankDeposits />}
                path={PATH.REPORTS.REPORT_BANK_DEPOSITS}
            />
            <Route
                element={<ReportDonors />}
                path={PATH.REPORTS.REPORT_DONORS}
            />
            <Route
                element={<ReportDisbursements />}
                path={PATH.REPORTS.REPORT_DISBURSEMENTS}
            />
            <Route
                element={<ReportDonationsLegacy />}
                path={PATH.REPORTS.REPORT_DONATIONS_LEGACY}
            />
            <Route
                element={<ReportDonationsByEnvelope />}
                path={PATH.REPORTS.DONATION_BY_ENVELOPE}
            />
            <Route
                element={<ReportDonationsByDonors />}
                path={PATH.REPORTS.DONATIONS_BY_DONOR}
            />
        </Route>
    </>
);

const ReportsRoute = () => {
    const enabledFeatures = useSelector(
        (state: AppState) => state.System.enabledFeatures,
    );

    if (enabledFeatures.length === 0) {
        return <LoadingBar show />;
    }

    return (
        <TrackingProvider pageName={PAGE_NAME.Reports} trackPageVisit={false}>
            <PageTitle description="Reports" title="Reports" />
            <Outlet />
        </TrackingProvider>
    );
};

export default ReportsRoute;
