import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAdvancedTranslation } from '@givelify/givelify-ui';
import { GivelifyLabel, GivelifyLink } from '@givelify/ui';
import { useApiRequest } from '@givelify/utils';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import {
    MATCHED,
    matchSelect,
    UNMATCH,
} from '../../../utils/integrationFormatFilterUtils';
import {
    getIntegrationDetail,
    getIntegrationSettings,
} from './components/automatedIntegrationRequest';
import IntegrationStatsCard from './components/IntegrationStatsCard';
import { homeTabStyle, SyncNotification } from './style';
import {
    AutoIntegrationMatchNumberResponse,
    AutoIntegrationSettingData,
    TotalMatchDonors,
    TotalMatchEnvelopes,
} from './types';

interface AutoIntegrationHomeTabContentProps {
    goToTab: (tab: number, matchDefault?: matchSelect) => void;
    integrationSync: string;
    closeSync: boolean;
    handleCloseSync: () => void;
}

const AutoIntegrationHomeTabContent: React.FC<
    AutoIntegrationHomeTabContentProps
> = ({ goToTab, integrationSync, closeSync, handleCloseSync }) => {
    const { doneeId, userDoneeId, autoIntegrationType } = useSelector(
        (state: AppState) => ({
            doneeId: state.Donee.donee.id,
            userDoneeId: state.User.user.doneeId,
            autoIntegrationType: state.F1CCBIntegration.data.data.type,
        }),
    );
    const classes = homeTabStyle();
    const { t } = useAdvancedTranslation();

    const [getDataMatch, requestGetDataMatch] =
        useApiRequest<AutoIntegrationMatchNumberResponse>();
    const [donorDataMatch, setDonorDataMatch] = useState<TotalMatchDonors>();
    const [envelopeDataMatch, setEnvelopeDataMatch] =
        useState<TotalMatchEnvelopes>();
    const [getIntegrationSetting, requestIntegrationSetting] =
        useApiRequest<AutoIntegrationSettingData>();
    const [integrationSetting, setIntegrationSetting] =
        useState<AutoIntegrationSettingData>({
            data: {
                type: autoIntegrationType,
                doneeId: doneeId,
                settings: {
                    donationsAutoexport: true,
                },
            },
        });
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const getAutoIntegrationInfo = useCallback(() => {
        requestGetDataMatch(getIntegrationDetail(userDoneeId));
        setLoading(true);
    }, [userDoneeId, requestGetDataMatch]);
    const getAutoIntegrationSettings = useCallback(() => {
        requestIntegrationSetting(getIntegrationSettings(userDoneeId));
    }, [userDoneeId, requestIntegrationSetting]);

    useEffect(() => {
        getAutoIntegrationInfo();
        getAutoIntegrationSettings();
    }, [getAutoIntegrationInfo, getAutoIntegrationSettings]);

    useEffect(() => {
        if (getDataMatch.type === 'REQUEST_SUCCESS') {
            setDonorDataMatch(getDataMatch.response.data.donors);
            setEnvelopeDataMatch(getDataMatch.response.data.envelopes);
            setLoading(false);
        } else if (getDataMatch.type === 'REQUEST_ERROR') {
            setErrorMessage(getDataMatch.error.message);
            setLoading(false);
        }
        if (getIntegrationSetting.type === 'REQUEST_SUCCESS') {
            setIntegrationSetting(getIntegrationSetting.response);
        } else if (getIntegrationSetting.type === 'REQUEST_ERROR') {
            setErrorMessage(getIntegrationSetting.error.message);
        }
    }, [getDataMatch, getIntegrationSetting]);

    const copy = useMemo(
        () => ({
            donors: t('f1IntegrationPage.donorTab'),
            envelopes: t('f1IntegrationPage.envelopeTab'),
            noRecord: t('f1IntegrationPage.noRecord'),
            goTo: t('f1IntegrationPage.goTo'),
            turnOn: t('f1IntegrationPage.turnOn'),
            settingTab: t('menu.data.integrations.settings'),
            pleaseWait: t('f1IntegrationPage.pleaseWait'),
            syncComplete: t('f1IntegrationPage.syncComplete'),
        }),
        [t],
    );
    const closed = localStorage.getItem('closeSync');

    const viewUnMatchDonors = () => {
        goToTab(1, UNMATCH);
    };
    const viewUnMatchEnvelopes = () => {
        goToTab(2, UNMATCH);
    };
    const viewMatchedDonor = () => {
        goToTab(1, MATCHED);
    };
    const viewMatchedEnvelopes = () => {
        goToTab(2, MATCHED);
    };
    const goSettingTab = () => {
        goToTab(4);
    };

    return (
        <Grid container className={classes.homeTabContent}>
            <SyncNotification
                open={integrationSync !== 'INITIAL_SETUP_COMPLETED'}
                text={copy.pleaseWait}
                variant="warning"
            />
            <SyncNotification
                id="sync-complete-notification"
                onClose={handleCloseSync}
                open={
                    integrationSync === 'INITIAL_SETUP_COMPLETED' &&
                    integrationSetting?.data.settings.donationsAutoexport &&
                    !closeSync &&
                    closed !== 'closed'
                }
                text={copy.syncComplete}
                variant="success"
            />
            <SyncNotification
                open={errorMessage.length > 0}
                text={errorMessage}
                variant="error"
            />
            <SyncNotification
                open={
                    integrationSync === 'INITIAL_SETUP_COMPLETED' &&
                    !integrationSetting?.data.settings.donationsAutoexport &&
                    integrationSetting.data.type !== 'PLANNING_CENTER'
                }
                text={
                    <GivelifyLabel variant="body1B">
                        {copy.noRecord}
                        <GivelifyLink
                            onClick={goSettingTab}
                            text={copy.settingTab}
                            variant="body1B"
                        />
                        {copy.turnOn}
                    </GivelifyLabel>
                }
                variant="warning"
            />
            <Grid item className={classes.donorItem} md={6} xs={12}>
                <IntegrationStatsCard
                    loading={loading}
                    syncStatus={integrationSync === 'INITIAL_SETUP_COMPLETED'}
                    title={copy.donors}
                    totalMatched={donorDataMatch?.matched}
                    type="donor"
                    unMatchNumber={donorDataMatch?.unmatched}
                    viewMatch={viewMatchedDonor}
                    viewUnMatch={viewUnMatchDonors}
                />
            </Grid>
            <Grid item className={classes.envelopeItem} md={6} xs={12}>
                <IntegrationStatsCard
                    loading={loading}
                    syncStatus={integrationSync === 'INITIAL_SETUP_COMPLETED'}
                    title={copy.envelopes}
                    totalMatched={envelopeDataMatch?.matched}
                    type="envelope"
                    unMatchNumber={envelopeDataMatch?.unmatched}
                    viewMatch={viewMatchedEnvelopes}
                    viewUnMatch={viewUnMatchEnvelopes}
                />
            </Grid>
        </Grid>
    );
};

export default AutoIntegrationHomeTabContent;
