import { useMemo } from 'react';
import { GivelifyTabOption } from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';

const AutoIntegrationTabsHeader = (): GivelifyTabOption[] => {
    const { t } = useTranslation();
    const { totalUnmatchedEnvelopes, totalUnmatchedDonors } = useSelector(
        (state: AppState) => ({
            totalUnmatchedEnvelopes: state.F1CCBIntegration.unmatchedEnvelopes,
            totalUnmatchedDonors: state.F1CCBIntegration.unmatchedDonors,
        }),
    );

    const copy = useMemo(
        () => ({
            reportTab: t('menu.reports.title'),
            customReportTab: t('reports.customReport'),
            matchDonorTab: t('pages.integration_donor_matching.matchTitleTab'),
            matchEnvelopeTab: t('pages.integration_envelope_matching.title'),
            settingTab: t('menu.data.integrations.settings'),
            exportedDonationsTab: t(
                'pages.integration_exported_donations.exportedDonation',
            ),
            homeTab: t('f1IntegrationPage.homeTab'),
        }),
        [t],
    );

    const options: GivelifyTabOption[] = [
        { label: copy.homeTab },
        { label: copy.matchDonorTab, badgeText: totalUnmatchedDonors },
        { label: copy.matchEnvelopeTab, badgeText: totalUnmatchedEnvelopes },
        { label: copy.exportedDonationsTab },
        {
            label: copy.settingTab,
        },
    ];

    return options;
};

export default AutoIntegrationTabsHeader;
