import React, { useState } from 'react';
import { Donee, User } from '@givelify/givelify-ui';
import CardComplete from '../../cards/v2/CardComplete';
import { VideoCards } from '../../cards/videoCards';
import CollapsibleItemStack from '../../components/CollapsibleItemStack';

import { ProfileCard } from './ProfileCard';

interface CardsStackProps {
    onVideoCardClose: () => void;
    onProfileCardClose: () => void;
    introductionVideoCardsEnabled: boolean;
    hideVideoCard: boolean;
    donee: Donee;
    user: User;
    settingsUsersPath: string;
    hideProfileCard: boolean;
    onCompleteCardClose: () => void;
    showCompleteCard: boolean;
    setButtonBringToFront: (value: boolean) => void;
}

export const CardsStack: React.FC<CardsStackProps> = ({
    onProfileCardClose,
    introductionVideoCardsEnabled,
    hideVideoCard,
    donee,
    onVideoCardClose,
    settingsUsersPath,
    user,
    hideProfileCard,
    onCompleteCardClose,
    showCompleteCard,
    setButtonBringToFront
}) => {
    const { hasMid, organizationType } = donee.onboarding;

    const [videoCardIsHidden, setVideoCardIsHidden] = useState(
        !introductionVideoCardsEnabled || hideVideoCard,
    );
    const onVideoCardCloseInternal = () => {
        setVideoCardIsHidden(true);
        onVideoCardClose();
    };

    if (videoCardIsHidden && !(hasMid && showCompleteCard) && hideProfileCard)
        return null;

    return (
        <CollapsibleItemStack
            gap={16}
            items={[
                {
                    item: (
                        <VideoCards
                            onDismissClick={onVideoCardCloseInternal}
                            organizationType={organizationType}
                        />
                    ),
                    hidden: videoCardIsHidden,
                    testId: 'videoCards',
                },
                {
                    item: (
                        <CardComplete
                            hasMid={hasMid}
                            onCloseClick={onCompleteCardClose}
                            progress={100}
                        />
                    ),
                    hidden: !(hasMid && showCompleteCard),
                    testId: 'cardComplete',
                },
                {
                    item: (
                        <ProfileCard
                            donee={donee}
                            onClose={onProfileCardClose}
                            setButtonBringToFront={setButtonBringToFront}
                            settingsUsersPath={settingsUsersPath}
                            user={user}
                        />
                    ),
                    hidden: hideProfileCard,
                    testId: 'cardProfile',
                },
            ]}
            onAnimationFinished={() => undefined}
        />
    );
};
