import React from 'react';
import { GivelifyModal } from '@givelify/ui';
import { useMediaQuery, useTheme } from '@mui/material';
import { F1goSetupStep1 } from './components';
import { F1goSetupStep2 } from './components/F1goSetupStep2';

type F1goIntegrationSetupDialogProps = {
    open: boolean;
    handleClose: () => void;
    setShowBanner: () => void;
};

export const F1goIntegrationSetupDialog: React.FC<
    F1goIntegrationSetupDialogProps
> = ({ open, handleClose }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const [step, setStep] = React.useState(1);
    const onClose = React.useCallback(() => {
        handleClose();
        setStep(1);
    }, [handleClose]);
    return (
        <GivelifyModal
            disableBackdropClose
            fullWidth
            noPadding
            showCloseButton
            aria-labelledby="f1go-setup-title"
            fullScreen={isMobile}
            maxWidth="md"
            name="f1go-setup-dialog"
            onClose={onClose}
            open={open}
        >
            {step === 1 ? (
                <F1goSetupStep1 onSubmit={() => setStep(2)} />
            ) : (
                <F1goSetupStep2 />
            )}
        </GivelifyModal>
    );
};
