import { toISODate } from '@givelify/utils';
import dayjs, { Dayjs } from 'dayjs';
import {
    DonationQueryString,
    EnvelopesQueryString,
    getDonationQueryString,
    getEnvelopesQueryString,
} from '../../store/donations/donation/types';
import { GetDonorsRequest } from '../services/requests/GetDonorsRequest';

export const getSignInApiEndpoint = () => '/login';

export const getSignOutApiEndpoint = () => '/logout';

export const getSignInAdminApiEndpoint = () => '/admin/login';

export const getlegacyEnvelopesEndPoint = (
    doneeId: number,
    filter: 'any' | 'active' | 'inactive',
    page?: number,
    fetchAll?: boolean,
    sort?: string,
    givingTuesday2023 = false,
): string => {
    if (!page) page = 1;
    let query = '';
    switch (filter) {
        case 'active':
            query = '&active=true';
            break;
        case 'inactive':
            query = '&active=false';
            break;
        default:
            break;
    }
    if (fetchAll) {
        query = `${query}&pageSize=99999999`;
    }
    if (sort) {
        query = `${query}&sort=${sort}`;
    }
    if (givingTuesday2023) {
        query = `${query}&givingTuesday2023=true`;
    }
    return `/donees/${doneeId}/envelopes?page=${page}${query}`;
};

export const getUpdatePrioritiesEndpoint = (doneeId: number): string => {
    return `/donees/${doneeId}/envelopes/priorities`;
};

export const getEnvelopesUpdateEndpoint = (
    doneeId: number,
    envelopeId: number,
): string => {
    return `/donees/${doneeId}/envelopes/${envelopeId}`;
};

export const getEnvelopesCreateEndpoint = (doneeId: number): string => {
    return `/donees/${doneeId}/envelopes`;
};

export const getEnvelopesDeleteEndpoint = (
    doneeId: number,
    envelopeId: number,
): string => {
    return `/donees/${doneeId}/envelopes/${envelopeId}`;
};

export const getEnvelopesStatsEndpoint = (
    doneeId: number,
    envelopeId: number,
): string => {
    return `/donee/${doneeId}/offering/${envelopeId}/stats`;
};

export const getEnvelopesDonorsEndpoint = (
    doneeId: number,
    envelopeId: number,
): string => {
    return `/donee/${doneeId}/offering/${envelopeId}/donors`;
};

export function getEnvelopesDataEndpoint(
    doneeId: number,
    donationQuery: EnvelopesQueryString,
): string {
    return `/donees/${doneeId}/envelopes/altindex${getEnvelopesQueryString(
        donationQuery,
    )}`;
}

export function getAllEnvelopesDataEndpoint(
    doneeId: number,
    startDate: dayjs.Dayjs,
    endDate: dayjs.Dayjs,
): string {
    return `/donees/${doneeId}/donations/statistics/by-envelopes?start_date=${startDate.format(
        'YYYY-MM-DD',
    )}&end_date=${endDate.format('YYYY-MM-DD')}`;
}

export function getTotalDonationByQueryEndpoint(
    doneeId: number,
    donationQuery: DonationQueryString,
    pageName?: 'activity' | 'bankDeposit' | 'refund',
): string {
    return `/donees/${doneeId}/${
        pageName === 'bankDeposit' ? 'deposits' : 'donations'
    }/statistics${getDonationQueryString({
        ...donationQuery,
        onlyDate: pageName === 'bankDeposit',
    })}`;
}

export const getAverageDonationsApiEndpoint = (doneeId: number): string => {
    return `/donees/${doneeId}/statistics/average-donations`;
};

export const getLifeTimeDonationsApiEndpoint = (doneeId: number): string => {
    return `/donees/${doneeId}/donations/statistics/lifetime`;
};

export type DonationsStatisticType =
    | 'yearly'
    | 'monthly'
    | 'weekly'
    | 'daily'
    | 'hourly';

export const getDonationsStatisticApiEndpoint = (
    doneeId: number,
    startDate: dayjs.Dayjs,
    endDate: dayjs.Dayjs,
    type: DonationsStatisticType,
): string => {
    return `/donees/${doneeId}/donations/statistics/trend?period_type=${type}&start_date=${startDate.format(
        'YYYY-MM-DD',
    )}&end_date=${endDate.format('YYYY-MM-DD')}`;
};

export const getDonationsStatisticByEnvelopesApiEndpoint = (
    doneeId: number,
    startDate: dayjs.Dayjs,
    endDate: dayjs.Dayjs,
    type: DonationsStatisticType,
): string => {
    return `/donees/${doneeId}/donations/statistics/trend/by-envelopes?period_type=${type}&start_date=${startDate.format(
        'YYYY-MM-DD',
    )}&end_date=${endDate.format('YYYY-MM-DD')}`;
};

const getDonorsQuery = ({
    donationStatuses,
    asc,
    desc,
    ...params
}: GetDonorsRequest): string => {
    return `${
        donationStatuses
            ? donationStatuses
                  .map((status) => '&donationStatuses[]=' + status)
                  .join('')
            : ''
    }&${new URLSearchParams(params as Record<string, string>).toString()}${
        asc ? '&asc=1' : ''
    }${desc ? '&desc=1' : ''}`;
};

export const getDonorsEndpoint = (
    doneeId: number,
    payload: GetDonorsRequest,
): string => {
    return `/donees/${doneeId}/donors?${getDonorsQuery(payload)}`;
};

export const getDonorProfileEndpoint = (
    doneeId: number,
    donorId: string,
): string => {
    return `/donees/${doneeId}/donors/${donorId}`;
};

export const getDonorDonationsStatisticEndpoint = (
    doneeId: number,
    donorId: string,
    type: DonationsStatisticType,
    startDate: dayjs.Dayjs,
    endDate: dayjs.Dayjs,
): string => {
    return `/donees/${doneeId}/donors/${donorId}/donations/trend?period_type=${type}&start_date=${startDate.format(
        'YYYY-MM-DD',
    )}&end_date=${endDate.format('YYYY-MM-DD')}`;
};

export const getPagedDonationsByDateApiEndpoint = (
    doneeId: number,
    date: string,
    page: number,
    pending?: boolean,
): string => {
    return `/donees/${doneeId}/donations?${
        pending ? 'pending&' : ''
    }deposit_date=${date}&page=${page}`;
};

export const getPagedDonationsByStartEndDateEndpoint = (
    doneeId: number,
    donorId: string,
    startDate: Dayjs,
    endDate: Dayjs,
    page: number,
) => {
    return `/donees/${doneeId}/donors/${donorId}/donations?startDate=${toISODate(
        startDate,
    )}&endDate=${toISODate(endDate)}&page=${page}`;
};

export const getPagedDonationsMessagesEndpoint = (
    doneeId: number,
    donorId: string,
    startDate: Dayjs,
    endDate: Dayjs,
    page: number,
) => {
    return `/donees/${doneeId}/donors/${donorId}/messages?startDate=${toISODate(
        startDate,
    )}&endDate=${toISODate(endDate)}&page=${page}`;
};

export const getGenerateReportsApiEndpoint = (): string => {
    return `/reports/generate`;
};

export const getReportDetailsApiEndpoint = (reportId: number): string => {
    return `/reports/${reportId}`;
};

export const getPollsApiEndpoint = (doneeId: number): string => {
    return `/donees/${doneeId}/polls`;
};

export const getPollAnswerApiEndpoint = (
    doneeId: number,
    pollId: number,
): string => {
    return `/donees/${doneeId}/polls/${pollId}`;
};

export const getBankAccountsApiEndpoint = (id: number): string =>
    `/donees/${id}/bank-accounts`;

export const getPrimaryRepresentativeApiEndpoint = (doneeId: number) =>
    `/donees/${doneeId}/beneficiary`;

export const getCalendlyScheduledEvents = (
    eventId: string,
    inviteeId: string,
) => `/calendly/scheduled-events?event_id=${eventId}&invitee_id=${inviteeId}`;

export const getIntegrationDonorsApiEndpoint = (donorId: number) => {
    return `/donees/${donorId}/integration/chms/donors`;
};

export const getIntegrationDonorOptionsApiEndpoint = (donorId: number) => {
    return `/donees/${donorId}/integration/chms/donors/options`;
};
