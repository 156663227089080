import { dateToString } from '@givelify/utils';
import { AxiosResponse } from 'axios';
import {
    DonorMatchingProgressResponse,
    MetaMatchedDonorProgress,
} from 'pages/integrations/IntegrationContent/TabsContent/DonorMatching/types';
import {
    EnvelopeMatchingProgressResponse,
    EnvelopeResponseData,
    MetaMatchedEnvelopeProgress,
} from 'pages/integrations/IntegrationContent/TabsContent/EnvelopeMatching/types';
import { mapReportHistoryResponse } from 'pages/integrations/IntegrationContent/TabsContent/Reports/utils/mapReportResponse';
import { ThunkAction } from 'redux-thunk';
import { AppState, AppActions } from '..';
import { DonorResponse } from '../../types/donorTypes';
import { ReportHistoryResponse } from '../report/types';
import { httpGet, httpPatch } from '../thunks';
import {
    LoadReportsStart,
    LoadReportsSuccess,
    LoadDonorsMatchProgressSuccess,
    LoadEnvelopesMatchProgressSuccess,
    LoadMatchProgressStart,
    LoadTotalMatchStateStart,
    LoadTotalMatchStateSuccess,
    OnMatchDonor,
    OnMatchEnvelope,
    DownloadReport as DownloadReportAction,
} from './actions';

export const GetReportsAsync =
    (
        page: number,
        pageSize: number,
    ): ThunkAction<void, AppState, undefined, AppActions> =>
    async (dispatch, getState) => {
        const parentCampus = getState().Donee.campuses[0];
        if (!parentCampus) return;

        const doneeId = parentCampus.id;

        dispatch(LoadReportsStart(page));
        const reportsHistoryResponse: AxiosResponse<ReportHistoryResponse> =
            await httpGet(
                `donees/${doneeId}/reports?page=${page}&pageSize=${pageSize}`,
            )(dispatch);

        if (reportsHistoryResponse.status !== 200) return;

        const reports = mapReportHistoryResponse(reportsHistoryResponse.data);

        dispatch(
            LoadReportsSuccess(reports, reportsHistoryResponse.data.pagination),
        );
    };

export const LoadTotalMatchStateAsync =
    (): ThunkAction<void, AppState, undefined, AppActions> =>
    async (dispatch, getState) => {
        dispatch(LoadTotalMatchStateStart());

        const parentCampus = getState().Donee.campuses[0];
        if (!parentCampus) return;

        const doneeId = parentCampus.id;

        const loadDonors = async () => {
            const response: AxiosResponse<DonorMatchingProgressResponse> =
                await httpGet(
                    `/donees/${doneeId}/donors/campus?unmatched=true`,
                )(dispatch);

            if (response.status !== 200) return;
            const count =
                response.data.meta.totalDonors -
                response.data.meta.totalMatchedDonors;

            dispatch(LoadTotalMatchStateSuccess('donors', count));
        };

        const loadEnvelopes = async () => {
            const response: AxiosResponse<EnvelopeMatchingProgressResponse> =
                await httpGet(
                    `/donees/${doneeId}/envelopes?unmatched=true&all=true`,
                )(dispatch);

            if (response.status !== 200) return;
            const count =
                response.data.meta.totalEnvelopes -
                response.data.meta.totalMatchedEnvelopes;

            dispatch(LoadTotalMatchStateSuccess('envelopes', count));
        };

        await Promise.all([loadDonors(), loadEnvelopes()]);
    };

export const GetMatchProgressAsync =
    (
        type: 'donor' | 'envelope',
        filter: string,
        doneeId: number,
        reportId: number,
        isUnmatched?: boolean,
        campusId?: number,
    ): ThunkAction<void, AppState, undefined, AppActions> =>
    async (dispatch) => {
        dispatch(LoadMatchProgressStart());

        const allCampuses = !campusId || campusId === -1;

        const prefix =
            type === 'donor'
                ? reportId
                    ? 'donors'
                    : 'donors/campus'
                : 'envelopes';

        const url =
            filter && filter.length > 0
                ? `/donees/${
                      allCampuses ? doneeId : campusId
                  }/${prefix}?filter=${filter}&all=${allCampuses}`
                : reportId
                ? `/reports/${reportId}/unmatched-${prefix}`
                : `/donees/${allCampuses ? doneeId : campusId}/${prefix}?${
                      allCampuses ? `all=true` : ''
                  }`;

        const response: AxiosResponse<
            MetaMatchedEnvelopeProgress | MetaMatchedDonorProgress
        > = await httpGet(url)(dispatch);

        if (response.status !== 200) return;

        if (type === 'donor') {
            const data = response.data as unknown as MetaMatchedDonorProgress;

            dispatch(
                LoadDonorsMatchProgressSuccess(
                    data.meta.totalDonors,
                    data.meta.totalMatchedDonors,
                    reportId,
                ),
            );
        } else {
            const data =
                response.data as unknown as MetaMatchedEnvelopeProgress;

            dispatch(
                LoadEnvelopesMatchProgressSuccess(
                    data.meta.totalEnvelopes,
                    data.meta.totalMatchedEnvelopes,
                    reportId,
                ),
            );
        }
    };

export const MatchAsync =
    (
        type: 'donor' | 'envelope',
        doneeId: number,
        donorOrEnvelopeId: number,
        newExternalId: string,
        previousExternalId: string,
        reportId?: number,
    ): ThunkAction<
        Promise<{ success: false } | { success: true; result: string }>,
        AppState,
        undefined,
        AppActions
    > =>
    async (dispatch) => {
        const url =
            type === 'donor'
                ? `donees/${doneeId}/donors/${donorOrEnvelopeId}`
                : `donees/${doneeId}/envelopes/${donorOrEnvelopeId}`;

        const response: AxiosResponse<DonorResponse | EnvelopeResponseData> =
            await httpPatch(url, {
                // eslint-disable-next-line
                external_id: newExternalId || null,
            })(dispatch);

        if (response.status !== 200) return { success: false };

        const matchCreated = !previousExternalId;
        const matchRemoved = !newExternalId;

        let result: string;
        if (type === 'donor') {
            const data = response as unknown as DonorResponse;
            result = data?.data?.externalId;
            dispatch(
                OnMatchDonor(
                    matchCreated,
                    matchRemoved,
                    reportId,
                    donorOrEnvelopeId,
                ),
            );
        } else {
            const data = response.data as unknown as EnvelopeResponseData;
            result = data.externalId;
            dispatch(OnMatchEnvelope(matchCreated, matchRemoved, reportId));
        }

        return { success: true, result };
    };

export const DownloadReport =
    (
        reportId: number,
        downloadLink: string,
    ): ThunkAction<void, AppState, undefined, AppActions> =>
    async (dispatch, getState) => {
        const userName = getState().User.user.fullName;
        dispatch(
            DownloadReportAction(reportId, userName, dateToString(new Date())),
        );
    };

export interface F1IntegrationLoginData {
    username: string;
    password: string;
    consumerKey: string;
    consumerSecret: string;
    churchCode: string;
}

export interface FellowshipOneFormData {
    username: string;
    password: string;
}

export interface F1CreateNewProfileData {
    firstName: string;
    lastName: string;
    address: string;
    email: string;
    phone: string;
    status: string;
    memberType: string;
    addressType: string;
    emailType: string;
    phoneType: string;
}
