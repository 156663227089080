import React from 'react';
import {
    GivelifyTabOption,
    GivelifyButton,
    GivelifyIcon,
    GivelifyNotificationProps,
    GivelifyNotification,
} from '@givelify/ui';
import { PAGE_NAME, RequestState, TimeFrameValue } from '@givelify/utils';
import { DonationsStatisticType } from 'api/client/endpoints';
import { GetDonorDonationsStatisticResponse } from 'api/services/responses';
import PageTitle from 'components/PageTitle';
import { DonorTrendChart } from 'components/widgets';
import { useTranslation } from 'react-i18next';
import { DonorCard, DonorCardProps } from './components';
import { HistoryTab } from './components/historyTab';
import { MessageHistoryTab } from './components/messageHistoryTab';
import { useDonorProfileContext } from './DonorProfileContext';
import {
    HistoryTabs,
    ChartBox,
    DatePickersWidgetNoWrap,
    Header,
    InfoRow,
    Page,
} from './styles';

type DonorProfileViewProps = {
    handleTabChange: (value: number) => void;
    currentTab: number;
    tabsOptions: GivelifyTabOption[];
    setTimeFrame: (state: TimeFrameValue) => void;
    donorCardProps: DonorCardProps;
    onBackClick: () => void;
    notification: GivelifyNotificationProps;
    setNotification: (notification: GivelifyNotificationProps) => void;
    donationsRequest: RequestState<GetDonorDonationsStatisticResponse>;
    rangeDateType: DonationsStatisticType;
};

const DonorProfileViewPage: React.FC<DonorProfileViewProps> = ({
    handleTabChange,
    tabsOptions,
    currentTab,
    setTimeFrame,
    donorCardProps,
    onBackClick,
    notification,
    setNotification,
    donationsRequest,
    rangeDateType,
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            back: t('labels.back'),
            title: t('donors.profile.title'),
            thisMonth: t('components.timeFrameSelector.thisMonth'),
            thisWeek: t('components.timeFrameSelector.thisWeek'),
            thisYear: t('components.timeFrameSelector.thisYear'),
            lifetime: t('components.timeFrameSelector.lifetime'),
        }),
        [t],
    );

    const { timeFrame } = useDonorProfileContext();

    return (
        <Page>
            <PageTitle
                description={PAGE_NAME.DonorProfile}
                title={PAGE_NAME.DonorProfile}
            />

            <Header>
                <GivelifyButton
                    disableRipple
                    className="back-button"
                    data-testid="back-button"
                    name="Back"
                    onClick={onBackClick}
                    startIcon={<GivelifyIcon variant="left-arrow" />}
                    text={copy.back}
                    variant="ghost"
                />
                <DatePickersWidgetNoWrap
                    setTimeFrame={setTimeFrame}
                    shortcuts={[
                        {
                            id: 'lifetime',
                            label: copy.lifetime,
                        },
                        {
                            id: 'thisYear',
                            label: copy.thisYear,
                        },
                        {
                            id: 'thisMonth',
                            label: copy.thisMonth,
                        },
                        {
                            id: 'thisWeek',
                            label: copy.thisWeek,
                        },
                    ]}
                    timeFrame={timeFrame}
                    title={copy.title}
                    titleVariant="heading2"
                />
            </Header>
            <InfoRow>
                <DonorCard {...donorCardProps} />
                <ChartBox>
                    <DonorTrendChart
                        donationsDataRequest={donationsRequest}
                        rangeDateType={rangeDateType}
                        timeFrame={timeFrame}
                    />
                </ChartBox>
            </InfoRow>

            <HistoryTabs
                onChange={handleTabChange}
                options={tabsOptions}
                value={currentTab}
            />
            <GivelifyNotification
                {...notification}
                onClose={(_, reason) => {
                    if (reason === 'dismiss') setNotification(null);
                }}
                open={!!notification}
                transitionDuration={0}
            />
            {currentTab === 0 ? <HistoryTab /> : null}
            {currentTab === 1 ? <MessageHistoryTab /> : null}
        </Page>
    );
};

export const DonorProfileView = React.memo(DonorProfileViewPage);
