import { GivelifyColorPalette } from '@givelify/givelify-ui';
import { GivelifyNotification } from '@givelify/ui';
import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { styled } from '@mui/material';

export const homeTabStyle = makeStyles((theme: Theme) => {
    return createStyles({
        homeTabContent: {
            marginTop: 40,
        },
        donorItem: {
            '& > div': {
                marginRight: theme.spacing(2),
                [theme.breakpoints.down('sm')]: {
                    marginRight: 0,
                },
            },
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(3),
            },
        },
        envelopeItem: {
            '& > div': {
                marginLeft: theme.spacing(2),
                [theme.breakpoints.down('sm')]: {
                    marginLeft: 0,
                },
            },
        },
        homeItemContent: {
            height: '100%',
            borderRadius: 10,
            background: GivelifyColorPalette.primaryWhite,
            boxShadow: '0 0 9px rgba(131, 131, 131, 0.4)',
            padding: theme.spacing(5),
            textAlign: 'center',
        },
        homeItemTitle: {
            lineHeight: '22px',
            fontWeight: 700,
        },
        unMatchContent: {
            marginTop: 56,
        },
        matchedContent: {
            marginTop: 24,
        },
        number: {
            fontSize: 48,
            lineHeight: '38px',
            marginBottom: theme.spacing(3),
        },
        matchNumber: {
            fontSize: 48,
            fontWeight: 600,
            lineHeight: '64px',
        },
        viewUnMatch: {
            marginTop: theme.spacing(5),
        },
        totalMatched: {
            marginTop: theme.spacing(5),
        },
        totalMatchedText: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
        },
        totalUnmatchedText: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            fontSize: 16,
            fontWeight: 800,
        },
        matchedTitle: {
            textTransform: 'capitalize',
            fontSize: 24,
            fontWeight: 700,
            lineHeight: '32px',
        },
        unMatchedText: {
            fontSize: 16,
            fontWeight: 800,
        },
        centerButton: {
            margin: '0 auto',
        },
        matchedNumber: {
            fontWeight: 700,
            background: GivelifyColorPalette.neutralHoverGrey,
            borderRadius: 15,
            padding: theme.spacing(1, 2),
            marginRight: theme.spacing(2),
        },
        noUnMatchContent: {
            marginTop: theme.spacing(5),
        },
        caughtUp: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(5),
        },
        errorBanner: {
            width: '100%',
            marginBottom: theme.spacing(4),
            '& button': {
                minHeight: 'unset',
            },
        },
        tabLink: {
            paddingLeft: 3,
        },
        integrationAlert: {
            marginBottom: theme.spacing(5),
            width: '100%',
            display: 'block',
            '& div, & button': {
                display: 'inline',
            },
            '& button': {
                fontSize: 16,
                lineHeight: '22px',
            },
        },
    });
});

export const donorTabStyle = makeStyles((theme: Theme) => {
    return createStyles({
        donorTabContent: {
            marginTop: theme.spacing(4),
        },
        searchContent: {
            width: '100%',
            maxWidth: 340,
            [theme.breakpoints.between(0, 1001)]: {
                marginTop: theme.spacing(4),
                marginLeft: 0,
                maxWidth: 352,
            },
        },
        filterRow: {
            marginRight: 'auto',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
        filterItem: {
            marginRight: theme.spacing(5),
        },
        selector: {
            minWidth: 160,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        autocomplete: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.shape.borderRadius,
            '&:focus-within': {
                boxShadow: '0 0 0',
                border: 0,
                borderRadius: '25px',
            },
            '& .MuiInput-input': {
                border: 'none',
                backgroundColor: theme.palette.background.paper,
            },
        },
        autocompleteInput: {
            '& .MuiInputBase-root': {
                border: '1px solid ' + GivelifyColorPalette.neutralGrey75,
                borderRadius: '25px',
                paddingRight: '16px !important',
                paddingLeft: theme.spacing(3),
                paddingBottom: 0,
                height: 40,
                '&:focus-within': {
                    boxShadow: '0 0 0',
                },
            },
            '&:focus': {
                border: 'none',
                outline: 0,
                boxShadow: 'none',
            },
            '& .MuiInputBase-input': {
                paddingLeft: '16px !important',
                '&:focus': {
                    outline: 0,
                    border: 'none',
                    boxShadow: '0 0 0',
                },
            },
            '& svg': {
                fontSize: 16,
            },
        },
        autocompletePopper: {
            display: 'none',
        },
        donorTableContent: {
            marginTop: theme.spacing(3),
            [theme.breakpoints.between(0, 1113)]: {
                overflow: 'hidden',
                width: '100%',
            },
        },
        matchRowContent: {
            flexWrap: 'nowrap',
            borderBottom: '1px solid ' + GivelifyColorPalette.neutralPlatinum,
            [theme.breakpoints.down('md')]: {
                flexWrap: 'wrap',
            },
        },
        matchItem: {
            padding: theme.spacing(4, 3),
            display: 'flex',
            alignItems: 'center',
            lineHeight: '16px',
            [theme.breakpoints.down('sm')]: {
                borderBottom:
                    '1px solid ' + GivelifyColorPalette.neutralPlatinum,
            },
            '& .matchBtn': {
                maxWidth: 114,
                minWidth: 0,
            },
        },
        matchColumn: {
            padding: theme.spacing(3, 4),
        },
        matchDonorLogo: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRight: '1px solid ' + GivelifyColorPalette.neutralPlatinum,
            minWidth: 72,
        },
        matchHeaderLogo: {
            display: 'flex',
            alignItems: 'center',
            minWidth: 72,
            maxWidth: 72,
            '&&': {
                padding: 0,
                justifyContent: 'center',
            },
        },
        givelifyLogo: {
            [theme.breakpoints.between(0, 1113)]: {
                width: 32,
            },
        },
        matchDonorItem: {
            padding: theme.spacing(3, 3, 2),
            display: 'flex',
            alignItems: 'center',
            lineHeight: '16px',
            '& > div': {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
        matchDonorName: {
            minWidth: 193,
            width: 'calc(27% - (72px / 4))',
            [theme.breakpoints.between(0, 1053)]: {
                minWidth: 163,
            },
        },
        matchDonorNameHeader: {
            minWidth: 193,
            width: 'calc(27% - (72px / 4))',
            [theme.breakpoints.between(0, 1053)]: {
                minWidth: 163,
            },
        },
        matchDonorEmail: {
            minWidth: 162,
            width: 'calc(23% - (72px / 4))',
            [theme.breakpoints.between(0, 1053)]: {
                minWidth: 132,
            },
        },
        matchDonorPhone: {
            minWidth: 153,
            width: 'calc(22% - (72px / 4))',
        },
        matchDonorAddress: {
            width: 'calc(28% - (72px / 4))',
            minWidth: 201,
            [theme.breakpoints.between(0, 1053)]: {
                minWidth: 167,
            },
        },
        matchDonorF1Item: {
            padding: theme.spacing(2, 3, 3),
            display: 'flex',
            alignItems: 'center',
            lineHeight: '16px',
            '& > div': {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
            [theme.breakpoints.down('sm')]: {
                borderBottom:
                    '1px solid ' + GivelifyColorPalette.neutralPlatinum,
            },
        },
        f1Donor: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        f1DonorName: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        givelifyAvatar: {
            minWidth: 40,
        },
        matchDonorButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            width: 137,
            flex: '0 0 137px',
            backgroundColor: '#fff',
            [theme.breakpoints.between(0, 976)]: {
                minWidth: 137,
                boxShadow: '-11px 1px 11px 0px #dde2e4',
            },
            [theme.breakpoints.between(0, 1113)]: {
                padding: 0,
            },
        },
        donorMatchBtn: {
            maxWidth: 114,
            minWidth: 0,
            [theme.breakpoints.between(0, 1399)]: {
                padding: theme.spacing(0, 3),
            },
        },
        f1DonorId: {
            fontSize: 12,
            lineHeight: '16px',
            color: '#676E77',
        },
        donorAvatar: {
            minWidth: 32,
        },
        donorNameRow: {
            marginLeft: theme.spacing(2),
        },
        donorAddress: {
            whiteSpace: 'unset',
        },
        matchedDonorTableContent: {
            marginTop: theme.spacing(3),
            [theme.breakpoints.between(0, 1113)]: {
                overflow: 'hidden',
                width: '100%',
            },
        },
        matchDonorContent: {
            flexWrap: 'nowrap',
        },
        matchDonorInfo: {
            width: 'calc(100% - 137px)',
            '& $donorRow': {
                height: '120px',
            },
            [theme.breakpoints.between(0, 1400)]: {
                width: '100%',

                overflow: 'auto',
            },
        },
        donorRow: {
            width: '100%',
        },
        headerDonorRow: {
            fontWeight: 'bold',
            height: 54,
            '& $donorContainer': {
                height: '100%',
            },
        },
        donorContainer: {
            flexWrap: 'nowrap',
            '& .header-item': {
                display: 'flex',
                height: 54,
                borderBottom:
                    '1px solid ' + GivelifyColorPalette.neutralPlatinum,
            },
        },
        headerButtonItem: {
            height: 54,
            width: '100%',
            borderBottom: '1px solid ' + GivelifyColorPalette.neutralPlatinum,
        },
        buttonItem: {
            height: 120,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderBottom: '1px solid ' + GivelifyColorPalette.neutralPlatinum,
        },
        chmsItem: {
            height: 64,
            borderBottom: '1px solid ' + GivelifyColorPalette.neutralPlatinum,
            [theme.breakpoints.between(0, 1113)]: {
                height: 63,
            },
        },
        unmatchDonorContent: {
            flexWrap: 'nowrap',
        },
        unmatchDonorInfo: {
            width: 'calc(100% - 137px)',
            [theme.breakpoints.between(0, 1399)]: {
                overflow: 'auto',
            },
        },
        unmatchDonorRow: {
            flexWrap: 'nowrap',
        },
        unmatchDonorName: {
            width: '30%',
            minWidth: 200,
            overflow: 'hidden',
            borderBottom: '1px solid ' + GivelifyColorPalette.neutralPlatinum,
        },
        unmatchDonation: {
            width: '20%',
            minWidth: 140,
            borderBottom: '1px solid ' + GivelifyColorPalette.neutralPlatinum,
        },
        unmatchNumber: {
            width: '23%',
            minWidth: 145,
            borderBottom: '1px solid ' + GivelifyColorPalette.neutralPlatinum,
        },
        unmatchDate: {
            width: '27%',
            minWidth: 180,
            borderBottom: '1px solid ' + GivelifyColorPalette.neutralPlatinum,
        },
        unmatchButton: {
            width: 202,
            height: 54,
            borderBottom: '1px solid ' + GivelifyColorPalette.neutralPlatinum,
            [theme.breakpoints.between(0, 1399)]: {
                width: 165,
                minWidth: 165,
                boxShadow: '-11px 1px 11px 0px #dde2e4',
            },
        },
        unmatchButtonItem: {
            height: 81,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderBottom: '1px solid ' + GivelifyColorPalette.neutralPlatinum,
        },
        unmatchDonorButton: {
            width: 137,
            [theme.breakpoints.between(0, 976)]: {
                width: 137,
                minWidth: 137,
                boxShadow: '-11px 1px 11px 0px #dde2e4',
            },
        },
        unmatchHeaderDonorButton: {
            width: '100%',
            height: 54,
            borderBottom: '1px solid ' + GivelifyColorPalette.neutralPlatinum,
        },
        unmatchHeaderDonorRow: {
            fontWeight: 'bold',
        },
        donorUnmatchName: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    });
});

export const envelopeTabStyle = makeStyles((theme: Theme) => {
    return createStyles({
        envelopeTabContent: {
            marginTop: theme.spacing(4),
        },
        envelopeTableContent: {
            marginTop: theme.spacing(3),
        },
        confirmModalContent: {
            textAlign: 'center',
            padding: theme.spacing(0, 7),
        },
        modalTitle: {
            marginBottom: theme.spacing(4),
        },
        confirmModalGroupButton: {
            marginTop: theme.spacing(8),
            '& button': {
                margin: theme.spacing(0, 3),
            },
        },
    });
});

export const exportTabStyle = makeStyles((theme: Theme) => {
    return createStyles({
        exportTabContent: {
            marginTop: theme.spacing(4),
        },
        searchIcon: {
            fontSize: 14,
        },
        searchExport: {
            marginRight: `-${theme.spacing(3)}px`,
        },
        searchInput: {
            marginRight: theme.spacing(5),
            maxWidth: 340,
            marginLeft: 'auto',
            '& .MuiInput-input': {
                borderRadius: 50,
                height: '32px',
                padding: theme.spacing(1, 3),
                boxSizing: 'border-box',
                minWidth: 340,
                [theme.breakpoints.down('md')]: {
                    minWidth: 0,
                },
            },
        },
        root: {
            width: '100%',
        },
        tableRow: {
            '& .MuiTableCell-root': {
                color: '#373739',
            },
        },
        TableCellExport: {
            borderRight: '1px solid rgba(224, 224, 224, 1)',
        },
        tableCellName: {
            width: 248,
        },
        tableCellSubFund: {
            width: 216,
        },
        inputPropsClassName: {
            marginLeft: '100px',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        donationPagination: {
            '& button': {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        exportTableWrap: {
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1)',
            background: '#fff',
            marginTop: theme.spacing(2),
        },
        exportedTable: {
            '& > thead': {
                '& tr th': {
                    lineHeight: '16px',
                    paddingLeft: theme.spacing(3),
                    paddingRight: theme.spacing(3),
                    fontSize: 14,
                },
            },
            '& > tbody': {
                '& tr:hover': {
                    background: GivelifyColorPalette.neutralHoverGrey,
                },
                '& tr td': {
                    lineHeight: '16px',
                    padding: theme.spacing(3),
                    fontSize: 14,
                    [theme.breakpoints.down('xs')]: {
                        padding: theme.spacing(3),
                    },
                },
            },
        },
        exportError: {
            margin: theme.spacing(2, 0),
        },
        exportModalContent: {
            padding: '0 40px 40px',
        },
        selectDateRange: {
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'space-between',
        },
        buttonRow: {
            marginTop: 56,
            display: 'flex',
            justifyContent: 'flex-end',
        },
        exportBtn: {
            marginLeft: theme.spacing(3),
        },
    });
});

export const SyncNotification = styled(GivelifyNotification)(({ theme }) => ({
    marginBottom: theme.spacing(5.25),
}));
