import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    GivelifyBox,
    AddressWithPhone,
    useApiRequest,
    GivelifySnackbar,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import {
    CustomizeProfileStepOne,
    ImageWithDimensions,
    OrganizationLogoSection,
    CustomizeProfileRef,
    AppProfile,
    OrganizationType,
    I18N_NAMESPACE,
    isImageChanged,
    appProfileUpdateOrganizationAddressApi,
    appProfileUpdateBannerImageApi,
    appProfileUpdateOrganizationLogoApi,
    appProfileUpdateFaithLeaderApi,
    FaithLeaderEditorInfo,
    AddressEditorFormRef,
} from '@givelify/onboarding';
import { ErrorText } from 'components/ErrorText';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { AppState } from 'store';
import {
    removeDoneeFaithLeader,
    setDoneeAddress,
    setDoneeBannerImage,
    setDoneeFaithLeader,
    setDoneeOrganizationLogo,
} from 'store/donee/actions';
import { v4 as uuidv4 } from 'uuid';

interface AdminAppProfileViewProps {
    appProfile: AppProfile;
    organizationName: string;
    organizationAddress: AddressWithPhone;
    organizationLogo: ImageWithDimensions | undefined;
    setOrganizationLogo: (image?: ImageWithDimensions) => unknown;
    organizationType: OrganizationType;
}

export const AdminAppProfileView: React.FC<AdminAppProfileViewProps> = (
    props,
) => {
    const {
        organizationType,
        appProfile,
        organizationAddress,
        organizationLogo,
        organizationName,
        setOrganizationLogo,
    } = props;
    const { PATH } = useGasRouterContext();
    const { t } = useAdvancedTranslation({ namespace: I18N_NAMESPACE });
    const { errorMessage, changesSaved } = useMemo(
        () => ({
            errorMessage: t('errors.generic'),
            changesSaved: t('customizeProfile.changesSaved'),
        }),
        [t],
    );
    const dispatch = useDispatch();
    const { userId, doneeId } = useSelector((state: AppState) => ({
        userId: state.User.user.id,
        doneeId: state.Donee.donee?.id,
    }));
    const [appProfileHash, setAppProfileHash] = useState(uuidv4());
    const editorRef = useRef<CustomizeProfileRef>(null);
    const addressEditorRef = useRef<AddressEditorFormRef>(null);
    const [saveOrgAddressRequest, makeSaveOrgAddressRequest] =
        useApiRequest<unknown>();
    const [saveLogoRequest, makeSaveLogoRequest] = useApiRequest<unknown>();
    const [saveBannerRequest, makeSaveBannerRequest] = useApiRequest<unknown>();
    const [saveFaithLeaderRequest, makeSaveFaithLeaderRequest] =
        useApiRequest<unknown>();
    const [showSnackBar, setShowSnackBar] = useState<boolean | undefined>(
        undefined,
    );
    const [bannerImageToSave, setBannerImageToSave] = React.useState<
        ImageWithDimensions | undefined
    >(undefined);
    const [orgLogoToSave, setOrgLogoToSave] = React.useState<
        ImageWithDimensions | undefined
    >(undefined);
    const [addressToSave, setAddressToSave] = React.useState<
        AddressWithPhone | undefined
    >(undefined);

    const onOrgLogoSaveClick = useCallback(() => {
        if (!isEqual(appProfile.organizationLogo, organizationLogo)) {
            setOrgLogoToSave(organizationLogo);
            makeSaveLogoRequest(
                appProfileUpdateOrganizationLogoApi(doneeId, {
                    organizationLogo: {
                        url: organizationLogo.url,
                        croppedCoordinates:
                            organizationLogo.dimensions.croppedAreaPixels,
                    },
                }),
            );
        }
    }, [appProfile, organizationLogo, doneeId, makeSaveLogoRequest]);

    const onOrgAddressSaveClick = useCallback(
        (organizationAddress: AddressWithPhone) => {
            if (!isEqual(appProfile.organizationAddress, organizationAddress)) {
                setAddressToSave(organizationAddress);
                makeSaveOrgAddressRequest(
                    appProfileUpdateOrganizationAddressApi(doneeId, {
                        organizationAddress: organizationAddress,
                    }),
                );
            }
        },
        [appProfile, doneeId, makeSaveOrgAddressRequest],
    );

    const onBannerImageSaveClick = useCallback(
        (bannerImage: ImageWithDimensions) => {
            if (!isEqual(appProfile.bannerImage, bannerImage)) {
                setBannerImageToSave(bannerImage);
                makeSaveBannerRequest(
                    appProfileUpdateBannerImageApi(doneeId, {
                        bannerImage: {
                            url: bannerImage.url,
                            croppedCoordinates:
                                bannerImage.dimensions.croppedAreaPixels,
                        },
                    }),
                );
            }
        },
        [appProfile, doneeId, makeSaveBannerRequest],
    );

    const imageIsLocalFile = useCallback(
        (faithLeaderImage: ImageWithDimensions | undefined) => {
            if (faithLeaderImage) {
                if (
                    faithLeaderImage.url &&
                    faithLeaderImage.url.startsWith('http')
                ) {
                    return false;
                }
            }
            return true;
        },
        [],
    );

    const onFaithLeaderSaveClick = useCallback(
        (
            faithLeader: FaithLeaderEditorInfo,
            faithLeaderImage: ImageWithDimensions,
            doNotDisplayFaithLeader: boolean,
        ) => {
            if (
                faithLeaderImage !== undefined ||
                doNotDisplayFaithLeader !==
                    appProfile.doNotDisplayFaithLeader ||
                (!doNotDisplayFaithLeader &&
                    !isEqual(appProfile.faithLeader, faithLeader))
            ) {
                makeSaveFaithLeaderRequest(
                    appProfileUpdateFaithLeaderApi(doneeId, {
                        faithLeaderImage: imageIsLocalFile(faithLeaderImage)
                            ? isImageChanged(
                                  appProfile.faithLeaderImage,
                                  faithLeaderImage,
                              )
                            : undefined,
                        doNotDisplayFaithLeader: doNotDisplayFaithLeader,
                        faithLeader: faithLeader,
                    }),
                );
            }
        },
        [appProfile, doneeId, makeSaveFaithLeaderRequest, imageIsLocalFile],
    );

    useEffect(() => {
        if (
            saveLogoRequest.type === 'REQUEST_START' ||
            saveBannerRequest.type === 'REQUEST_START' ||
            saveOrgAddressRequest.type === 'REQUEST_START' ||
            saveFaithLeaderRequest.type === 'REQUEST_START'
        ) {
            setShowSnackBar(false);
        } else if (
            saveLogoRequest.type !== 'REQUEST_ERROR' &&
            saveBannerRequest.type !== 'REQUEST_ERROR' &&
            saveOrgAddressRequest.type !== 'REQUEST_ERROR' &&
            saveFaithLeaderRequest.type !== 'REQUEST_ERROR'
        ) {
            if (showSnackBar !== undefined) {
                setShowSnackBar(true);
            }
        }
        //eslint-disable-next-line
    }, [
        saveLogoRequest,
        saveBannerRequest,
        saveOrgAddressRequest,
        saveFaithLeaderRequest,
    ]);

    useEffect(() => {
        if (saveFaithLeaderRequest.type === 'REQUEST_SUCCESS') {
            setAppProfileHash(uuidv4());
            if (saveFaithLeaderRequest.data['leadOfficer']) {
                dispatch(
                    setDoneeFaithLeader(
                        {
                            avatar: saveFaithLeaderRequest.data['leadOfficer'][
                                'avatar'
                            ],
                            avatarCroppedCoordinates:
                                saveFaithLeaderRequest.data['leadOfficer'][
                                    'avatarCroppedCoordinates'
                                ],
                            avatarOriginal:
                                saveFaithLeaderRequest.data['leadOfficer'][
                                    'avatarOriginal'
                                ],
                            firstName:
                                saveFaithLeaderRequest.data['leadOfficer'][
                                    'name'
                                ],
                            lastName:
                                saveFaithLeaderRequest.data['leadOfficer'][
                                    'lname'
                                ],
                            title: saveFaithLeaderRequest.data['leadOfficer'][
                                'title'
                            ],
                            userId: saveFaithLeaderRequest.data['leadOfficer'][
                                'officialId'
                            ],
                            isFaithLeader: true,
                            active: saveFaithLeaderRequest.data['leadOfficer'][
                                'active'
                            ],
                            email: saveFaithLeaderRequest.data['leadOfficer'][
                                'email'
                            ],
                            invitationEmailSent:
                                saveFaithLeaderRequest.data['leadOfficer'][
                                    'invitationEmailSent'
                                ],
                        },
                        new Date(),
                    ),
                );
            } else {
                dispatch(removeDoneeFaithLeader(new Date()));
            }
        }
        //eslint-disable-next-line
    }, [saveFaithLeaderRequest]);

    useEffect(() => {
        if (saveLogoRequest.type === 'REQUEST_SUCCESS') {
            dispatch(
                setDoneeOrganizationLogo({
                    ...orgLogoToSave,
                    url: saveLogoRequest.data['onboarding']['appProfile'][
                        'organizationLogo'
                    ]['logo'],
                }),
            );
        }
        //eslint-disable-next-line
    }, [saveLogoRequest]);

    useEffect(() => {
        if (saveBannerRequest.type === 'REQUEST_SUCCESS') {
            dispatch(
                setDoneeBannerImage({
                    ...bannerImageToSave,
                    url: saveBannerRequest.data['photo'],
                }),
            );
        }
        //eslint-disable-next-line
    }, [saveBannerRequest]);

    useEffect(() => {
        if (saveOrgAddressRequest.type === 'REQUEST_SUCCESS') {
            const { timezone_long, timezone_short, timezone } =
                saveOrgAddressRequest.data as {
                    timezone_long: string;
                    timezone_short: string;
                    timezone: string;
                };
            dispatch(
                setDoneeAddress(
                    doneeId,
                    addressToSave.street,
                    addressToSave.city,
                    addressToSave.state,
                    addressToSave.zip,
                    addressToSave.phone,
                    new Date(),
                    timezone_long,
                    timezone_short,
                    timezone,
                ),
            );
            addressEditorRef.current.close();
        }
        //eslint-disable-next-line
    }, [saveOrgAddressRequest]);

    const error =
        saveBannerRequest.type === 'REQUEST_ERROR'
            ? saveBannerRequest.error.message || errorMessage
            : saveLogoRequest.type === 'REQUEST_ERROR'
            ? saveLogoRequest.error.message || errorMessage
            : saveOrgAddressRequest.type === 'REQUEST_ERROR'
            ? saveOrgAddressRequest.error.message || errorMessage
            : saveFaithLeaderRequest.type === 'REQUEST_ERROR'
            ? saveFaithLeaderRequest.error.message || errorMessage
            : '';

    return (
        <GivelifyBox marginTop={40} smallScreenStyle={{ marginBottom: 45 }}>
            <CustomizeProfileStepOne
                ref={editorRef}
                hideFullScreenPreviewOption
                appProfile={appProfile}
                appProfileHash={appProfileHash}
                doneeId={doneeId}
                formProps={{
                    bunnerImageFormSubmitProps: {
                        onSave: onBannerImageSaveClick,
                        isSaving: saveBannerRequest.type === 'REQUEST_START',
                    },
                    addressFormSubmitProps: {
                        onSave: onOrgAddressSaveClick,
                        isSaving:
                            saveOrgAddressRequest.type === 'REQUEST_START',
                        formRef: addressEditorRef,
                    },
                    faithLeadeFormSubmitProps: {
                        onSave: onFaithLeaderSaveClick,
                        isSaving:
                            saveFaithLeaderRequest.type === 'REQUEST_START',
                    },
                }}
                organizationName={organizationName}
                organizationType={organizationType}
                settingsUsersPath={PATH.SETTINGS.USERS()}
                userId={userId}
            />
            <GivelifyBox maxWidth={533}>
                <OrganizationLogoSection
                    organizationAddress={organizationAddress}
                    organizationLogo={organizationLogo}
                    organizationName={organizationName}
                    organizationType={organizationType}
                    setOrganizationLogo={setOrganizationLogo}
                    submitProps={{
                        onSave: onOrgLogoSaveClick,
                        isSaving: saveLogoRequest.type === 'REQUEST_START',
                    }}
                />
            </GivelifyBox>
            {error ? <ErrorText text={error} /> : null}
            <GivelifySnackbar
                message={changesSaved}
                onClose={() => setShowSnackBar(false)}
                open={showSnackBar}
            />
        </GivelifyBox>
    );
};
