import { GivelifyModal } from '@givelify/ui';
import { styled } from '@mui/material';

export const StyledModal = styled(GivelifyModal)(() => ({
    '& .MuiPaper-root': {
        maxWidth: '680px',
    },
}));

export const WelcomeWrapper = styled('div')(({theme}) => ({
    textAlign: 'center',

    '& p': {
        margin: '0',
    },

    '& .title': {
        [theme.breakpoints.up('mobile')]: {
            marginTop: '32px',
        }
    },

    '& .description': {
        marginTop: '16px',
        marginBottom: '16px',

        '& p': {
            display: 'inline',
        },
    },

    '& .action-button-wrapper': {
        marginTop: '32px',
        [theme.breakpoints.down('mobile')]: {
            marginTop: '24px',
            gap: '8px',
            flexDirection: 'column-reverse',
        }
    },

    '& .action-button': {
        width: '264px',
        height: '48px',

        [theme.breakpoints.down('mobile')]: {
            height: '40px',
            fontSize: '16px',
        }
    },
}));
