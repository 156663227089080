import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { FacebookLoading, GivelifyLabel } from '@givelify/givelify-ui';
import { Theme } from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';

interface TableLoading {
    columnName: string;
    columnId: string;
    width: number;
    align: 'left' | 'right';
    paddingRight?: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerStyles: {
            overflowX: 'auto',
            '& .header-row': {
                borderBottom: '1px solid #E3E3E3',
                '& .column': {
                    display: 'inline-block',
                    padding: theme.spacing(3),
                    fontWeight: 'bold',
                    textAlign: 'center',
                },
            },
        },
        loadingRowStyles: {
            display: 'flex',
            alignItems: 'center',
            minHeight: 64,
            borderBottom: '1px solid #E3E3E3',
            '& .inline': {
                display: 'inline-block',
            },
            '& .flex, & .flex-center': {
                display: 'flex',
                alignItems: 'center',
                padding: theme.spacing(3),
                '&.align-left': {
                    justifyContent: 'flex-start',
                },
                '&.align-right': {
                    justifyContent: 'flex-end',
                },
            },
            '& .flex-center': {
                justifyContent: 'center',
            },
        },
        columnName: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    }),
);

const donorName = 'donorName';
const donorTitle = 'Donor Name';
const givelifyEnvelope = 'givelifyEnvelope';
const givelifyEnvelopeTitle = 'Givelify Envelope';
const chmsEnvelopeName = 'chmsEnvelopeName';
const chmsEnvelopeTitle = 'CCB COA Category';
const chmsF1GoEnvelopeTitle = 'F1 GO Fund';
const chmsF1EnvelopeTitle = 'FellowshipOne Fund';
const chmsF1SubFund = 'Sub Fund';
const amount = 'amount';
const amountTitle = 'Donation Amount';
const exportDate = 'exportDate';
const exportDateTitle = 'Export Date';
const PCEnvelopeTitle = 'Planning Center Fund';

export const exportTableColumnExtensions: Table.ColumnExtension[] = [
    { columnName: donorTitle, width: 250 },
    { columnName: givelifyEnvelopeTitle, width: 197.5 },
    { columnName: chmsEnvelopeTitle, width: 197.5 },
    { columnName: amountTitle, width: 197.5 },
    { columnName: exportDateTitle, width: 197.5 },
];
export const exportF1TableColumnExtensions: Table.ColumnExtension[] = [
    { columnName: donorTitle, width: 173.3 },
    { columnName: givelifyEnvelopeTitle, width: 173.3 },
    { columnName: chmsF1EnvelopeTitle, width: 173.3 },
    { columnName: chmsF1SubFund, width: 173.3 },
    { columnName: amountTitle, width: 173.3 },
    { columnName: exportDateTitle, width: 173.3 },
];
export const exportTableColumnLoading: TableLoading[] = [
    { columnName: donorTitle, columnId: donorName, width: 250, align: 'left' },
    {
        columnName: givelifyEnvelopeTitle,
        columnId: givelifyEnvelope,
        width: 197.5,
        align: 'left',
    },
    {
        columnName: chmsEnvelopeTitle,
        columnId: chmsEnvelopeName,
        width: 197.5,
        align: 'left',
    },
    { columnName: amountTitle, columnId: amount, width: 197.5, align: 'left' },
    {
        columnName: exportDateTitle,
        columnId: exportDate,
        width: 197.5,
        align: 'left',
    },
];
export const exportF1GoTableColumnLoading: TableLoading[] = [
    { columnName: donorTitle, columnId: donorName, width: 250, align: 'left' },
    {
        columnName: givelifyEnvelopeTitle,
        columnId: givelifyEnvelope,
        width: 197.5,
        align: 'left',
    },
    {
        columnName: chmsF1GoEnvelopeTitle,
        columnId: chmsEnvelopeName,
        width: 197.5,
        align: 'left',
    },
    { columnName: amountTitle, columnId: amount, width: 197.5, align: 'left' },
    {
        columnName: exportDateTitle,
        columnId: exportDate,
        width: 197.5,
        align: 'left',
    },
];
export const exportPCTableColumnLoading: TableLoading[] = [
    { columnName: donorTitle, columnId: donorName, width: 250, align: 'left' },
    {
        columnName: givelifyEnvelopeTitle,
        columnId: givelifyEnvelope,
        width: 197.5,
        align: 'left',
    },
    {
        columnName: PCEnvelopeTitle,
        columnId: chmsEnvelopeName,
        width: 197.5,
        align: 'left',
    },
    { columnName: amountTitle, columnId: amount, width: 197.5, align: 'left' },
    {
        columnName: exportDateTitle,
        columnId: exportDate,
        width: 197.5,
        align: 'left',
    },
];
export const exportF1TableColumnLoading: TableLoading[] = [
    {
        columnName: donorTitle,
        columnId: donorName,
        width: 173.3,
        align: 'left',
    },
    {
        columnName: givelifyEnvelopeTitle,
        columnId: givelifyEnvelope,
        width: 173.3,
        align: 'left',
    },
    {
        columnName: chmsF1EnvelopeTitle,
        columnId: chmsEnvelopeName,
        width: 173.3,
        align: 'left',
    },
    {
        columnName: chmsF1SubFund,
        columnId: chmsEnvelopeName,
        width: 173.3,
        align: 'left',
    },
    { columnName: amountTitle, columnId: amount, width: 173.3, align: 'left' },
    {
        columnName: exportDateTitle,
        columnId: exportDate,
        width: 173.3,
        align: 'left',
    },
];

interface ExportTableLoadingProps {
    columns: TableLoading[];
    noOfRows: number;
}

const LoadingRow: React.FC<{ columns: ExportTableLoadingProps['columns'] }> = ({
    columns,
}) => {
    const { loadingRowStyles } = useStyles();
    return (
        <div className={loadingRowStyles}>
            {columns.map((c, i) => {
                return (
                    <div
                        key={i}
                        className={`flex-center align-${c.align}`}
                        style={{
                            width: c.width,
                            paddingRight: c.paddingRight || 0,
                        }}
                    >
                        <FacebookLoading
                            borderRadius={4}
                            className="inline"
                            height={18}
                            width={80}
                        />
                    </div>
                );
            })}
        </div>
    );
};

const getLoadingRows = (
    rows: number,
    columns: ExportTableLoadingProps['columns'],
): JSX.Element[] => {
    const result: JSX.Element[] = [];
    for (let i = 0; i < rows; i++) {
        result.push(<LoadingRow key={i} columns={columns} />);
    }
    return result;
};

const ExportTableLoading: React.FC<ExportTableLoadingProps> = ({
    columns,
    noOfRows,
}) => {
    const { headerStyles, columnName } = useStyles();
    return (
        <>
            <div className={headerStyles}>
                <div className="header-row">
                    {columns.map((c, i) => (
                        <div
                            key={i}
                            className="column"
                            style={{
                                width: c.width,
                                textAlign: c.align,
                                paddingRight: c.paddingRight || 0,
                            }}
                        >
                            <GivelifyLabel
                                className={columnName}
                                text={c.columnName}
                                variant="body3"
                            />
                        </div>
                    ))}
                </div>
            </div>
            {getLoadingRows(noOfRows, columns)}
        </>
    );
};

export default ExportTableLoading;
