import React from 'react';
import { DesignTokens } from '@givelify/ui';
import { DonationsStatisticType } from 'api/client/endpoints';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { TrendData } from '../../types';

dayjs.extend(utc);

export type ChartXAxisTickProps = {
    x: number;
    y: number;
    index: number;
    payload: {
        value: string;
        index: number;
    };
    data: TrendData;
    rangeDateType: DonationsStatisticType;
};

const ChartXAxisTickComponent: React.FC<ChartXAxisTickProps> = (props) => {
    const { x, y, payload, data, rangeDateType } = props;

    const { index } = payload;

    const isHoursView = rangeDateType === 'hourly';
    const isDaysView = rangeDateType === 'daily';
    const isWeeksView = rangeDateType === 'weekly';
    const isMonthView = rangeDateType === 'monthly';
    const isYearView = rangeDateType === 'yearly';

    const isShowMidMonth =
        data[0].startDate.month() !== data[data.length - 1].startDate.month() &&
        data[index].startDate.date() === 1;

    const isShowPrevMonth =
        data[0].startDate.month() !== data[data.length - 1].startDate.month() &&
        index === 0;

    const isSunday = data[index].startDate.day() === 0;

    const isLowerMidMonth = data[0].startDate.day() === 0;

    const isShowYear =
        data[0].startDate.year() !== data[data.length - 1].startDate.year() &&
        ((isMonthView && data[index].startDate.month() === 0) ||
            (isDaysView && data[index].startDate.date() === 1) ||
            (isWeeksView &&
                data[index + 1] &&
                data[index].startDate.year() !==
                    data[index + 1].startDate.year()));

    const isDifferentYears =
        data[0].startDate.year() !== data[data.length - 1].startDate.year();

    const isCurrentDateActive =
        (!isHoursView && data[index].startDate.isSame(dayjs().tz(), 'day')) ||
        (isWeeksView && data[index].startDate.isSame(dayjs().tz(), 'week')) ||
        (isMonthView &&
            data[index].startDate.isSame(dayjs().tz(), 'month') &&
            data[index].startDate.isSame(dayjs().tz(), 'years')) ||
        (isYearView && data[index].startDate.isSame(dayjs().tz(), 'year'));
    return (
        <text
            data-testid={`trend-x-tick-${index}`}
            dy={4}
            fill={
                data[index].startDate.isAfter(dayjs().utc())
                    ? DesignTokens.color.textDisabled
                    : DesignTokens.color.textPrimary
            }
            fontSize="10px"
            textAnchor="middle"
            x={x}
            y={y}
        >
            {isWeeksView && typeof payload.value === 'string' ? (
                [
                    payload.value.split(' - ')[0],
                    ' - ',
                    payload.value.split(' - ')[1],
                ].map((v, i) => (
                    <tspan
                        key={i}
                        data-testid={`trend-x-tick-value-${index}`}
                        dy={7 + i * 9}
                        fontWeight={
                            data[index].startDate.isSame(
                                dayjs().tz(),
                                'week',
                            ) && v !== ' - '
                                ? 'bold'
                                : null
                        }
                        textDecoration={
                            data[index].startDate.isSame(
                                dayjs().tz(),
                                'week',
                            ) && v !== ' - '
                                ? 'underline'
                                : null
                        }
                        x={x}
                        y={y}
                    >
                        {v}
                    </tspan>
                ))
            ) : (
                <tspan
                    data-testid={`trend-x-tick-value-${index}`}
                    dy={10}
                    fontWeight={isCurrentDateActive ? 'bold' : null}
                    textDecoration={isCurrentDateActive ? 'underline' : null}
                    x={x}
                    y={y}
                >
                    {payload.value}
                </tspan>
            )}
            {isSunday && isDaysView ? (
                <tspan
                    data-testid={`trend-x-tick-sun-${index}`}
                    dy={25}
                    fill={
                        data[index].startDate.isAfter(dayjs().utc())
                            ? DesignTokens.color.textDisabled
                            : DesignTokens.color.textSecondary
                    }
                    fontSize="10px"
                    x={x}
                    y={y}
                >
                    Sun
                </tspan>
            ) : null}

            {isShowMidMonth &&
            !isDifferentYears &&
            !isWeeksView &&
            !isMonthView ? (
                <tspan
                    data-testid="trend-x-tick-mid-month"
                    dy={isSunday ? 41 : isLowerMidMonth ? 41 : 25}
                    fill={DesignTokens.color.textPrimary}
                    fontSize="10px"
                    fontWeight={700}
                    x={x}
                    y={y}
                >
                    {data[index].startDate.format('MMM')}
                </tspan>
            ) : null}

            {isShowPrevMonth &&
            !isDifferentYears &&
            !isWeeksView &&
            !isMonthView ? (
                <tspan
                    data-testid="trend-x-tick-prev-month"
                    dy={isSunday ? 41 : 25}
                    fill={DesignTokens.color.textPrimary}
                    fontSize="10px"
                    fontWeight={700}
                    x={x}
                    y={y}
                >
                    {data[index].startDate.format('MMM')}
                </tspan>
            ) : null}

            {isShowYear && !isYearView ? (
                <tspan
                    data-testid="trend-x-tick-year"
                    dy={isMonthView ? 25 : 41}
                    fill={DesignTokens.color.textPrimary}
                    fontSize="10px"
                    fontWeight={700}
                    x={x}
                    y={y}
                >
                    {data[isWeeksView ? index + 1 : index].startDate.format(
                        'YYYY',
                    )}
                </tspan>
            ) : null}
        </text>
    );
};

export const ChartXAxisTick = React.memo(ChartXAxisTickComponent);
