import React from 'react';
import { GivelifyButton, GivelifyIcon } from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import { Footer, FooterSubmit, BackButton } from './styles';

type PrimaryRepEditorFooterProps = {
    showBackButton?: boolean;
    canSubmit?: boolean;
    onBackClick?: () => void;
    onCancelClick: () => void;
    onSubmitClick?: () => void;
};

export const PrimaryRepEditorFooter: React.FC<PrimaryRepEditorFooterProps> = ({
    showBackButton,
    canSubmit,
    onBackClick,
    onCancelClick,
    onSubmitClick,
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            submit: t('labels.submit'),
            continue: t('labels.continue'),
            cancel: t('labels.cancel'),
            back: t('labels.back'),
        }),
        [t],
    );
    return (
        <Footer>
            {showBackButton ? (
                <BackButton
                    name="back"
                    onClick={onBackClick}
                    size="large"
                    startIcon={<GivelifyIcon variant="left-arrow" />}
                    text={copy.back}
                    variant="ghost"
                />
            ) : null}
            <FooterSubmit>
                <GivelifyButton
                    name="cancel"
                    onClick={onCancelClick}
                    size="large"
                    text={copy.cancel}
                    variant="secondary"
                />
                <GivelifyButton
                    disabled={!canSubmit}
                    name="submit"
                    onClick={onSubmitClick}
                    size="large"
                    text={onSubmitClick ? copy.submit : copy.continue}
                    type={onSubmitClick ? 'button' : 'submit'}
                    variant="primary"
                />
            </FooterSubmit>
        </Footer>
    );
};
