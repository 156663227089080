import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import {
    getGenerateReportsApiEndpoint,
    getReportDetailsApiEndpoint,
} from 'api/client/endpoints';
import { IReportsService } from '../interfaces';
import { GenerateDonorLifetimeReportRequest } from '../requests';
import {
    GenerateReportResponse,
    GetReportDetailsResponse,
} from '../responses/reports';

export class ReportsService implements IReportsService {
    public async getReportDetailsById(
        id: number,
    ): Promise<ApiResponse<GetReportDetailsResponse>> {
        const url = getReportDetailsApiEndpoint(id);
        const httpRequest = getAxiosClient().get(url);
        return makeApiRequest(httpRequest);
    }

    public async generateDonorLifetimeReport(
        payload: GenerateDonorLifetimeReportRequest,
    ): Promise<ApiResponse<GenerateReportResponse>> {
        const url = getGenerateReportsApiEndpoint();
        const httpRequest = getAxiosClient(undefined, {
            ignoreCaseTransform: true,
        }).post(url, {
            ...payload,
            reportType: 'OLD_DASHBOARD_DONATIONS',
            fileType: 'csv',
            timeFramePickerText: 'Lifetime',
            excludeEmptyEnvelopes: true,
        });
        const result = await makeApiRequest<GenerateReportResponse>(
            httpRequest,
        );
        return result;
    }
}
