import { GivelifyColorPalette } from '@givelify/givelify-ui';
import { DesignTokens, GivelifyModalDefaultPaper } from '@givelify/ui';
import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { styled } from '@mui/material';

export const autoIntegrationModalPaper = styled(GivelifyModalDefaultPaper)(
    ({ theme }) => ({
        minHeight: 700,
        maxHeight: '100vh',
        overflow: 'auto',
        backgroundColor: DesignTokens.color.backgroundSecondary,
        padding: theme.spacing(3),
        [theme.breakpoints.down('mobile')]: {
            height: '100vh',
            padding: theme.spacing(2),
        },
    }),
);

export const autoIntegrationModalStyle = makeStyles((theme: Theme) => {
    return createStyles({
        f1ModalContent: {
            overflow: 'auto',
            maxWidth: 880,
            '& .header': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: theme.spacing(2),
                marginBottom: theme.spacing(4),
            },
        },
        matchInfo: {
            whiteSpace: 'normal',
            margin: theme.spacing(3, 0),
            paddingRight: '15%',
        },
    });
});

export const unMatchDonorTabStyle = makeStyles((theme: Theme) => {
    return createStyles({
        potentialContent: {
            marginTop: theme.spacing(4),
        },
        donorInfo: {
            padding: theme.spacing(4),
            borderRadius: 10,
            backgroundColor: GivelifyColorPalette.neutralHoverGrey,
            border: '1px solid ' + GivelifyColorPalette.neutralPlatinum,
            marginBottom: theme.spacing(4),
        },
        rowItem: {
            paddingRight: theme.spacing(3),
        },
        textOverflow: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
        listF1Donors: {
            marginTop: theme.spacing(4),
            borderTop: '1px solid ' + GivelifyColorPalette.neutralPlatinum,
            maxHeight: 280,
            overflow: 'auto',
        },
        f1DonorInfo: {
            padding: theme.spacing(2, 4),
            borderBottom: '1px solid ' + GivelifyColorPalette.neutralPlatinum,
        },
        italicText: {
            fontStyle: 'italic',
            whiteSpace: 'unset',
        },
        donorAddress: {
            whiteSpace: 'unset',
        },
        enterF1IdContent: {
            marginTop: theme.spacing(4),
        },
        errorNotification: {
            marginBottom: theme.spacing(3),
        },
        enterF1IdForm: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
        },
        enterIdField: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: theme.spacing(4),
        },
        createProfileContent: {
            marginTop: theme.spacing(4),
        },
        createProfileForm: {
            marginTop: theme.spacing(4),
        },
        createProfileRow: {
            display: 'flex',
            alignItems: 'flex-start',
        },
        selectTypeCol: {
            width: '40%',
        },
        infoCol: {
            width: '60%',
        },
        fullWidth: {
            width: '100%',
        },
        selectType: {
            paddingRight: theme.spacing(5),
            marginBottom: theme.spacing(3),
            '& > div': {
                width: '100%',
                height: 48,
            },
            [theme.breakpoints.down('sm')]: {
                paddingRight: 0,
            },
        },
        createInput: {
            marginBottom: theme.spacing(3),
        },
        submitProfile: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: theme.spacing(4),
        },
        strongMatch: {
            color: GivelifyColorPalette.success,
        },
        partialMatch: {
            color: GivelifyColorPalette.warning,
        },
        weakMatch: {
            color: GivelifyColorPalette.error,
        },
        flexCenter: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: theme.spacing(3),
        },
    });
});

export const useDonationTableClasses = makeStyles((theme: Theme) => ({
    table: {
        '& > thead': {
            '& > tr > td': {
                fontWeight: 900,
            },
            '& > tr > th': {
                paddingLeft: theme.spacing(2),
            },
        },
        '.MuiTableCell-root, & > th': {
            borderRight: 'none',
        },
        '& > tbody': {
            '& > tr ': {
                '& > td': {
                    padding: theme.spacing(2, 3),
                    position: 'relative',
                    whiteSpace: 'nowrap',
                },
                '& > td:first-child': {
                    position: 'sticky',
                    whiteSpace: 'nowrap',
                },
                '& > td:nth-last-child(2)': {
                    '&::after': {
                        borderRight: 'none',
                    },
                },
                '& > td:last-child': {
                    '&::after': {
                        borderRight: 'none',
                    },
                },
            },
        },
        [theme.breakpoints.down('sm')]: {
            '& > tbody': {
                '& > tr ': {
                    '& > td': {
                        padding: theme.spacing(2, 4),
                        position: 'relative',
                        whiteSpace: 'nowrap',
                        borderRight:
                            '1px solid ' + GivelifyColorPalette.neutralPlatinum,
                    },
                    '& > td:first-child': {
                        padding: theme.spacing(2, 4),
                        position: 'sticky',
                        whiteSpace: 'nowrap',
                    },
                },
            },
        },
    },
}));

export const useExportStyles = makeStyles((theme: Theme) => ({
    imageWrapper: {
        borderRadius: '100px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        minWidth: '40px',
        marginRight: theme.spacing(2),
    },
    donationTable: {
        '& th': {
            fontWeight: 'bold',
            '& div': {
                justifyContent: 'center',
            },
        },
        '& td': {
            borderLeft: '1px solid ' + GivelifyColorPalette.neutralPlatinum,
            '&:last-child': {
                borderLeft: 0,
            },
        },
    },
    internalId: {
        '& div': {
            justifyContent: 'center',
        },
    },
    centerItem: {
        textAlign: 'center',
    },
    image: {
        width: '40px',
        height: '40px',
    },
    donationName: {
        whiteSpace: 'pre-wrap',
        justifyContent: 'flex-start',
        textAlign: 'left',
    },
}));

export const useConfirmModalStyles = makeStyles((theme: Theme) => ({
    confirmModalContent: {
        textAlign: 'center',
        padding: theme.spacing(0, 7),
    },
    modalTitle: {
        marginBottom: theme.spacing(4),
    },
    confirmModalGroupButton: {
        marginTop: theme.spacing(8),
        display: 'flex',
        justifyContent: 'center',
        '& button': {
            margin: theme.spacing(0, 3),
        },
    },
}));
