import { useEffect, useMemo } from 'react';
import {
    FacebookGiving,
    FundraisingToolsRoot,
    GivelifyButtonPage,
    GivelifyButtonPublicPage,
    InstagramGiving,
    OnlineGiving,
    SnapGive,
    SocialMediaGiving,
    TwitterGiving,
    YouTubeGiving,
} from '@givelify/fundraising-tools';
import { useCaptivePortalContext } from '@givelify/onboarding';
import { GivelifyTheme } from '@givelify/ui';
import {
    getAccessTokenLocalStorage,
    registerUnauthorizedInterceptor,
    TimeFrameValues,
    TimezoneManager,
    TrackingConfigProvider,
    TrackingProvider,
} from '@givelify/utils';
import { getTimeframeFilterFromUrlParams } from 'api/hooks';
import {
    getDonationService,
    getDonorsService,
    getEnvelopesService,
} from 'api/utils/serviceProvider';
import { BankDeposits } from 'pages/donations/BankDeposits';
import { DonationActivity } from 'pages/donations/DonationActivity';
import { DonationsPage } from 'pages/donations/donationsPage';
import { DonationsRoot } from 'pages/donations/DonationsRoot';
import { DonationSummary } from 'pages/donations/donationSummary';
import { DonationActivity as NewDonationActivity } from 'pages/donations/newDonationActivity';
import { Refunds } from 'pages/donations/Refunds';
import DonorProfilePage from 'pages/donors/DonorProfile';
import { DonorsMain } from 'pages/donors/DonorsMain';
import EditProfile from 'pages/editProfile/EditProfile';
import Givelithon from 'pages/givelithon/Givelithon';
import LaunchGivelithon from 'pages/givelithon/LaunchGivelithon';
import IntegrationContent from 'pages/integrations/IntegrationContent';
import Integrations from 'pages/integrations/IntegrationSetup';
import { AdminLogin } from 'pages/login/AdminLogin';
import Login from 'pages/login/Login';
import { DonorProfile as NewDonorProfile } from 'pages/newDonorProfile';
import NotFoundPage from 'pages/NotFound';
import { OverviewLoadedData } from 'pages/overview/types';
import ResetPasswordWrapper from 'pages/resetPassword/ResetPassword';
import { useSelector } from 'react-redux';
import {
    defer,
    LoaderFunction,
    LoaderFunctionArgs,
    Navigate,
    Outlet,
    redirect,
    Route,
    useLocation,
    useSearchParams,
} from 'react-router-dom';

import GetStore, { AppState, getDispatch } from 'store';
import { loadData } from 'store/system/thunks';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import {
    GTM_TAG_VALUE,
    getGoogleTagManagerTag,
} from 'utils/getGoogleTagManagerTag';
import { getRangeDateType } from 'utils/timeFrameUtils';
import { getEventTrackingData } from 'utils/tracking';
import FundraisingToolsRoutes from './FundraisingToolsRoutes';
import { GasRouterProvider, getDoneeIdFromQuery } from './GasRouterProvider';
import GivelithonRoutes from './GivelithonRoutes';
import IntegrationsRoutes from './IntegrationsRoutes';
import OverviewRoutes from './OverviewRoutes';
import ReportsRoute, { REPORTS_ROUTES } from './ReportRoutes';
import { PATH } from './routes';
import SecuredRoutes, {
    getUnauthorizedRedirectUrl,
    removeInvalidToken,
} from './SecuredRoutes';
import SettingsRoutes, { SETTINGS_ROUTES } from './SettingsRoutes';

declare global {
    interface Window {
        Appcues: {
            identify: (id: number, data: any) => void;
            page: () => void;
        };
        FS?: {
            //eslint-disable-next-line
            identify: (id: string, userVars: object) => unknown;
            anonymize: () => unknown; // un-identify the user
            //eslint-disable-next-line
            setUserVars: (userVars: object) => unknown; // same as the second param in identify
            //eslint-disable-next-line
            event: (eventName: string, props: object) => unknown;
        };
    }
}

const RoutesWrapper = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        window.Appcues?.page();
    }, [location.pathname]);

    const { doneeId, user, donee } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee?.id,
        user: state.User.user,
        donee: state.Donee.donee,
        mainDoneeId: state.Donee.campuses ? state.Donee.campuses[0].id : null,
    }));

    const tag = useMemo(
        () =>
            donee
                ? getGoogleTagManagerTag(
                      donee.onboarding?.hasMid,
                      donee.lastDonationDate,
                  )
                : null,
        [donee],
    );

    useEffect(() => {
        // when doneeId is missing it means donee data is not loaded yet
        if (!doneeId) return;

        const targetParams = new URLSearchParams(searchParams);

        targetParams.delete('Onboarding');
        targetParams.delete('MIDApproved');

        if (tag) {
            targetParams.set(tag, GTM_TAG_VALUE);
        }

        if (targetParams.toString() !== searchParams.toString()) {
            setSearchParams(targetParams, { replace: true });
        }
        // listen only to tag and doneeId change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tag, doneeId]);

    return (
        <GasRouterProvider>
            <TrackingConfigProvider
                getPayload={() => getEventTrackingData(user, donee)}
            >
                <Outlet />
            </TrackingConfigProvider>
        </GasRouterProvider>
    );
};

const useEnabledFeature = (feature: Features) =>
    useSelector((state: AppState) => ({
        isEnabled: isFeatureEnabled(
            state.System.enabledFeatures,
            feature,
            false,
        ),
    }));

const CaptivePortalGuard = () => {
    const { showCaptivePortal } = useCaptivePortalContext();
    return showCaptivePortal ? (
        <Navigate replace to={PATH.OVERVIEW} />
    ) : (
        <Outlet />
    );
};

const DonationsActivityElement = () => {
    const { isEnabled } = useEnabledFeature(Features.NEW_DONATION_PAGE);
    return isEnabled ? <NewDonationActivity /> : <DonationActivity />;
};

const DonorProfileElement = () => {
    const { isEnabled } = useEnabledFeature(Features.NEW_DONOR_DETAILS_PAGE);
    return isEnabled ? <NewDonorProfile /> : <DonorProfilePage />;
};

export const DonorProfileRoutes = (
    <Route element={<DonorProfileElement />} path={PATH.DONORS.PROFILE} />
);

export const authenticatedLoader = <T extends LoaderFunction>(
    load: T,
): LoaderFunction => {
    return (args: LoaderFunctionArgs) => {
        const token = getAccessTokenLocalStorage();

        if (!token) {
            const { request } = args;
            const { pathname, searchParams } = new URL(request.url);
            const redirectTo = getUnauthorizedRedirectUrl(
                pathname,
                `?${searchParams.toString()}`,
            );
            return redirect(redirectTo);
        }
        return load(args);
    };
};

export const OverviewLoader: LoaderFunction = authenticatedLoader(
    ({ request }) => {
        const { searchParams } = new URL(request.url);

        const timeFrame = getTimeframeFilterFromUrlParams(searchParams);

        const clientWidth = document.documentElement?.clientWidth;
        const isMobile = clientWidth <= GivelifyTheme.breakpoints.values.mobile;

        const rangeDateType = getRangeDateType(timeFrame, isMobile);

        const doneeIdFromQuery = getDoneeIdFromQuery(searchParams);
        const userDoneeId = GetStore().getState().User.user.doneeId;

        // If we load campus data - use doneeId from query, otherwise from user
        const doneeId = doneeIdFromQuery || userDoneeId;

        const envelopesData = getEnvelopesService().getEnvelopesDataByTime(
            timeFrame,
            doneeId,
        );

        const donationsStatistics = getDonationService().getDonationsStatistic(
            doneeId,
            timeFrame.start,
            timeFrame.end,
            rangeDateType,
        );

        let _rangeDateType = rangeDateType;
        if (
            timeFrame.selector === 'today' ||
            timeFrame.selector === 'yesterday'
        ) {
            _rangeDateType = 'hourly';
        } else if (
            timeFrame.selector === 'custom' &&
            rangeDateType === 'weekly'
        ) {
            _rangeDateType = 'monthly';
        }

        const donationsStatisticsByEnvelopes =
            getEnvelopesService().getDonationsStatisticByEnvelopes(
                doneeId,
                timeFrame.start,
                timeFrame.end,
                _rangeDateType,
            );

        const lifetimeTimeFrame = TimeFrameValues['lifetime'];
        const donorsData = getDonorsService().getDonorsData(
            lifetimeTimeFrame,
            doneeId,
        );

        return {
            envelopesData,
            donationsStatistics,
            donationsStatisticsByEnvelopes,
            donorsData,
        } satisfies OverviewLoadedData;
    },
);

export const Routes = (
    <Route element={<RoutesWrapper />}>
        <Route
            element={<GivelifyButtonPublicPage />}
            path={PATH.GIVELIFY_BUTTON}
        />
        <Route element={<ResetPasswordWrapper />} path={PATH.DONEE.RESET} />
        <Route element={<AdminLogin />} path={PATH.ADMIN.LOGIN} />
        <Route element={<Login />} path={PATH.DONEE.LOGIN} />
        <Route element={<GivelithonRoutes />}>
            <Route
                element={<LaunchGivelithon />}
                path={PATH.FUNDRAISING_TOOLS.LAUNCH_GIVELITHON}
            />
        </Route>
        <Route
            element={<SecuredRoutes />}
            loader={authenticatedLoader(({ request }) => {
                registerUnauthorizedInterceptor(() => {
                    removeInvalidToken();
                });

                const user = GetStore().getState().User.user;
                const timezone = user?.givingPartnerTimezone;
                if (timezone !== null) {
                    TimezoneManager.setDefault(timezone);
                }

                const { searchParams } = new URL(request.url);

                const doneeId = getDoneeIdFromQuery(searchParams);
                const dispatch = getDispatch();
                const result = dispatch(loadData(doneeId));
                return defer({ result });
            })}
            shouldRevalidate={() => false}
        >
            <Route
                element={<OverviewRoutes />}
                loader={OverviewLoader}
                path={PATH.OVERVIEW}
            />
            <Route element={<CaptivePortalGuard />}>
                <Route
                    element={<BankDeposits />}
                    path={PATH.DONATIONS.BANK_DEPOSITS}
                />
                <Route element={<Refunds />} path={PATH.DONATIONS.REFUNDS} />
                <Route element={<DonationsRoot />} path={PATH.DONATIONS.ROOT} />
                <Route element={<DonationsPage />}>
                    <Route
                        element={<DonationsActivityElement />}
                        path={PATH.DONATIONS.DONATIONS_ACTIVITY}
                    />
                    <Route
                        element={<DonationSummary />}
                        path={PATH.DONATIONS.DONATION_SUMMARY.BY_ENVELOPES}
                    />
                    <Route
                        element={<DonationSummary />}
                        path={PATH.DONATIONS.DONATION_SUMMARY.BY_DATE}
                    />
                </Route>
                <Route element={<DonorsMain />} path={PATH.DONORS.ROOT} />
                {DonorProfileRoutes}
                <Route element={<ReportsRoute />}>{REPORTS_ROUTES}</Route>
                <Route element={<SettingsRoutes />}>{SETTINGS_ROUTES}</Route>
                <Route element={<EditProfile />} path={PATH.EDIT_PROFILE} />
                <Route element={<IntegrationsRoutes />}>
                    <Route
                        element={<IntegrationContent />}
                        path={PATH.DATA.INTEGRATION_CONTENT}
                    />
                    <Route
                        element={<Integrations />}
                        path={PATH.DATA.INTEGRATIONS_SETUP}
                    />
                </Route>
                <Route
                    element={<Givelithon />}
                    path={PATH.FUNDRAISING_TOOLS.GIVELITHON}
                />
                <Route element={<FundraisingToolsRoutes />}>
                    <Route
                        element={<FundraisingToolsRoot />}
                        path={PATH.FUNDRAISING_TOOLS.ROOT}
                    />
                    <Route
                        element={<SnapGive />}
                        path={PATH.FUNDRAISING_TOOLS.SNAP_GIVE}
                    />
                    <Route
                        element={<OnlineGiving />}
                        path={PATH.FUNDRAISING_TOOLS.ONLINE_GIVING}
                    />
                    <Route
                        element={<GivelifyButtonPage />}
                        path={PATH.FUNDRAISING_TOOLS.GIVELIFY_BUTTON}
                    />
                    <Route
                        element={<SocialMediaGiving />}
                        path={PATH.FUNDRAISING_TOOLS.SOCIAL_MEDIA_GIVING}
                    />
                    <Route
                        element={
                            <TrackingProvider
                                trackPageVisit
                                pageName="Social Media Giving"
                            >
                                <Outlet />
                            </TrackingProvider>
                        }
                    >
                        <Route
                            element={<FacebookGiving />}
                            path={
                                PATH.FUNDRAISING_TOOLS
                                    .SOCIAL_MEDIA_GIVING_FACEBOOK
                            }
                        />
                        <Route
                            element={<TwitterGiving />}
                            path={
                                PATH.FUNDRAISING_TOOLS
                                    .SOCIAL_MEDIA_GIVING_TWITTER
                            }
                        />
                        <Route
                            element={<InstagramGiving />}
                            path={
                                PATH.FUNDRAISING_TOOLS
                                    .SOCIAL_MEDIA_GIVING_INSTAGRAM
                            }
                        />
                        <Route
                            element={<YouTubeGiving />}
                            path={
                                PATH.FUNDRAISING_TOOLS
                                    .SOCIAL_MEDIA_GIVING_YOUTUBE
                            }
                        />
                    </Route>
                </Route>
            </Route>
            <Route element={<NotFoundPage />} path="*" />
        </Route>
    </Route>
);

export default Routes;
