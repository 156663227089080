export const DesignTokens = {
    font: {
        areaNormalBold: 'AreaNormal-Bold',
        areaNormalExtrabold: 'AreaNormal-Extrabold',
        areaNormalSemibold: 'AreaNormal-Semibold',
        madeMirageBold: 'MADE Mirage-Bold',
    },
    color: {
        avatarBlack: 'rgb(6, 6, 6)',
        avatarBlue: 'rgb(48, 113, 217)',
        avatarBrown: 'rgb(105, 61, 7)',
        avatarBurgundy: 'rgb(154, 72, 86)',
        avatarCamel: 'rgb(178, 98, 0)',
        avatarEucalyptus: 'rgb(73,220,177)',
        avatarGold: 'rgb(127, 114, 5)',
        avatarGreen: 'rgb(35, 103, 70)',
        avatarGrey: 'rgb(89, 97, 110)',
        avatarMagenta: 'rgb(177, 28, 171)',
        avatarNavy: 'rgb(16, 53, 112)',
        avatarPink: 'rgb(214, 36, 110)',
        avatarPurple: 'rgb(117, 5, 127)',
        avatarRed: 'rgb(224, 28, 28)',
        avatarTeal: 'rgb(12, 122, 176)',
        avatarViolet: 'rgb(102, 42, 249)',
        backgroundButtonDestructiveDefault: 'rgb(201, 28, 28)',
        backgroundButtonDestructiveHover: 'rgb(172, 10, 10)',
        backgroundButtonDestructivePressed: 'rgb(141, 6, 6)',
        backgroundButtonDisabled: 'rgb(216, 215, 224)',
        backgroundButtonPrimaryAltDefault: 'rgb(43, 173, 221)',
        backgroundButtonPrimaryDefault: 'rgb(113, 90, 255)',
        backgroundButtonPrimaryHover: 'rgb(83, 54, 226)',
        backgroundButtonPrimaryPressed: 'rgb(69, 41, 207)',
        backgroundButtonWarning: 'rgb(204, 126, 0)',
        backgroundButtonWarningSecondary: 'rgb(255, 255, 255)',
        backgroundButtonSecondaryDefault: 'rgb(255, 255, 255)',
        backgroundButtonSecondaryHover: 'rgb(244, 242, 255)',
        backgroundButtonSticky: 'rgb(40, 40, 48)',
        backgroundComponentDisabled: 'rgb(216, 215, 224)',
        backgroundComponentError: 'rgb(255, 240, 240)',
        backgroundComponentHighlight: 'rgb(244, 242, 255)',
        backgroundComponentInfo: 'rgb(229, 248, 255)',
        backgroundComponentPrimaryAccent: 'rgb(113, 90, 255)',
        backgroundComponentPrimaryDefault: 'rgb(255, 255, 255)',
        backgroundComponentPrimaryHover: 'rgb(243, 242, 250)',
        backgroundComponentQuaternary: 'rgb(216, 215, 224)',
        backgroundComponentSecondary: 'rgb(250, 249, 252)',
        backgroundComponentSecondaryAccent: 'rgb(244, 242, 255)',
        backgroundComponentSuccess: 'rgb(232, 249, 237)',
        backgroundComponentTertiary: 'rgb(243, 242, 250)',
        backgroundComponentTertiaryAccent: 'rgb(198, 189, 255)',
        backgroundComponentToolTip: 'rgb(40, 40, 48)',
        backgroundComponentWarning: 'rgb(254, 250, 236)',
        backgroundPrimary: 'rgb(250, 249, 252)',
        backgroundSecondary: 'rgb(255, 255, 255)',
        borderButtonDefault: 'rgb(113, 90, 255)',
        borderButtonDestructiveDefault: 'rgb(201, 28, 28)',
        borderButtonDestructiveHover: 'rgb(172, 10, 10)',
        borderButtonDestructivePressed: 'rgb(141, 6, 6)',
        borderButtonDisabled: 'rgb(194, 192, 205)',
        borderButtonHover: 'rgb(83, 54, 226)',
        borderButtonPressed: 'rgb(69, 41, 207)',
        borderComponentDefault: 'rgb(158, 157, 169)',
        borderComponentDisabled: 'rgb(194, 192, 205)',
        borderComponentError: 'rgb(234, 55, 55)',
        borderComponentFocused: 'rgb(113, 90, 255)',
        borderComponentInfo: 'rgb(43, 173, 221)',
        borderComponentPrimaryAccent: 'rgb(113, 90, 255)',
        borderComponentSecondaryAccent: 'rgb(244, 242, 255)',
        borderComponentSuccess: 'rgb(52, 166, 82)',
        borderComponentWarning: 'rgb(255, 194, 52)',
        borderComponentWhite: 'rgb(255, 255, 255)',
        chartBlue200: 'rgb(167, 226, 249)',
        chartBlue400: 'rgb(106, 197, 231)',
        chartGrey200: 'rgb(208, 207, 217)',
        chartGrey400: 'rgb(164, 163, 171)',
        chartGrey700: 'rgb(84, 82, 102)',
        chartMint200LegacyGreen100: 'rgb(182, 241, 224)',
        chartMint400LegacyGreen200: 'rgb(73, 220, 177)',
        chartViolet200: 'rgb(198, 189, 255)',
        chartViolet400: 'rgb(142, 123, 255)',
        chartViolet700: 'rgb(95, 72, 240)',
        dividerPrimaryDark: 'rgb(194, 192, 205)',
        dividerPrimaryLight: 'rgb(216, 215, 224)',
        dividerSecondary: 'rgb(198, 189, 255)',
        elevationCard: 'rgb(10, 0, 77)',
        externalAcst: 'rgb(235, 51, 51)',
        externalApple: 'rgb(6, 7, 10)',
        externalF1: 'rgb(26, 103, 177)',
        externalFacebook: 'rgb(24, 119, 242)',
        externalLinkedin: 'rgb(14, 118, 168)',
        externalPowerChurch: 'rgb(34, 159, 217)',
        externalQuickbooks: 'rgb(44, 160, 28)',
        externalServantKeeper: 'rgb(85, 164, 69)',
        externalShelby: 'rgb(64, 174, 215)',
        externalTwitter: 'rgb(29, 161, 242)',
        externalYoutube: 'rgb(255, 0, 0)',
        globalError100: 'rgb(255, 240, 240)',
        globalError200: 'rgb(255, 181, 181)',
        globalError300: 'rgb(252, 111, 111)',
        globalError400: 'rgb(234, 55, 55)',
        globalError600: 'rgb(201, 28, 28)',
        globalError700: 'rgb(172, 10, 10)',
        globalError800: 'rgb(141, 6, 6)',
        globalGenerosityOrange100: 'rgb(254, 241, 237)',
        globalGenerosityOrange200: 'rgb(255, 181, 157)',
        globalGenerosityOrange300: 'rgb(255, 128, 86)',
        globalGenerosityOrange400: 'rgb(248, 85, 32)',
        globalGenerosityOrange600: 'rgb(200, 56, 8)',
        globalGenerosityOrange700: 'rgb(177, 44, 0)',
        globalGenerosityOrange800: 'rgb(155, 38, 0)',
        globalGuidingViolet100: 'rgb(244, 242, 255)',
        globalGuidingViolet200: 'rgb(198, 189, 255)',
        globalGuidingViolet300: 'rgb(162, 147, 255)',
        globalGuidingViolet400: 'rgb(113, 90, 255)',
        globalGuidingViolet600: 'rgb(95, 71, 246)',
        globalGuidingViolet700: 'rgb(83, 54, 226)',
        globalGuidingViolet800: 'rgb(69, 41, 207)',
        globalInfo100: 'rgb(229, 248, 255)',
        globalInfo200: 'rgb(167, 226, 249)',
        globalInfo300: 'rgb(106, 197, 231)',
        globalInfo400: 'rgb(43, 173, 221)',
        globalInfo600: 'rgb(12, 118, 156)',
        globalInfo700: 'rgb(11, 89, 117)',
        globalInfo800: 'rgb(0, 61, 83)',
        globalNeutral0: 'rgb(255, 255, 255)',
        globalNeutral100: 'rgb(243, 243, 247)',
        globalNeutral200: 'rgb(216, 215, 224)',
        globalNeutral300: 'rgb(194, 192, 205)',
        globalNeutral400: 'rgb(158, 157, 169)',
        globalNeutral50: 'rgb(250, 250, 252)',
        globalNeutral600: 'rgb(106, 104, 115)',
        globalNeutral700: 'rgb(84, 81, 90)',
        globalNeutral800: 'rgb(48, 45, 54)',
        globalNeutral900: 'rgb(40, 40, 48)',
        globalSuccess100: 'rgb(232, 249, 237)',
        globalSuccess200: 'rgb(178, 234, 201)',
        globalSuccess300: 'rgb(115, 201, 151)',
        globalSuccess400: 'rgb(52, 166, 82)',
        globalSuccess600: 'rgb(8, 125, 38)',
        globalSuccess700: 'rgb(8, 94, 23)',
        globalSuccess800: 'rgb(9, 66, 28)',
        globalWarning100: 'rgb(254, 250, 236)',
        globalWarning200: 'rgb(255, 236, 170)',
        globalWarning300: 'rgb(255, 214, 120)',
        globalWarning400: 'rgb(255, 194, 52)',
        globalWarning600: 'rgb(156, 97, 3)',
        globalWarning700: 'rgb(110, 66, 1)',
        globalWarning800: 'rgb(74, 49, 11)',
        iconAccentBg: 'rgb(244, 242, 255)',
        iconAccentDefault: 'rgb(95, 71, 246)',
        iconAccentHover: 'rgb(83, 54, 226)',
        iconAccentPressed: 'rgb(69, 41, 207)',
        iconDisabled: 'rgb(194, 192, 205)',
        iconError: 'rgb(201, 28, 28)',
        iconErrorBg: 'rgb(255, 240, 240)',
        iconHover: 'rgb(84, 81, 90)',
        iconInfo: 'rgb(12, 118, 156)',
        iconInfoBg: 'rgb(229, 248, 255)',
        iconPrimary: 'rgb(106, 104, 115)',
        iconPrimaryBg: 'rgb(243, 242, 250)',
        iconSecondary: 'rgb(158, 157, 169)',
        iconSuccess: 'rgb(8, 125, 38)',
        iconSuccessBg: 'rgb(232, 249, 237)',
        iconWarning: 'rgb(156, 97, 3)',
        iconWarningBg: 'rgb(254, 250, 236)',
        iconWhite: 'rgb(255, 255, 255)',
        navigationBackgroundDefault: 'rgb(255, 255, 255)',
        navigationBackgroundHover: 'rgb(243, 242, 250)',
        navigationBackgroundSelect: 'rgb(254, 241, 237)',
        navigationIconDefault: 'rgb(106, 104, 115)',
        navigationIconHover: 'rgb(106, 104, 115)',
        navigationIconSelect: 'rgb(200, 56, 8)',
        navigationTextDefault: 'rgb(106, 104, 115)',
        navigationTextHover: 'rgb(106, 104, 115)',
        navigationTextSelect: 'rgb(200, 56, 8)',
        neutralGrey: 'rgba(103, 110, 119, 1)',
        neutralPlatinum: 'rgba(227, 227, 227, 1)',
        overlayModal: 'rgba(241, 240, 247, 0.8)',
        textAccentDefault: 'rgb(95, 71, 246)',
        textAccentHover: 'rgb(83, 54, 226)',
        textAccentPressed: 'rgb(69, 41, 207)',
        textDisabled: 'rgb(194, 192, 205)',
        textErrorDefault: 'rgb(201, 28, 28)',
        textErrorHover: 'rgb(172, 10, 10)',
        textErrorPressed: 'rgb(141, 6, 6)',
        textInfoDefault: 'rgb(12, 118, 156)',
        textInfoHover: 'rgb(11, 89, 117)',
        textInfoPressed: 'rgb(0, 61, 83)',
        textPrimary: 'rgb(40, 40, 48)',
        textSecondary: 'rgb(106, 104, 115)',
        textSecondaryAccent: 'rgb(162, 147, 255)',
        textSuccessDefault: 'rgb(8, 125, 38)',
        textSuccessHover: 'rgb(8, 94, 23)',
        textSuccessPressed: 'rgb(9, 66, 28)',
        textWarningDefault: 'rgb(156, 97, 3)',
        textWarningHover: 'rgb(110, 66, 1)',
        textWarningPressed: 'rgb(74, 49, 11)',
        textWhite: 'rgb(255, 255, 255)',
    },
    measurement: {
        avatarBoxSizeExtraLarge: '120px',
        avatarBoxSizeExtraSmall: '32px',
        avatarBoxSizeLarge: '64px',
        avatarBoxSizeMediumLarge: '56px',
        avatarBoxSizeMedium: '48px',
        avatarBoxSizeSmall: '40px',
        borderRadiusL: '24px',
        borderRadiusM: '16px',
        borderRadiusS: '8px',
        borderRadiusXl: '32px',
        borderRadiusXs: '4px',
        buttonPaddingLargeX: '32px',
        buttonPaddingLargeY: '12px',
        buttonPaddingMediumX: '32px',
        buttonPaddingMediumY: '8px',
        buttonPaddingSmallX: '32px',
        buttonPaddingSmallY: '4px',
        buttonPaddingNarrowX: '16px',
        buttonRadius: '32px',
        calendarBoxSizeHeight: '352px',
        calendarBoxSizeWidth: '368px',
        fontDefaultSize: '24px',
        iconButtonBoxSizeLarge: '48px',
        iconButtonBoxSizeMedium: '40px',
        iconButtonBoxSizeSmall: '32px',
        iconButtonPaddingLarge: '12px',
        iconButtonPaddingMedium: '8px',
        iconButtonPaddingSmall: '4px',
        iconDefaultSize: '24px',
        inputFieldDateLargeBottom: '8px',
        inputFieldDateLargeLeft: '16px',
        inputFieldDateLargeMinHeight: '24px',
        inputFieldDateLargeMinWidth: '106px',
        inputFieldDateLargeRight: '8px',
        inputFieldDateLargeTop: '8px',
        inputFieldDateMediumBottom: '8px',
        inputFieldDateMediumLeft: '12px',
        inputFieldDateMediumMinHeight: '24px',
        inputFieldDateMediumMinWidth: '106px',
        inputFieldDateMediumRight: '8px',
        inputFieldDateMediumTop: '8px',
        inputFieldPasswordLargeBottom: '8px',
        inputFieldPasswordLargeLeft: '16px',
        inputFieldPasswordLargeMinHeight: '24px',
        inputFieldPasswordLargeMinWidth: '156px',
        inputFieldPasswordLargeRight: '8px',
        inputFieldPasswordLargeTop: '8px',
        inputFieldPasswordMediumBottom: '8px',
        inputFieldPasswordMediumLeft: '12px',
        inputFieldPasswordMediumMinHeight: '24px',
        inputFieldPasswordMediumMinWidth: '160px',
        inputFieldPasswordMediumRight: '8px',
        inputFieldPasswordMediumTop: '8px',
        inputFieldRadius: '4px',
        inputFieldSelectLargeBottom: '16px',
        inputFieldSelectLargeLeft: '16px',
        inputFieldSelectLargeMinHeight: '24px',
        inputFieldSelectLargeMinWidth: '164px',
        inputFieldSelectLargeRight: '12px',
        inputFieldSelectLargeTop: '16px',
        inputFieldSelectMediumBottom: '12px',
        inputFieldSelectMediumLeft: '12px',
        inputFieldSelectMediumMinHeight: '24px',
        inputFieldSelectMediumMinWidth: '172px',
        inputFieldSelectMediumRight: '8px',
        inputFieldSelectMediumTop: '12px',
        inputFieldTextLargeBottom: '16px',
        inputFieldTextLargeLeft: '16px',
        inputFieldTextLargeMinHeight: '24px',
        inputFieldTextLargeMinWidth: '188px',
        inputFieldTextLargeRight: '16px',
        inputFieldTextLargeTop: '16px',
        inputFieldTextMediumBottom: '12px',
        inputFieldTextMediumLeft: '12px',
        inputFieldTextMediumMinHeight: '24px',
        inputFieldTextMediumMinWidth: '196px',
        inputFieldTextMediumRight: '12px',
        inputFieldTextMediumTop: '12px',
        inputFieldTextWidthCompact: '170px',
        inputFieldTextWidthDefault: '220px',
        modalRadius: '32px',
        searchBarRadius: '32px',
    },
    border: {
        borderButtonDefault: {
            width: 1,
            style: 'solid',
            color: 'rgb(113, 90, 255)',
        },
        borderButtonDestructiveDefault: {
            width: 1,
            style: 'solid',
            color: 'rgb(201, 28, 28)',
        },
        borderButtonDestructiveHover: {
            width: 1,
            style: 'solid',
            color: 'rgb(172, 10, 10)',
        },
        borderButtonDestructivePressed: {
            width: 1,
            style: 'solid',
            color: 'rgb(141, 6, 6)',
        },
        borderButtonDisabled: {
            width: 1,
            style: 'solid',
            color: 'rgb(194, 192, 205)',
        },
        borderButtonHover: {
            width: 1,
            style: 'solid',
            color: 'rgb(83, 54, 226)',
        },
        borderButtonPressed: {
            width: 1,
            style: 'solid',
            color: 'rgb(69, 41, 207)',
        },
        borderComponentAccentPrimary: {
            width: 1,
            style: 'solid',
            color: 'rgb(113, 90, 255)',
        },
        borderComponentAccentSecondary: {
            width: 1,
            style: 'solid',
            color: 'rgb(244, 242, 255)',
        },
        borderComponentDefault: {
            width: 1,
            style: 'solid',
            color: 'rgb(158, 157, 169)',
        },
        borderComponentDisabled: {
            width: 1,
            style: 'solid',
            color: 'rgb(194, 192, 205)',
        },
        borderComponentError: {
            width: 1,
            style: 'solid',
            color: 'rgb(234, 55, 55)',
        },
        borderComponentFocused: {
            width: 2,
            style: 'solid',
            color: 'rgb(113, 90, 255)',
        },
        borderComponentInfo: {
            width: 1,
            style: 'solid',
            color: 'rgb(43, 173, 221)',
        },
        borderComponentSuccess: {
            width: 1,
            style: 'solid',
            color: 'rgb(52, 166, 82)',
        },
        borderComponentWarning: {
            width: 1,
            style: 'solid',
            color: 'rgb(255, 194, 52)',
        },
        borderComponentWhite: {
            width: 1,
            style: 'solid',
            color: 'rgb(255, 255, 255)',
        },
    },
    shadow: {
        divider1: 'inset 0px -1px 0px rgb(216, 215, 224)',
        divider2: 'inset 0px -1px 0px rgb(194, 192, 205)',
        divider3:
            'inset 0px -1px 0px rgb(216, 215, 224), inset 0px 2px 0px rgb(216, 215, 224)',
        shadowHigh:
            '0px 5.5px 30px rgba(10, 0, 77, 0.05), 0px 1px 6px rgba(10, 0, 77, 0.04)',
        shadowLow:
            '0px 0.5px 1.5px rgba(10, 0, 77, 0.19), 0px 0px 1px rgba(10, 0, 77, 0.04)',
        shadowMedium:
            '0px 2.389147996902466px 7.819029808044434px rgba(10, 0, 77, 0.08), 0px 0.25px 2.75px rgba(10, 0, 77, 0.04)',
        shadowMediumHigh:
            '0px 4px 15px rgba(10, 0, 77, 0.06), 0px 0.5px 3px rgba(10, 0, 77, 0.04)',
        shadowMediumLow:
            '0px 0.8500000238418579px 3px rgba(10, 0, 77, 0.19), 0px 0.25px 1px rgba(10, 0, 77, 0.04)',
        shadowLowHigh:
            '0px 0px 1px rgba(10, 0, 77, 0.039), 0px 0.5px 1.5px rgba(10, 0, 77, 0.19)',
    },
    textStyle: {
        globalBody1: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 600,
                size: 16,
                lineHeight: 24,
                family: 'AreaNormal-Semibold',
            },
            letterSpacing: 'normal',
        },
        globalBody1B: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 800,
                size: 16,
                lineHeight: 24,
                family: 'AreaNormal-Extrabold',
            },
            letterSpacing: 'normal',
        },
        globalBody2: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 600,
                size: 14,
                lineHeight: 24,
                family: 'AreaNormal-Semibold',
            },
            letterSpacing: 'normal',
        },
        globalBody2B: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 800,
                size: 14,
                lineHeight: 24,
                family: 'AreaNormal-Extrabold',
            },
            letterSpacing: 'normal',
        },
        globalButton1: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 800,
                size: 16,
                lineHeight: 24,
                family: 'AreaNormal-Extrabold',
            },
            letterSpacing: 'normal',
        },
        globalButton1M: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 800,
                size: 12,
                lineHeight: 20,
                family: 'AreaNormal-Extrabold',
            },
            letterSpacing: 'normal',
        },
        globalCaption1: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 600,
                size: 12,
                lineHeight: 24,
                family: 'AreaNormal-Semibold',
            },
            letterSpacing: 'normal',
        },
        globalCaption1B: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 800,
                size: 12,
                lineHeight: 24,
                family: 'AreaNormal-Extrabold',
            },
            letterSpacing: 'normal',
        },
        globalCaption2: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 600,
                size: 12,
                lineHeight: 24,
                family: 'AreaNormal-Semibold',
            },
            letterSpacing: 1.600000023841858,
        },
        globalCaption2B: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 800,
                size: 12,
                lineHeight: 24,
                family: 'AreaNormal-Extrabold',
            },
            letterSpacing: 1.600000023841858,
        },
        globalComponentInput: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 700,
                size: 16,
                lineHeight: 24,
                family: 'AreaNormal-Bold',
            },
            letterSpacing: 'normal',
        },
        globalComponentLabel: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 700,
                size: 12,
                lineHeight: 16,
                family: 'AreaNormal-Bold',
            },
            letterSpacing: 'normal',
        },
        globalData1: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 600,
                size: 12,
                lineHeight: 12,
                family: 'AreaNormal-Semibold',
            },
            letterSpacing: 'normal',
        },
        globalData1B: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 800,
                size: 12,
                lineHeight: 12,
                family: 'AreaNormal-Extrabold',
            },
            letterSpacing: 'normal',
        },
        globalHeadingL1: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 600,
                size: 54,
                lineHeight: 64,
                family: 'AreaNormal-Semibold',
            },
            letterSpacing: 'normal',
        },
        globalHeadingL2: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 600,
                size: 48,
                lineHeight: 64,
                family: 'AreaNormal-Semibold',
            },
            letterSpacing: 'normal',
        },
        globalHeadingL3: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 600,
                size: 42,
                lineHeight: 56,
                family: 'AreaNormal-Semibold',
            },
            letterSpacing: 'normal',
        },
        globalHeadingM1: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 600,
                size: 36,
                lineHeight: 48,
                family: 'AreaNormal-Semibold',
            },
            letterSpacing: 'normal',
        },
        globalHeadingM2: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 600,
                size: 32,
                lineHeight: 40,
                family: 'AreaNormal-Semibold',
            },
            letterSpacing: 'normal',
        },
        globalHeadingM3: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 600,
                size: 28,
                lineHeight: 40,
                family: 'AreaNormal-Semibold',
            },
            letterSpacing: 'normal',
        },
        globalHeadingS1: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 700,
                size: 24,
                lineHeight: 32,
                family: 'AreaNormal-Bold',
            },
            letterSpacing: 'normal',
        },
        globalHeadingS2: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 700,
                size: 20,
                lineHeight: 32,
                family: 'AreaNormal-Bold',
            },
            letterSpacing: 'normal',
        },
        globalHeadingS3: {
            color: 'rgb(0, 0, 0)',
            font: {
                style: null,
                variant: null,
                weight: 700,
                size: 16,
                lineHeight: 32,
                family: 'AreaNormal-Bold',
            },
            letterSpacing: 'normal',
        },
    },
};
