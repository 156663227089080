import { DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';

export const EditorContainer = styled('div')(({ theme }) => ({
    '& .addr-mobile': {
        position: 'relative',
        left: -35,
    },
    '& .sum-mobile': {
        position: 'relative',
        left: -90,
    },
}));

export const Box = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(2),
    alignItems: 'flex-start',
    gap: theme.spacing(0, 7),
}));

export const FormBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 612,
    '& .description': {
        marginTop: theme.spacing(1.5),
    },
    '& .rep-box': {
        marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down('smallTablet')]: {
        maxWidth: 'initial',
    },
}));

export const InfoBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
}));

export const InputsBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(4),
}));

export const NameFields = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column',
    },
}));

export const Note = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(0.5),
    marginTop: theme.spacing(2),
}));

export const NoteAccent = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    alignItems: 'flex-start',
    padding: theme.spacing(3),
    borderRadius: DesignTokens.measurement.borderRadiusM,
    backgroundColor: DesignTokens.color.globalGuidingViolet100,
    [theme.breakpoints.down('tablet')]: {
        padding: theme.spacing(3, 2),
    },
    [theme.breakpoints.down('mobile')]: {
        padding: theme.spacing(2),
        flexDirection: 'column',
    },
}));

export const NoteAccentRow = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'flex-start',
}));

export const SummaryBox = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(3),
    padding: theme.spacing(3, 2),
    backgroundColor: DesignTokens.color.backgroundComponentSecondary,
    borderRadius: DesignTokens.measurement.borderRadiusM,
    width: '100%',
    '& .sum-last': {
        borderBottom: 'none',
    },
    [theme.breakpoints.down('mobile')]: {
        padding: theme.spacing(2),
    },
}));

export const SummaryRow = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(2.5, 0),
    gap: theme.spacing(2),
    alignItems: 'center',
    borderBottom: `1px solid ${DesignTokens.color.backgroundComponentQuaternary}`,
    '& .sum-header': {
        width: 160,
    },
    '& .sum-value': {
        maxWidth: 165,
    },
    '& .sum-edit': {
        padding: theme.spacing(0, 1),
    },
    '& .sum-right': {
        marginLeft: 'auto',
    },
    [theme.breakpoints.down('mobile')]: {
        padding: theme.spacing(1.5, 0),
        alignItems: 'flex-end',
        '& .sum-header': {
            maxWidth: 135,
        },
        '& .sum-value': {
            maxWidth: 135,
        },
    },
}));

export const ValueRow = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column',
        gap: 0,
        alignItems: 'flex-start',
    },
}));
