import React from 'react';
import { DesignTokens, GivelifyButton, WarningIcon } from '@givelify/ui';
import {
    RequestState,
    useApiRequest,
    TimeFrameValue,
    useTrackingContext,
    MODAL_NAME,
    TimeFrameFilterIds,
} from '@givelify/utils';
import dayjs from 'dayjs';
import { useOverviewLoaderData } from 'pages/overview/hooks';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import {
    DonorDistribution,
    DonorsTimeFrameData,
} from 'store/dashboard/donors/types';
import {
    EnvelopeDistribution,
    EnvelopeDistributionData,
} from 'store/dashboard/envelopes/types';
import {
    GIVING_STYLE_CHART_SECTOR_CLICK,
    TOP_ENVELOPES_CHART_SECTOR_CLICK,
} from 'utils/clevertapEvents';
import { useAdvancedTranslation } from 'utils/i18n';
import { envelopeDonutColorsList } from '../../components/styles';
import { OverviewPaper } from '../components';
import { GivingStyleHelpModal } from './components/GivingStyleHelpModal';
import { EnvelopeChartTooltip, DonorsChartTooltip } from './pieChart';
import { PieChartsView } from './PieChartsView';
import { Item, Wrapper } from './styles';

export type PieChartsProps = {
    doneeId: number;
    onboardingEnabled: boolean;
    onboardingCompleted: boolean;
    envelopesDataRequest: RequestState<EnvelopeDistributionData>;
    lifetimeTimeFrame: TimeFrameValue;
    timeFrame: TimeFrameValue;
};
const PieChartsComponent: React.FC<PieChartsProps> = ({
    doneeId,
    onboardingCompleted,
    onboardingEnabled,
    envelopesDataRequest,
    lifetimeTimeFrame,
    timeFrame,
}) => {
    const navigate = useNavigate();
    const { PATH } = useGasRouterContext();
    const [isOpen, setIsOpen] = React.useState(false);
    const { t, at } = useAdvancedTranslation();
    const { trackOpenCloseEvent, trackEvent } = useTrackingContext();
    const copy = {
        envelopesStatusTitle: t('dashboard.donorsGivingTo.title'),
        donorsStatusTitle: t('dashboard.donorStatus.title'),
        envelopeLegendTitle: at('dashboard.donorsGivingTo.legendTitle'),
        donorsLegendTitle: t('dashboard.donorStatus.legendTitle'),
        description1: t('dashboard.donorStatus.description1'),
        description2: t('dashboard.donorStatus.description2'),
    };

    const { donorsData } = useOverviewLoaderData();

    const [getDonorsDataRequest] =
        useApiRequest<DonorsTimeFrameData>(donorsData);

    const handleOpenModal = React.useCallback(() => {
        trackOpenCloseEvent(true, MODAL_NAME.GivingStyleHelp);
        setIsOpen(true);
    }, [trackOpenCloseEvent]);

    const handleCloseModal = React.useCallback(() => {
        trackOpenCloseEvent(false, MODAL_NAME.GivingStyleHelp);
        setIsOpen(false);
    }, [trackOpenCloseEvent]);

    const handleDonationsTooltip = React.useCallback(
        (entry: EnvelopeDistribution, isSector?: boolean) => {
            const queryParams = {
                startDate: timeFrame.start.format('YYYY-MM-DD'),
                endDate: timeFrame.end.format('YYYY-MM-DD'),
                timeFrame:
                    TimeFrameFilterIds.indexOf(timeFrame.selector) > -1
                        ? timeFrame.selector
                        : 'custom',
                envelopeIds:
                    entry.aggregatedIds !== null
                        ? entry.aggregatedIds.join('_')
                        : JSON.stringify(entry.id),
            };

            const path = PATH.DONATIONS.DONATIONS_ACTIVITY({
                params: queryParams,
            });
            navigate(path);
            if (isSector) {
                trackEvent(TOP_ENVELOPES_CHART_SECTOR_CLICK);
            }
        },
        [
            trackEvent,
            navigate,
            timeFrame.end,
            timeFrame.start,
            timeFrame.selector,
            PATH.DONATIONS,
        ],
    );

    const handleDonorsChartClick = React.useCallback(
        (entry: DonorDistribution, isSector?: boolean) => {
            const queryParams = {
                lastGivenOnStart: dayjs(lifetimeTimeFrame.start).format(
                    'YYYY-MM-DD',
                ),
                lastGivenOnEnd: dayjs(lifetimeTimeFrame.end).format(
                    'YYYY-MM-DD',
                ),
                [`givingStyle${entry.name}`]: 'true',
            };

            const path = PATH.DONORS.ROOT('your-donors', queryParams);
            navigate(path);
            if (isSector) {
                trackEvent(GIVING_STYLE_CHART_SECTOR_CLICK);
            }
        },
        [navigate, lifetimeTimeFrame, PATH.DONORS, trackEvent],
    );

    return (
        <Wrapper data-testid="pie-charts-wrapper">
            <Item>
                <OverviewPaper
                    data-testid="paper-title-envelopes"
                    title={copy.envelopesStatusTitle}
                    variant="heading2S"
                >
                    <PieChartsView<
                        EnvelopeDistribution,
                        EnvelopeDistributionData
                    >
                        data={envelopesDataRequest}
                        dataKey="sum"
                        legendTitle={copy.envelopeLegendTitle}
                        nameKey="title"
                        onSectorClick={handleDonationsTooltip}
                        onboardingCompleted={onboardingCompleted}
                        onboardingEnabled={onboardingEnabled}
                        tooltip={EnvelopeChartTooltip}
                    />
                </OverviewPaper>
            </Item>
            <Item>
                <GivingStyleHelpModal
                    onClose={handleCloseModal}
                    open={isOpen}
                />
                <OverviewPaper
                    data-testid="paper-title-donors"
                    icon={
                        <GivelifyButton
                            data-testid="modal-info-button"
                            onClick={handleOpenModal}
                            sx={{
                                padding: 0,
                                color: DesignTokens.color.iconAccentDefault,
                            }}
                            variant="icon"
                        >
                            <WarningIcon fontSize={'large'} />
                        </GivelifyButton>
                    }
                    title={copy.donorsStatusTitle}
                    variant="heading2S"
                >
                    <PieChartsView<DonorDistribution, DonorsTimeFrameData>
                        data={getDonorsDataRequest}
                        dataKey="donorsCount"
                        fillColors={envelopeDonutColorsList}
                        helperText1={copy.description1}
                        helperText2={copy.description2}
                        legendTitle={copy.donorsLegendTitle}
                        nameKey="name"
                        onSectorClick={handleDonorsChartClick}
                        onboardingCompleted={onboardingCompleted}
                        onboardingEnabled={onboardingEnabled}
                        tooltip={DonorsChartTooltip}
                    />
                </OverviewPaper>
            </Item>
        </Wrapper>
    );
};

export const PieCharts = React.memo(PieChartsComponent);
