import React, { useCallback } from 'react';
import { setDefaultStartDate, useTrackingContext } from '@givelify/utils';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { DONEE_SEARCH_QUERY_NAME } from 'router/GasRouterProvider';
import { AppState } from 'store';
import { NAVIGATION_CLICK_SELECT_CAMPUS } from 'utils/clevertapEvents';
import CampusSelector from './CampusSelector';

const CampusSelectorWrapper: React.FC = () => {
    const { trackEvent } = useTrackingContext();
    const { campuses, donee } = useSelector((state: AppState) => ({
        campuses: state.Donee.campuses,
        donee: state.Donee.donee,
    }));
    const navigate = useNavigate();
    const location = useLocation();
    const shortCampuses = React.useMemo(
        () =>
            campuses
                ? campuses.map((campus) => ({
                      id: campus.id,
                      address: campus.address,
                      name: campus.name,
                  }))
                : [],
        [campuses],
    );

    const handleChange = useCallback(
        async (campusId: number) => {
            const campus = campuses.find((campus) => campus.id === campusId);
            setDefaultStartDate(campus?.createdAt.toString());
            trackEvent(NAVIGATION_CLICK_SELECT_CAMPUS);
            const newUrl = `${location.pathname}?${DONEE_SEARCH_QUERY_NAME}=${campusId}`;
            navigate(newUrl);
        },
        [campuses, location.pathname, navigate, trackEvent],
    );

    return (
        <CampusSelector
            activeDoneeId={donee?.id}
            campuses={shortCampuses}
            onChange={handleChange}
        />
    );
};

export default React.memo(CampusSelectorWrapper);
