import { AutoIntegration } from 'store/automatedIntegration/types';
import { ReportType } from 'store/report/types';

export const integrationUrl = (donorId: number, prefix: string) => {
    return `/donees/${donorId}/integration/${prefix}`;
};
export const getCcbUrl =
    'https://support.givelify.com/hc/en-us/articles/4404341624599';

export interface AutoIntegrationMatchNumberResponse {
    data: {
        donors: {
            matched: number;
            unmatched: number;
        };
        envelopes: {
            matched: number;
            unmatched: number;
        };
    };
}

export interface SetupCCBFormData {
    url: string;
    username: string;
    password: string;
}

export type SetupF1GoFormData = SetupCCBFormData;

export interface TotalMatchDonors {
    matched: number;
    unmatched: number;
}
export interface TotalMatchEnvelopes {
    matched: number;
    unmatched: number;
}

export interface IntegrationPaginatedResponse<T> {
    data: T[];
    meta: Partial<IntegrationPagination>;
    pageNumbers?: number[];
}

export const initialIntegrationPaginatedResponse = {
    data: [],
    meta: {},
};

export interface AutoIntegrationDonorMatchedData {
    id: number;
    fullName: string;
    email: string;
    phone: string;
    streetAddress: string;
    zip: string;
    pictureUrl?: string;
    match: {
        matchType: string;
        chmsDonor: {
            chmsInternalId: number;
            firstName: string;
            lastName: string;
            addresses: [
                {
                    streetAddress: string;
                },
            ];
            emails: [string];
            phones: [string];
        };
    };
}

export interface AutoInteDonorResponseData {
    chmsInternalId: number;
    firstName: string;
    lastName: string;
    emails: [string];
    addresses: [
        {
            zip: string;
            streetAddress: string;
        },
    ];
    phones: [string];
    similarityScore: number;
}

export interface AutoInteDonorData {
    f1Id: number;
    f1FirstName: string;
    f1LastName: string;
    f1Email: string;
    f1Address: string;
    f1Phone: string;
    similarityScore: number;
}

export const mapAutoInteDonorResponseToDonor = (
    donor: AutoInteDonorResponseData,
) => {
    const mappedF1Donor: AutoInteDonorData = {
        f1Id: donor.chmsInternalId,
        f1FirstName: donor.firstName,
        f1LastName: donor.lastName,
        f1Email: donor.emails[0],
        f1Address: donor.addresses[0]?.streetAddress,
        f1Phone: donor.phones[0],
        similarityScore: donor.similarityScore,
    };

    return mappedF1Donor;
};

export interface AutoInteDonorUnmatchData {
    id: number;
    fullName: string;
    lastDonationAmount: number;
    lastDonatedAt: string;
    matchSuggestionsCount?: number;
    pictureUrl?: string;
}

export interface AutoInteEnterDonorIdData {
    donorId: string;
}

export interface IntegrationCampus {
    id: number;
    name: string;
    isHq: boolean;
}
export interface IntegrationCampusesResponse {
    data: IntegrationCampus[];
}

export interface IntegrationCampus {
    id: number;
    name: string;
    isHq: boolean;
}
export interface IntegrationCampusesResponse {
    data: IntegrationCampus[];
}

export interface AutoInteEnterDonorIdData {
    donorId: string;
}

export interface IntegrationCampus {
    id: number;
    name: string;
    isHq: boolean;
}
export interface IntegrationCampusesResponse {
    data: IntegrationCampus[];
}

export interface AutoIntegrationEnterDonorIdData {
    donorId: string;
}

export interface AutoIntegrationDonorResponseData {
    chmsInternalId: number;
    firstName: string;
    lastName: string;
    emails: [string];
    addresses: [
        {
            zip: string;
            streetAddress: string;
        },
    ];
    phones: [string];
    similarityScore: number;
}

export interface AutoIntegrationDonorData {
    f1Id: number;
    f1FirstName: string;
    f1LastName: string;
    f1Email: string;
    f1Address: string;
    f1Phone: string;
    similarityScore: number;
}

export const mapAutoIntegrationDonorResponseToDonor = (
    donor: AutoIntegrationDonorResponseData,
) => {
    const mappedF1Donor: AutoIntegrationDonorData = {
        f1Id: donor.chmsInternalId,
        f1FirstName: donor.firstName,
        f1LastName: donor.lastName,
        f1Email: donor.emails[0],
        f1Address: donor.addresses[0]?.streetAddress,
        f1Phone: donor.phones[0],
        similarityScore: donor.similarityScore,
    };

    return mappedF1Donor;
};

export interface AutoIntegrationSettingData {
    data: {
        type: ReportType;
        doneeId: number;
        settings: {
            donationsAutoexport: boolean;
            exportStartDate?: string | null;
        };
        credentials?: {
            url?: string;
            username?: string;
        };
        initialSetupStatus?: string;
    };
}

export interface ChmsEnvelope {
    chmsInternalId: number;
    name: string;
    parentId: number;
}
export interface ChmsEnvelopeParent extends ChmsEnvelope {
    parent: ChmsEnvelope;
}
export interface CCBEnvelopeMatchedData {
    id: number;
    name: string;
    campus: {
        id: number;
        isHq: boolean;
        name: string;
    };
    match: {
        matchType: string;
        level0: ChmsEnvelope;
        level1?: ChmsEnvelopeParent;
    };
}

export interface AutoInteExportedDonationsResponse {
    givelifyDonationId: number;
    givelifyDonationEnvelopeId: number;
    chmsInternalId: number;
    amount: number;
    givelifyEnvelope?: {
        id: number;
        name: string;
    };
    chmsDonor: {
        chmsInternalId: number;
        firstName: string;
        lastName: string;
        emails: string[];
        addresses: string[];
        phones: string[];
    };
    chmsEnvelope: {
        chmsInternalId: number;
        name: string;
        parentId: number;
        parent: {
            chmsInternalId: number;
            name: string;
            parentId: number;
        };
    };
    givelifyDonor: {
        city: string;
        email: string;
        fullName: string;
        id: number;
        phone: string;
        streetAddress: string;
        pictureUrl: string;
    };
    exportedAt: string;
}

export interface AutoIntegrationExportedDonationData {
    donationId: number;
    donationEnvelopeId: number;
    donorName: string;
    donorPicture: string | null;
    givelifyEnvelope?: string;
    chmsEnvelopeName: string;
    f1subFundName?: string;
    amount: number;
    exportDate: string;
}

export const mapAutoIntegrationExportedDonationToData = (
    exported: AutoInteExportedDonationsResponse,
) => {
    const mapAutoIntegrationExportedDonation: AutoIntegrationExportedDonationData =
        {
            donationId: exported.givelifyDonationId,
            donationEnvelopeId: exported.givelifyDonationEnvelopeId,
            donorName: exported.givelifyDonor?.fullName,
            donorPicture: exported.givelifyDonor?.pictureUrl,
            givelifyEnvelope: exported.givelifyEnvelope?.name,
            chmsEnvelopeName:
                exported.chmsEnvelope?.parent?.name ||
                exported.chmsEnvelope?.name,
            f1subFundName: exported.chmsEnvelope?.parent
                ? exported.chmsEnvelope?.name
                : '',
            amount: exported.amount,
            exportDate: exported.exportedAt,
        };
    return mapAutoIntegrationExportedDonation;
};

export type IntegrationPagination = {
    total: number;
    count: number;
    perPage: string;
    currentPage: number;
    lastPage: number;
};

export const initialIntegrationPagination: IntegrationPagination = {
    total: 0,
    count: 0,
    perPage: '15',
    currentPage: 1,
    lastPage: 1,
};

export interface CCBEnvelope {
    id: number;
    chmsInternalId: number;
    name: string;
    parentId: number;
    childrenCount: number;
}

export interface CCBEnvelopeUnmatchData {
    id: number;
    name: string;
    match: boolean;
    campus: {
        id: number;
        name: string;
        isHq: boolean;
    };
}

export type F1GoOrCCBCredentialUpdate = {
    credentials: {
        url: string;
        username: string;
        password: string;
    };
};

export const defaultCurrentDonor = {
    id: 0,
    givelifyNumber: '',
    externalId: '',
    name: '',
    donorType: {
        id: 0,
        statusType: '',
    },
    phone: '',
    email: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    picture: '',
    lastDonationDate: '',
    lastDonationAmount: 0,
    lifetimeDonations: 0,
};

export interface AutomatedIntegrationDonorResponseData {
    chmsInternalId: number;
    firstName: string;
    lastName: string;
    emails: [string];
    addresses: [
        {
            zip: string;
            streetAddress: string;
        },
    ];
    phones: [string];
    similarityScore: number;
}

export interface ExportDonationData {
    startDate: string;
    endDate: string;
}

export interface F1EnableRequest {
    type: string;
    credentials: SetupF1Request;
}

export type SetupF1FormData = {
    key: number;
    secret: string;
    username: string;
    password: string;
    churchCode: string;
};

export type SetupF1Request = {
    key: number;
    secret: string;
    username: string;
    password: string;
    church_code: string;
};

export const mapSetupF1ToRequest = (data: SetupF1FormData): SetupF1Request => ({
    key: data.key,
    secret: data.secret,
    username: data.username,
    password: data.password,
    church_code: data.churchCode,
});

export interface F1CredentialUpdate {
    credentials: {
        username: string;
        password: string;
    };
}

export interface F1EnableResponse {
    data: {
        credentials: {
            username: string;
            url: string;
        };
        doneeId: number;
        initialSetupStatus: string;
        type: ReportType;
        settings: {
            donationsAutoexport: boolean;
        };
    };
}

export const mapF1EnableResponseToAutoIntegration = (
    data: F1EnableResponse,
) => {
    const mapData: AutoIntegration = {
        data: {
            doneeId: data.data.doneeId,
            type: data.data.type,
            initialSetupStatus: data.data.initialSetupStatus,
        },
    };
    return mapData;
};

export interface OptionProps {
    id: number;
    name: string;
}

export interface CreatDonorOptionsProps {
    addressTypeOptions: OptionProps[];
    phoneTypeOptions: OptionProps[];
    emailTypeOptions: OptionProps[];
    familyPositionOptions: OptionProps[];
    membershipStatusOptions: OptionProps[];
}

export interface CreateDonorForm {
    givelifyDonorId: number;
    familyPositionId: number;
    addressTypeId: number;
    phoneTypeId: number;
    emailTypeId: number;
    membershipStatusId: number;
}

export type CreateDonorData = {
    givelify_donor_id: number;
    family_position_id: number;
    address_type_id: number;
    phone_type_id: number;
    email_type_id: number;
    membership_status_id: number;
};

export const mapCreateDonorFormToData = (
    data: CreateDonorForm,
): CreateDonorData => ({
    givelify_donor_id: data.givelifyDonorId,
    family_position_id: data.familyPositionId,
    address_type_id: data.addressTypeId,
    phone_type_id: data.phoneTypeId,
    email_type_id: data.emailTypeId,
    membership_status_id: data.membershipStatusId,
});
