import { GivelifyButton } from '@givelify/givelify-ui';
import { GivelifyForm } from '@givelify/ui';
import { styled } from '@mui/material';

export const Step1Box = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: theme.spacing(4),
    padding: theme.spacing(4),
    [theme.breakpoints.down('mobile')]: {
        padding: theme.spacing(2),
    },
}));

export const Step1Content = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(4),
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

export const Step1ContentPart = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: '325px',
}));

export const Step2Box = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: theme.spacing(2),
    minWidth: '760px',
    padding: theme.spacing(4),
    [theme.breakpoints.down('mobile')]: {
        minWidth: '100%',
        padding: theme.spacing(2),
    },
}));

export const Step2Content = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: theme.spacing(4),
}));

export const Step2FormRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: theme.spacing(2),
}));

export const Step2Form = styled(GivelifyForm)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
    maxWidth: '430px',
})) as typeof GivelifyForm;

export const SubmitButton = styled(GivelifyButton)(({ theme }) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2),
}));
