import { ImageWithDimensions } from '@givelify/onboarding';
import {
    ApiResponse,
    defaultStartDate,
    getAxiosClient,
    makeApiRequest,
    Logger,
} from '@givelify/utils';
import { getSignOutApiEndpoint } from 'api/client/endpoints';
import { AxiosResponse } from 'axios';
import mixpanel from 'mixpanel-browser';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AppActions, AppState } from 'store';
import { EditUserInfo, UpdateContactInfo } from 'types/userTypes';
import { httpDelete, httpGet, httpPatch, httpPost, httpPut } from '../thunks';
import {
    mapContactInfoToRequest,
    mapUserInfoToRequest,
    setAccessToken,
    setUser,
    setUserAvatar,
    setUserEmail,
} from './actions';
import { accessToken } from './reducer';
import { UserActions, UserState, User } from './types';

export const noEditUserError = new Error('Edit user not found');

export interface LoginFormData {
    username: string;
    password: string;
}

export interface ResetFormData {
    email: string;
}

export interface ResetPasswordFormData {
    password: string;
    password_confirmation: string;
}

const toFirstLetterUpperCase = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);

export const stripeOutAccessToken = (data: unknown) => {
    const token = data['access_token'];
    const type = data['token_type'];

    if (!token || !type) return undefined;

    return `${toFirstLetterUpperCase(type)} ${token}`;
};

export const mapResponseToUser = (user: unknown): User => {
    return {
        id: user['id'],
        doneeId: user['doneeId'],
        username: user['username'] || user['email'],
        fullName: user['fullName'],
        profilePicUrl: user['picture'],
        avatarOriginal: user['avatarOriginal'],
        avatar: user['avatar'],
        createdDateUser: new Date(user['createdDateUser'] || defaultStartDate),
        doneeName: user['doneeName'],
        notify: user['notify'] || false,
        phone: user['phone'],
        title: user['title'],
        email: user['email'],
        firstName: user['name'],
        lastName: user['lname'],
        isSuper: user['isSuper'],
        officialId: user['officialId'],
        isSynagogue: user['isSynagogue'],
        role: user['role'],
        roleId: user['roleId'],
        givingPartnerTimezone: user['givingPartnerTimezone'],
    };
};

export const mapResponseDataToUser = (data: unknown): UserState => {
    // TODO: make sure /login and /user returns a consistent response
    const user = data['data'] || data['user'] || data;

    return {
        user: mapResponseToUser(user),
        accessToken: stripeOutAccessToken(data) || accessToken,
    };
};

/**
 * Dispatcher to logout User
 */
export const logoutUser =
    (): ThunkDispatch<UserState, undefined, UserActions> =>
    async (dispatch) => {
        const response: AxiosResponse = await httpPost(getSignOutApiEndpoint())(
            dispatch,
        );
        if (response.status === 200) {
            dispatch(setAccessToken(undefined));
            if (window.mixpanel?.initialized) {
                mixpanel.reset();
            }
            return true;
        }
        return undefined;
    };

/**
 * Dispatcher to recover password
 */
export const recoverPassword =
    (data: ResetFormData): ThunkDispatch<UserState, undefined, UserActions> =>
    async (dispatch) => {
        const response: AxiosResponse = await httpPost(
            '/forgot-password',
            data,
        )(dispatch);

        return response;
    };

export const recoverPasswordPromise = (
    data: ResetFormData & { captchaToken: string },
): Promise<ApiResponse<unknown>> => {
    const httpRequest = getAxiosClient().post('/forgot-password', data);
    const result = makeApiRequest<unknown>(httpRequest);
    return result;
};

/**
 * Dispatcher to reset password
 */
export const resetPassword =
    (
        data: ResetPasswordFormData,
    ): ThunkDispatch<UserState, undefined, UserActions> =>
    async (dispatch) => {
        const response: AxiosResponse = await httpPut(
            '/reset-password',
            data,
        )(dispatch);

        return response;
    };

/**
 * Dispatcher to get email
 */
export const getEmail =
    (token): ThunkDispatch<UserState, undefined, UserActions> =>
    async (dispatch) => {
        try {
            const response: AxiosResponse = await httpGet(
                '/email?token=' + token,
            )(dispatch);

            if (response) {
                dispatch(setUserEmail(response.data.email));
                return response.data.email;
            }
        } catch (error) {
            return error;
        }
    };

export const loadUser =
    (): ThunkAction<Promise<boolean>, AppState, undefined, AppActions> =>
    async (dispatch, getState) => {
        const state = getState();
        const isSuperUser = state.User?.user?.isSuper;

        const httpRequest = getAxiosClient().get(`/user`);

        const userResponse = await makeApiRequest<{ data: User }>(httpRequest);
        if (!userResponse.success) return false;

        if (!isSuperUser) {
            const userData = mapResponseToUser(userResponse.response.data);
            dispatch(setUser(userData));
            Logger.configureUserData(userData.doneeId, userData.id);
        }

        return true;
    };

export const editUser =
    (
        isEmailSame: boolean,
        data: EditUserInfo,
    ): ThunkAction<
        Promise<ApiResponse<unknown>>,
        AppState,
        undefined,
        AppActions
    > =>
    async (dispatch, getState) => {
        const requestData = mapUserInfoToRequest(data, isEmailSame);
        const state = getState();
        const doneeId = state.Donee?.donee?.id;

        const url = `/donees/${doneeId}/users/${data.id}`;
        const httpRequest = getAxiosClient().patch(url, requestData);

        return makeApiRequest(httpRequest);
    };

export const deleteUser =
    (
        data: EditUserInfo,
    ): ThunkAction<Promise<void>, AppState, undefined, AppActions> =>
    async (dispatch, getState) => {
        const state = getState();
        const doneeId = state.Donee?.donee?.id;
        await httpDelete(`/donees/${doneeId}/users/${data.id}`)(dispatch);
    };

export const editUserAvatar =
    (
        image: ImageWithDimensions,
    ): ThunkAction<Promise<void>, AppState, undefined, AppActions> =>
    async (dispatch, getState) => {
        const requestData = {
            avatar: image.url,
            // eslint-disable-next-line
            avatar_original: image.croppedUrl,
            // eslint-disable-next-line
            avatar_cropped_coordinates: image.dimensions.croppedAreaPixels,
        };
        const state = getState();
        const doneeId = state.Donee?.donee?.id;
        const userId = state.User?.user?.id;
        await httpPatch(
            `/donees/${doneeId}/users/${userId}`,
            requestData,
        )(dispatch);

        dispatch(setUserAvatar(image));
    };

export const updateContactInfo =
    (
        data: UpdateContactInfo,
    ): ThunkAction<
        Promise<ApiResponse<unknown>>,
        AppState,
        undefined,
        AppActions
    > =>
    async (dispatch, getState) => {
        const requestData = mapContactInfoToRequest(data);
        const state = getState();
        const doneeId = state.Donee?.donee?.id;
        const url = `/donees/${doneeId}/faith-leader`;
        const httpRequest = getAxiosClient().put(url, requestData);

        return makeApiRequest(httpRequest);
    };
