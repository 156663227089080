import React from 'react';
import {
    DesignTokens,
    GivelifyForm,
    GivelifyLabel,
    GivelifyLink,
} from '@givelify/ui';
import { TrackingProvider } from '@givelify/utils';
import { useTheme, useMediaQuery } from '@mui/material';
import { SUPPORT_WHY_PRIMARY_REP_URL } from 'api/constants';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useRepInfoSchema } from '../../schema';
import { PrimaryRepInfoForm, primaryRepEditorViews } from '../../types';
import { InfoStepForm, InfoStepFormRef } from './InfoStepForm';

export type PrimaryRepEditorStepInfoProps = {
    userDetails: Partial<PrimaryRepInfoForm>;
    value: PrimaryRepInfoForm;
    replace: boolean;
    onCancelClick: () => void;
    onSubmit: (data: PrimaryRepInfoForm) => void;
    formRef: React.RefObject<InfoStepFormRef>;
};

export const PrimaryRepEditorStepInfo: React.FC<
    PrimaryRepEditorStepInfoProps
> = ({ userDetails, value, replace, onCancelClick, onSubmit, formRef }) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            title: t(
                'pages.settings.bank-info.customize-rep-tab.editor.step1.title',
            ),
            description: t(
                'pages.settings.bank-info.customize-rep-tab.editor.step1.description',
            ),
            placeholder1: t(
                'pages.settings.bank-info.customize-rep-tab.editor.step1.placeholder1',
            ),
            placeholder2: t(
                'pages.settings.bank-info.customize-rep-tab.editor.step1.placeholder2',
            ),
            placeholder3: t(
                'pages.settings.bank-info.customize-rep-tab.editor.step1.placeholder3',
            ),
            placeholder4: t(
                'pages.settings.bank-info.customize-rep-tab.editor.step1.placeholder4',
            ),
            placeholder5: t(
                'pages.settings.bank-info.customize-rep-tab.editor.step1.placeholder5',
            ),
            placeholder6: t(
                'pages.settings.bank-info.customize-rep-tab.editor.step1.placeholder6',
            ),
            learnMore: t(
                'pages.settings.bank-info.customize-rep-tab.editor.labels.learnMore',
            ),
        }),
        [t],
    );
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('smallTablet'));
    const schemaA = useRepInfoSchema(t);
    return (
        <TrackingProvider
            trackPageVisit
            pageName={primaryRepEditorViews.infoStep}
            viewType="other"
        >
            <GivelifyLabel variant={isSmallScreen ? 'heading2S' : 'heading1S'}>
                {copy.title}
            </GivelifyLabel>
            <GivelifyLabel
                className="description"
                color={DesignTokens.color.textSecondary}
                variant="body2"
            >
                {copy.description}
                <GivelifyLink
                    fontSize="inherit"
                    href={SUPPORT_WHY_PRIMARY_REP_URL}
                    marginLeft={0.5}
                    name="Primary rep why"
                    target="_blank"
                    text={copy.learnMore}
                />
            </GivelifyLabel>
            <GivelifyForm<PrimaryRepInfoForm>
                defaultValues={{
                    ...value,
                    dateOfBirth: value.dateOfBirth
                        ? dayjs(value.dateOfBirth)
                        : null,
                }}
                onSubmit={onSubmit}
                schema={schemaA}
            >
                <InfoStepForm
                    ref={formRef}
                    onCancelClick={onCancelClick}
                    replace={replace}
                    userDetails={{
                        firstName: userDetails.firstName,
                        lastName: userDetails.lastName,
                        title: userDetails.title,
                    }}
                />
            </GivelifyForm>
        </TrackingProvider>
    );
};
