export const emailRegex =
    /^(("[\p{L}\p{N}\p{M}_+\-\p{Z}]+")|([\p{L}\p{N}\p{M}_+-]+(?:\.[\p{L}\p{N}\p{M}_+-]+)*)|("[\p{L}\p{N}\p{M}_+\-\p{Z}]+")([\p{L}\p{N}\p{M}_+-]+(?:\.[\p{L}\p{N}\p{M}_+-]+)*))(@((?:[\p{L}\p{N}\p{M}_+-]+\.)*[\p{L}\p{N}\p{M}_][\p{L}\p{N}\p{M}_+-]{0,66})\.([a-z]{2,63}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/iu;

export const emailValidation = (value: string | undefined): boolean => {
    if (!value || value.length < 4) return false;
    try {
        return emailRegex.test(value);
    } catch (e) {
        return false;
    }
};
