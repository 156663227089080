import { Donee } from '@givelify/givelify-ui';
import {
    PrimaryRepresentativeInfo,
    ImageWithDimensions,
} from '@givelify/onboarding';
import { AddressInfo } from 'types/orgInfoTypes';
import {
    SetDoneeAndCampuses,
    SET_DONEE_AND_CAMPUSES,
    SetDonee,
    SET_DONEE,
    SET_DONEE_CUSTOM_RECEIPT,
    SetDoneeCustomReceipt,
    SetDoneeMissionStatement,
    SET_DONEE_MISSION_STATEMENT,
    SET_DONEE_ADDRESS,
    SetDoneeAddress,
    SET_DONEE_MAILING_ADDRESS,
    SetDoneeMailingAddress,
    SET_DONEE_CONTACT,
    SetDoneeContact,
    SET_DONEE_SOCIAL,
    SetDoneeSocial,
    SET_DONEE_FAITH_LEADER,
    SetDoneeFaithLeader,
    REMOVE_DONEE_FAITH_LEADER,
    RemoveDoneeFaithLeader,
    FaithLeaderInfo,
    SET_DONEE_ORGANIZATION_LOGO,
    SetDoneeOrganizationLogo,
    SET_DONEE_BANNER_IMAGE,
    SetDoneeBannerImage,
    SET_DONEE_PRIMARY_REP,
    SetDoneePrimaryRep,
    SET_DONEE_ACCOUNT_OWNER,
    AccountOwnerInfo,
    SetDoneeAccountOwner,
} from './types';

export const setDoneeAndCampuses = (
    donee: Donee,
    campuses: Donee[],
): SetDoneeAndCampuses => ({
    type: SET_DONEE_AND_CAMPUSES,
    donee,
    campuses,
});

export const setDonee = (donee: Donee): SetDonee => ({
    type: SET_DONEE,
    donee,
});

export const setDoneeCustomReceipt = (
    doneeId: number,
    useCustomReceipt: boolean,
    updatedAt: Date,
): SetDoneeCustomReceipt => ({
    doneeId,
    type: SET_DONEE_CUSTOM_RECEIPT,
    useCustomReceipt,
    updatedAt,
});

export const setDoneeMissionStatement = (
    doneeId: number,
    missionStatement: string,
    updatedAt: Date,
): SetDoneeMissionStatement => ({
    type: SET_DONEE_MISSION_STATEMENT,
    doneeId,
    missionStatement,
    updatedAt,
});

export const setDoneeAddress = (
    doneeId: number,
    address: string,
    city: string,
    state: string,
    zip: string,
    phone: string,
    updatedAt: Date,
    timezoneLong: string,
    timezoneShort: string,
    timezone: string,
): SetDoneeAddress => ({
    type: SET_DONEE_ADDRESS,
    doneeId,
    address,
    city,
    state,
    zip,
    phone,
    updatedAt,
    timezoneLong,
    timezoneShort,
    timezone,
});

export const setDoneeMailingAddress = (
    doneeId: number,
    mailingAddress: AddressInfo | null,
    updatedAt: Date,
): SetDoneeMailingAddress => ({
    type: SET_DONEE_MAILING_ADDRESS,
    doneeId,
    mailingAddress,
    updatedAt,
});

export const setDoneeContact = (
    doneeId: number,
    phone: string,
    email: string,
    established: string,
    congregationSize: string,
    denomination: string,
    updatedAt: Date,
): SetDoneeContact => ({
    type: SET_DONEE_CONTACT,
    doneeId,
    phone,
    email,
    established,
    congregationSize,
    denomination,
    updatedAt,
});

export const setDoneeSocial = (
    doneeId: number,
    facebook: string,
    instagram: string,
    twitter: string,
    website: string,
    updatedAt: Date,
): SetDoneeSocial => ({
    type: SET_DONEE_SOCIAL,
    doneeId,
    facebook,
    instagram,
    twitter,
    website,
    updatedAt,
});

export const setDoneeFaithLeader = (
    faithLeaderInfo: FaithLeaderInfo | undefined,
    updatedAt: Date,
): SetDoneeFaithLeader => ({
    type: SET_DONEE_FAITH_LEADER,
    faithLeaderInfo,
    updatedAt,
});

export const removeDoneeFaithLeader = (
    updatedAt: Date,
): RemoveDoneeFaithLeader => ({
    type: REMOVE_DONEE_FAITH_LEADER,
    updatedAt,
});

export const setDoneeAccountOwner = (
    accountOwnerInfo: AccountOwnerInfo,
    updatedAt: Date,
): SetDoneeAccountOwner => ({
    type: SET_DONEE_ACCOUNT_OWNER,
    accountOwnerInfo,
    updatedAt,
});

export const setDoneeOrganizationLogo = (
    logo: ImageWithDimensions,
): SetDoneeOrganizationLogo => ({
    type: SET_DONEE_ORGANIZATION_LOGO,
    logo,
});

export const setDoneeBannerImage = (
    image: ImageWithDimensions,
): SetDoneeBannerImage => ({
    type: SET_DONEE_BANNER_IMAGE,
    image,
});

export const setDoneePrimaryRep = (
    primaryRepInfo: PrimaryRepresentativeInfo,
): SetDoneePrimaryRep => ({
    type: SET_DONEE_PRIMARY_REP,
    primaryRepInfo,
});
