import React from 'react';
import {
    GivelifyAvatar,
    GivelifyButton,
    GivelifyLabel,
    GivelifyLink,
} from '@givelify/ui';
import { useMediaQuery, useTheme } from '@mui/material';
import { getCcbUrl } from 'pages/integrations/IntegrationContent/TabsContent/AutoIntegrationContent/types';
import { useTranslation } from 'react-i18next';
import { Step1Box, Step1Content, Step1ContentPart } from './styles';

type F1goSetupStep1Props = {
    onSubmit: () => void;
};

export const F1goSetupStep1: React.FC<F1goSetupStep1Props> = ({ onSubmit }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const { t } = useTranslation();
    const prefix = 'pages.integrations.content.setup-dialog.f1go';
    const copy = React.useMemo(() => {
        return {
            title: t(`${prefix}.title`),
            step1: t(`${prefix}.screen1.step1.title`),
            step2: t(`${prefix}.screen1.step2.title`),
            step1Description: t(`${prefix}.screen1.step1.line`),
            step2Description: t(`${prefix}.screen1.step2.line`),
            click: t(`${prefix}.screen1.clickHere`),
            description: t(`${prefix}.screen1.line`),
            button: t(`${prefix}.screen1.submitText`),
        };
    }, [t]);
    return (
        <Step1Box>
            <GivelifyLabel
                id="f1go-setup-title"
                text={copy.title}
                variant={isMobile ? 'heading3S' : 'heading1S'}
            />
            <Step1Content>
                <Step1ContentPart>
                    <GivelifyAvatar size="large" src="/f1go-assets/step1.svg" />
                    <GivelifyLabel
                        text={copy.step1}
                        variant={isMobile ? 'body2B' : 'body1B'}
                    />
                    <GivelifyLabel
                        color="GrayText"
                        text={copy.step1Description}
                        textAlign="center"
                        variant={isMobile ? 'body2' : 'body1'}
                    />
                </Step1ContentPart>
                <Step1ContentPart>
                    <GivelifyAvatar size="large" src="/f1go-assets/step2.svg" />
                    <GivelifyLabel
                        text={copy.step2}
                        variant={isMobile ? 'body2B' : 'body1B'}
                    />
                    <GivelifyLabel
                        color="GrayText"
                        text={copy.step2Description}
                        textAlign="center"
                        variant={isMobile ? 'body2' : 'body1'}
                    />
                </Step1ContentPart>
            </Step1Content>
            <GivelifyLabel
                textAlign="center"
                variant={isMobile ? 'body2' : 'body1'}
                width={isMobile ? 'auto' : '760px'}
            >
                <GivelifyLink
                    fontSize="inherit"
                    href={getCcbUrl}
                    rel="noopener"
                    target="_blank"
                    text={copy.click}
                />{' '}
                {copy.description}
            </GivelifyLabel>
            <GivelifyButton
                onClick={onSubmit}
                size="large"
                text={copy.button}
                variant="primary"
            />
        </Step1Box>
    );
};
