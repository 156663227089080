import React, { useCallback, useMemo, useState } from 'react';
import { GivelifyButton, GivelifyLabel } from '@givelify/givelify-ui';
import { GivelifyDatePicker } from '@givelify/ui';
import { mergeClassNames } from '@givelify/utils';
import { TimeFrameValue } from '@givelify/utils';
import { Stack } from '@mui/material';
import { TimeFrameFilter } from 'components';
import dayjs, { Dayjs } from 'dayjs';
import { useDoneeStartDate } from 'hooks/useDoneeStartDate';
import { reportItemStyles } from 'pages/reports/LegacyReports/components/style';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import { ExcludeEmptyEnvelopes } from './ExcludeEmptyEnvelopes';
import LegacyFileFormat from './LegacyFileFormat';

interface LegacyReportFormProps {
    type: 'custom' | 'quickbooks';
    timeFrame: TimeFrameValue;
    onTimeFrameChange: (timeFrame: TimeFrameValue) => unknown;
    format: string;
    onFormatFileChange: (value: boolean) => void;
    hasFormatFile: boolean;
    onSubmit: (
        startDate: Dayjs,
        endDate: Dayjs,
        pageName: string,
        excludeEmptyEnvelopes?: boolean,
    ) => void;
    isLoading?: boolean;
    name: string;
    backToReports: () => void;
    pageName?: string;
}

const LegacyReportForm: React.FC<LegacyReportFormProps> = ({
    type,
    timeFrame,
    onTimeFrameChange,
    format,
    onFormatFileChange,
    hasFormatFile,
    isLoading,
    name,
    onSubmit,
    pageName,
}) => {
    const { timezoneLong, timezoneShort, timezoneEnabled } = useSelector(
        (state: AppState) => ({
            timezoneLong: state.Donee.donee.timezoneLong,
            timezoneShort: state.Donee.donee.timezoneShort,
            timezoneEnabled:
                isFeatureEnabled(
                    state.System.enabledFeatures,
                    Features.TIMEZONES,
                    false,
                ) && state.Donee.donee.timezone !== null,
        }),
    );
    const classes = reportItemStyles();
    const { t } = useTranslation();

    const copy = useMemo(
        () => ({
            startDateMessage: t(
                'pages.integrations_custom_report.start_date_message',
            ),
            endDateMessage: t(
                'pages.integrations_custom_report.end_date_message',
            ),
            startDateRequired: t(
                'pages.integrations_custom_report.startDateRequired',
            ),
            endDateRequired: t(
                'pages.integrations_custom_report.endDateRequired',
            ),
            invalidStartDate: t(
                'pages.integrations_custom_report.invalidStartDate',
            ),
            invalidEndDate: t(
                'pages.integrations_custom_report.invalidEndDate',
            ),
            selectDateRange: t('reports.legacyReportsDashboard.selectDate'),
            to: t('reports.text.to'),
            fileFormat: t('reports.text.fileFormat'),
            exportInfo: t('reports.legacyReportsDashboard.exportInfo'),
            cancel: t('labels.cancel'),
            timezoneNote: t('timezones.notes.reports'),
        }),
        [t],
    );

    const [startDate, setStartDate] = React.useState<Dayjs | undefined>();
    const [startDateChanged, setStartDateChanged] = useState(false);

    const [endDate, setEndDate] = React.useState<Dayjs | undefined>();
    const [endDateChanged, setEndDateChanged] = useState(false);
    const [excludeEmptyEnvelopes, setExcludeEmptyEnvelopes] = useState(false);

    const showExcludeEnvelopes = ['donations', 'disbursments'];

    const onChangeStartDate = (date: Dayjs) => {
        setStartDate(date);
        setStartDateChanged(true);
    };
    const onChangeEndDate = (date: Dayjs) => {
        setEndDate(date);
        setEndDateChanged(true);
    };

    const { errorStartDate, errorEndDate } = useMemo(() => {
        let errorStartDate: string | undefined;
        let errorEndDate: string | undefined;

        if (timeFrame.selector !== 'custom') {
            return { errorStartDate, errorEndDate };
        }

        const startDateIsDate = startDate && startDate.isValid();
        const endDateIsDate = endDate && endDate.isValid();

        if (!startDateIsDate) {
            errorStartDate = copy.startDateRequired;
        }

        if (!endDateIsDate) {
            errorEndDate = copy.endDateRequired;
        }

        if (startDateIsDate && endDateIsDate && endDate < startDate) {
            errorStartDate = copy.startDateMessage;
            errorEndDate = copy.endDateMessage;
        }

        return {
            errorStartDate,
            errorEndDate,
        };
    }, [
        startDate,
        endDate,
        copy.endDateMessage,
        copy.endDateRequired,
        copy.startDateMessage,
        copy.startDateRequired,
        timeFrame.selector,
    ]);

    const minStartDate = dayjs.tz(useDoneeStartDate());
    const maxEndDate = useMemo(() => {
        return name === 'Bank deposits'
            ? dayjs().tz().subtract(1, 'day')
            : dayjs().tz(); // disallow today as an end
    }, [name]);
    const maxStartDate = useMemo(
        () => (endDate && endDate.isValid() ? endDate : maxEndDate),
        [endDate, maxEndDate],
    );

    const endDateError = useCallback(() => {
        if (!endDate) return true;
        const todayDate = dayjs().tz().startOf('day');
        const endDateStart = endDate.startOf('day');
        return (
            (name === 'Bank deposits' &&
                endDateStart.isSameOrAfter(todayDate)) ||
            endDateStart.isAfter(todayDate)
        );
    }, [endDate, name]);

    const minEndDate = useMemo(
        () => (startDate && startDate.isValid() ? startDate : minStartDate),
        [startDate, minStartDate],
    );

    const handleSubmit = useCallback(() => {
        if (timeFrame.selector === 'custom') {
            onSubmit(startDate, endDate, pageName, excludeEmptyEnvelopes);
        } else {
            onSubmit(
                timeFrame.start,
                timeFrame.end,
                pageName,
                excludeEmptyEnvelopes,
            );
        }
    }, [
        endDate,
        excludeEmptyEnvelopes,
        onSubmit,
        pageName,
        startDate,
        timeFrame,
    ]);

    const timeFrameChange = (timeFrame: TimeFrameValue) => {
        if (timeFrame.selector === 'custom') {
            timeFrame.start = startDate;
            timeFrame.end = endDate;
        }
        onTimeFrameChange(timeFrame);
    };

    return (
        <div className={classes.reportFormContainer}>
            <GivelifyLabel
                bold
                fontWeight={700}
                text={copy.selectDateRange}
                variant="heading4"
            />
            <div className={classes.reportDateRow}>
                <TimeFrameFilter
                    excludeToday={name === 'Bank deposits'}
                    mode="list"
                    onChange={timeFrameChange}
                    value={timeFrame}
                    variant="input"
                    width="fullwidth"
                />
                {timeFrame.selector === 'custom' && (
                    <>
                        <GivelifyDatePicker
                            fullwidth
                            disabled={isLoading}
                            helperText={startDateChanged && errorStartDate}
                            id="start-date"
                            label=""
                            maxDate={maxStartDate}
                            minDate={minStartDate}
                            name="startDate"
                            onDateChange={onChangeStartDate}
                            state={
                                startDateChanged && errorStartDate
                                    ? 'error'
                                    : 'idle'
                            }
                            value={startDate ? startDate : undefined}
                        />
                        <GivelifyLabel
                            marginTop={13}
                            text={copy.to}
                            variant="body1"
                        />
                        <GivelifyDatePicker
                            fullwidth
                            disabled={isLoading}
                            helperText={endDateChanged && errorEndDate}
                            id="end-date"
                            label=""
                            maxDate={maxEndDate}
                            minDate={minEndDate}
                            name="endDate"
                            onDateChange={onChangeEndDate}
                            state={
                                endDateChanged && errorEndDate
                                    ? 'error'
                                    : 'idle'
                            }
                            value={endDate ? endDate : undefined}
                        />
                    </>
                )}
            </div>
            {timezoneEnabled ? (
                <Stack gap={1}>
                    <GivelifyLabel
                        className={classes.defineNormal}
                        color="grey600"
                        text={copy.timezoneNote}
                        variant="body1"
                    />
                    <GivelifyLabel
                        bold
                        className={classes.defineNormal}
                        text={`${timezoneLong} - ${timezoneShort}`}
                        variant="body1"
                    />
                </Stack>
            ) : null}
            {hasFormatFile && (
                <div>
                    <GivelifyLabel
                        fontWeight={700}
                        marginBottom={16}
                        text={copy.fileFormat}
                        variant="heading4"
                    />
                    <LegacyFileFormat
                        fileType={format}
                        onChange={onFormatFileChange}
                        pageName={pageName}
                        type={type}
                    />
                </div>
            )}
            {showExcludeEnvelopes.includes(pageName) ? (
                <ExcludeEmptyEnvelopes onChange={setExcludeEmptyEnvelopes} />
            ) : null}
            <div className={classes.formButtons}>
                <GivelifyButton
                    className={mergeClassNames(
                        classes.generateButton,
                        isLoading ? classes.loadingButton : '',
                    )}
                    data-testid="generateReport"
                    disabled={
                        isLoading ||
                        !!errorStartDate ||
                        !!errorEndDate ||
                        (timeFrame.selector === 'custom' && endDateError())
                    }
                    isLoading={isLoading}
                    name={copy.exportInfo}
                    onClick={handleSubmit}
                    text={copy.exportInfo}
                    variant="primary"
                />
            </div>
        </div>
    );
};

export default LegacyReportForm;
