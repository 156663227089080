import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgCloseInCircle = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={19.5}
        width={19.5}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>close</title>
        <path
            clipRule="evenodd"
            d="M1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10ZM10 0.25C4.61522 0.25 0.25 4.61522 0.25 10C0.25 15.3848 4.61522 19.75 10 19.75C15.3848 19.75 19.75 15.3848 19.75 10C19.75 4.61522 15.3848 0.25 10 0.25ZM13.909 6.03398C14.1989 6.32395 14.2013 6.79335 13.9142 7.08617L11.0575 9.99996L13.9142 12.9138C14.2013 13.2066 14.199 13.676 13.909 13.966C13.6149 14.2601 13.1375 14.2577 12.8463 13.9607L10 11.0575L7.15367 13.9607C6.86255 14.2577 6.38507 14.2601 6.09101 13.966C5.80105 13.676 5.79872 13.2066 6.0858 12.9138L8.9425 9.99996L6.03925 7.15363C5.7423 6.86251 5.73993 6.38503 6.03398 6.09098C6.32395 5.80101 6.79335 5.79869 7.08617 6.08577L10 8.94246L12.8463 6.03924C13.1375 5.74229 13.6149 5.73993 13.909 6.03398Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const CloseInCircleIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgCloseInCircle}
        fontSize={fontSize}
    />
);
